import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import StageModel from '../../../../../../../models/v2/Stage/Entity/StageModel';
import RoundModel from '../../../../../../../models/v2/round/entity/round-model';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import { TournamentProgrammeWidgetJson } from './football-tournament-programme.json';
import TournamentProgrammeWidgetBuilder from './football-tournament-programme.builder';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class TournamentProgrammeWidgetModel {
	readonly tournament: TournamentModel;
	readonly season: SeasonModel;
	readonly stage: StageModel;
	readonly rounds: RoundModel[];
	readonly sortDirection: string;
	readonly displayOdds: boolean = false;
	readonly bookmakers: SportOddsBookmakerModel[] | null;
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(
		tournament: TournamentModel,
		season: SeasonModel,
		stage: StageModel,
		rounds: RoundModel[],
		sortDirection: string,
		displayOdds: boolean,
		bookmakers: SportOddsBookmakerModel[] | null,
		refreshTime: RefreshTimeValuesType,
	) {
		this.tournament = tournament;
		this.season = season;
		this.stage = stage;
		this.rounds = rounds;
		this.sortDirection = sortDirection;
		this.displayOdds = displayOdds;
		this.bookmakers = bookmakers;
		this.refreshTime = refreshTime;
	}

	toJson(): TournamentProgrammeWidgetJson {
		return {
			tournament: this.tournament,
			season: this.season,
			stage: this.stage,
			rounds: this.rounds,
			sortDirection: this.sortDirection,
			displayOdds: this.displayOdds,
			bookmakers: this.bookmakers,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: TournamentProgrammeWidgetJson): TournamentProgrammeWidgetModel {
		return new TournamentProgrammeWidgetModel(
			json.tournament,
			json.season,
			json.stage,
			json.rounds,
			json.sortDirection,
			json.displayOdds,
			json.bookmakers,
			json.refreshTime,
		);
	}

	static builder(model?: TournamentProgrammeWidgetModel): TournamentProgrammeWidgetBuilder {
		return new TournamentProgrammeWidgetBuilder(model);
	}
}
