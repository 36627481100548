import Article from '../../../../models/article/Article';
import Video from '../../../../models/video/Video';
import Gallery from '../../../../models/gallery/Gallery';
import { ContentTypes } from '../../../../constants/content-types';
import HttpService from '../../../../services/rest/HttpService';
import ModelMapper from '../../../../models/ModelMapper';
import { EDITOR_BLOCK_TYPE } from '../../../../services/tags/tags.service';
import Category from '../../../../models/category/Category';
import { SelectedProjectsState } from './CopyToProjectsModal';
import { store } from '../../../../store/store';

export type ProjectsWithCategories = {
	domain: string;
	id: string;
	title: string;
	defaultLanguage: string;
	isMultilingual: boolean;
	categories: Category[];
};

export default class CopyToProjectsHelper {
	public getContent = async (id: string, type: ContentTypes): Promise<Article | Video | Gallery> => {
		switch (type) {
			case ContentTypes.ARTICLE:
				return this.getArticle(id);
			case ContentTypes.VIDEO:
				return this.getVideo(id);
			case ContentTypes.GALLERY:
				return this.getGallery(id);
			default:
				return {} as Article;
		}
	};

	public getAvailableProjectsAndCategories = async (userId: string, currentProjectId: string): Promise<ProjectsWithCategories[]> => {
		const projectsResponse = await HttpService.getAdminProjects(userId);
		const projects = projectsResponse.data.data.filter((project: any) => `${project.id}` !== `${currentProjectId}`);
		const projectDomains = projects.map((project: any) => project.domain);
		const categories = await this.getProjectCategories(projectDomains);
		return this.mergeCategoriesWithProjects(projects, categories);
	};

	//Project domains must be retrieved from UI
	public postContentToProjects = async (contentId: string, contentType: ContentTypes, selectedProjects: SelectedProjectsState[]) => {
		let content = await this.getContent(contentId, contentType);
		const contentJsonRequest = this.excludeContentData(content, contentType);
		const requests = this.createArticleCreateRequests(contentJsonRequest, contentType, selectedProjects);
		return Promise.all(requests);
	};

	public categoriesToOptions = (categories: Category[]) => {
		if (categories && categories.length > 0) {
			return categories.map((category: Category) => {
				return {
					value: category.id,
					label: category.title,
					status: category.active,
				};
			});
		}

		return [];
	};

	public canCopyToProject = (categories: Category[]) => {
		return categories && categories.length > 0;
	};

	public projectCopyStateIcon = (selectedProjects: SelectedProjectsState[], project: ProjectsWithCategories) => {
		const selectedProject = selectedProjects.find((selected) => selected.project.id === project.id);
		if (selectedProject) {
			return selectedProject.canCopy ? 'fa-lg fa fa-check-circle text-success' : 'fa-lg fa fa-times-circle text-danger';
		} else {
			return 'fa-lg fa fa-times-circle text-disabled';
		}
	};

	private mergeCategoriesWithProjects = (projects: any, categories: any) => {
		return projects.map((project: any, index: number) => {
			return {
				domain: project.domain,
				id: project.id,
				title: project.title,
				defaultLanguage: project.languages.default.language_code,
				isMultilingual: project.languages.multiple,
				categories: categories[index],
			};
		});
	};

	private getProjectCategories = async (projectDomains: string[]) => {
		const categoriesResponse = await Promise.all(this.createCategoriesRequests(projectDomains));
		return categoriesResponse.map((response: any) => {
			return ModelMapper.remapCategoriesFromResponse(response.data.data);
		});
	};

	private createCategoriesRequests = (projectsDomains: string[]) => {
		const userId = store.getState().profile.profile.id;

		return projectsDomains.map((projectDomain: string) => {
			return new Promise((resolve, reject) => {
				HttpService.get(`/admins/${userId}/categories?limit=200`, null, { project: projectDomain })
					.then((response: any) => {
						resolve(response);
					})
					.catch(() => {
						resolve({ data: { data: [] } });
					});
			});
		});
	};

	private createArticleCreateRequests = (content: any, contentType: ContentTypes, selectedProjects: SelectedProjectsState[]) => {
		const contentRequestTypes = { article: 'articles', video: 'videos', gallery: 'galleries' };
		return selectedProjects.map(async (selected) => {
			content.category_id = selected.categoryId;
			content.status = selected.categoryStatus;
			if (selected.project.isMultilingual) {
				content.language = selected.project.defaultLanguage;
			}
			return new Promise((resolve) => {
				HttpService.post(`/${contentRequestTypes[contentType]}`, Object.assign({}, content), { project: selected.project.domain })
					.then((resp: any) => resolve())
					.catch((e: any) => resolve());
			});
		});
	};

	private excludeContentData = (content: Article | Video | Gallery, contentType: ContentTypes): any => {
		switch (contentType) {
			case ContentTypes.ARTICLE:
				return this.stripContent(Article.builder(content instanceof Article ? content : ({} as Article)).toRequestJson());
			case ContentTypes.VIDEO:
				return this.stripContent(Video.builder(content instanceof Video ? content : ({} as Video)).toRequestJson());
			case ContentTypes.GALLERY:
				return this.stripContent(Gallery.builder(content instanceof Gallery ? content : ({} as Gallery)).toRequestJson());
			default:
				return {} as Article;
		}
	};

	private stripContent = (content: any) => {
		Object.keys(content).forEach((key) => {
			if (key !== 'title' && key !== 'subtitle' && key !== 'strapline' && key !== 'status' && key !== 'seo' && key !== 'body') {
				delete content[key];
			}
		});

		if (content && content.body && content.body.length > 0) {
			content.body = content.body.filter((block: any) => {
				return block.type === EDITOR_BLOCK_TYPE || block.type === 'embed' || block.type === 'link' || block.type === 'widget_smp';
			});
		}

		content.status = 'inactive';

		return content;
	};

	private getArticle = async (id: string): Promise<Article> => {
		const articleResponse = await HttpService.getArticle(id);
		return ModelMapper.remapArticleFromResponse(articleResponse.data.data);
	};
	private getVideo = async (id: string): Promise<Video> => {
		const videoResponse = await HttpService.getVideo(id);
		return ModelMapper.remapVideoFromResponse(videoResponse.data.data);
	};
	private getGallery = async (id: string): Promise<Gallery> => {
		const galleryResponse = await HttpService.getGallery(id);
		return ModelMapper.remapGalleryFromResponse(galleryResponse.data.data);
	};
}
