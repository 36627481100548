import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import LiveBlogModel from '../../../models/live-blog.model';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../store/store';
import { isArrayEmpty } from '../../../../../../global-helpers/global.helpers';
import { DATA_QA_ATTRIBUTES } from '../../../../../Partials/Sidebar/general-content/constants/data-qa.attributes';
import { customOption } from '../../../../../Partials/Shared/custom-select-options/custom-select-option';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import { authorsToOptions } from '../../../helpers/authors-select.helper';
import AuthorModel from '../../../../../../models/v2/author/author.model';
import '../../../style/live-blog.style.scss';

type Properties = {
	t: any;
	liveBlog: LiveBlogModel;
	setLiveBlogAuthors: (authors: AuthorModel[]) => void;
};

export const LiveBlogAuthorsSelect: FunctionComponent<Properties> = ({ t, liveBlog, setLiveBlogAuthors }) => {
	const availableAuthors = useSelector((state: AppState) => state.author.authors);

	const onLiveBlogAuthorSelect = (authorsData: Array<SelectMenuOptionType>) => {
		const authors =
			authorsData &&
			authorsData.filter((authorData: SelectMenuOptionType) => !!authorData.data).map((authorData: SelectMenuOptionType) => authorData.data);
		setLiveBlogAuthors(authors);
	};

	return isArrayEmpty(availableAuthors) ? null : (
		<Row className='mb-3 author'>
			<Col>
				<Label htmlFor={DATA_QA_ATTRIBUTES.LIVE_BLOG_AUTHORS}>
					<strong>{t('authors')}</strong>
				</Label>
				<Select
					id={DATA_QA_ATTRIBUTES.LIVE_BLOG_AUTHORS}
					value={liveBlog.authors && authorsToOptions(liveBlog.authors)}
					options={authorsToOptions(availableAuthors)}
					onChange={(selections: any) => onLiveBlogAuthorSelect(selections)}
					isMulti
					isClearable={liveBlog.authors && liveBlog.authors.length > 0}
					formatOptionLabel={customOption}
					placeholder={t('select')}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
				/>
			</Col>
		</Row>
	);
};
