import React from 'react';
import { Col, FormGroup, Label, Row, Input } from 'reactstrap';
import EmbedBlockModel from './models/embed-block.model';
import { Properties, State } from './properties/embed-block-edit.properties';
import { blockWrapperService, blockManagementService } from '../../../subcomponents/blocky.component';
import BlockValidation from '../../../helpers/block-validation.helper';
import ErrorHandler from '../../../partials/error/error-handler-component';
import { validationOptions, getDefaultType } from './helpers/embed-block-edit.helper';
import GenericSelect from '../../../partials/generic-select/generic-select-component';

export default class EmbedEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			embedBlock:
				props.block.data && props.block.data.preview
					? EmbedBlockModel.builder(props.block.data.preview.embedBlock).build()
					: EmbedBlockModel.builder().withType('other').withValidationType(getDefaultType(validationOptions, this.props.t)).build(),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const data = { embedContent: this.state.embedBlock.content, validationType: this.state.embedBlock.validationType.value };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.content = this.state.embedBlock.content;
			block.data.embed_type = this.state.embedBlock.type;
			block.data.preview = { embedBlock: this.state.embedBlock };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateEmbedCode = (e: any) => {
		this.setState({ ...this.state, embedBlock: EmbedBlockModel.builder(this.state.embedBlock).withContent(e.target.value).build() });
	};

	updateEmbedCodeType = (e: any) => {
		this.setState({ ...this.state, embedBlock: EmbedBlockModel.builder(this.state.embedBlock).withType(e.target.value).build() });
	};

	onValidationOptionChange = (validationType: any) => {
		validationType = validationType ? validationType : getDefaultType(validationOptions, this.props.t);

		this.setState({
			...this.state,
			embedBlock: EmbedBlockModel.builder(this.state.embedBlock).withValidationType(validationType).build(),
		});
	};

	render() {
		const { embedBlock, isValid } = this.state;

		return (
			<>
				<Row>
					<Col>
						<FormGroup>
							<Label>{this.props.t('enter_embed_code')}:</Label>
							<Input type='textarea' rows={5} value={embedBlock.content} onChange={this.updateEmbedCode} />
						</FormGroup>
					</Col>
				</Row>
				{!isValid && !embedBlock.content && <ErrorHandler t={this.props.t} errorMessage='field_is_required' />}
				<Row>
					<Col>
						<FormGroup>
							<FormGroup check inline>
								<Input
									className='form-check-input'
									type='radio'
									onChange={this.updateEmbedCodeType}
									id={`embed-type-video-${this.props.block.id}`}
									checked={embedBlock.type === 'video'}
									value='video'
								/>
								<Label className='form-check-label' check htmlFor={`embed-type-video-${this.props.block.id}`}>
									{this.props.t('video')}
								</Label>
							</FormGroup>
							<FormGroup check inline>
								<Input
									className='form-check-input'
									type='radio'
									onChange={this.updateEmbedCodeType}
									id={`embed-type-social-${this.props.block.id}`}
									checked={embedBlock.type === 'social'}
									value='social'
								/>
								<Label className='form-check-label' check htmlFor={`embed-type-social-${this.props.block.id}`}>
									{this.props.t('social')}
								</Label>
							</FormGroup>
							<FormGroup check inline>
								<Input
									className='form-check-input'
									type='radio'
									onChange={this.updateEmbedCodeType}
									id={`embed-type-other-${this.props.block.id}`}
									checked={embedBlock.type === 'other'}
									value='other'
								/>
								<Label className='form-check-label' check htmlFor={`embed-type-other-${this.props.block.id}`}>
									{this.props.t('other')}
								</Label>
							</FormGroup>
							<hr></hr>
							<Row>
								<Col>
									<FormGroup>
										<Label>{this.props.t('select_validation_type')}</Label>

										<GenericSelect
											t={this.props.t}
											isMulti={false}
											options={validationOptions}
											value={embedBlock.validationType ? embedBlock.validationType : getDefaultType(validationOptions, this.props.t)}
											onGenericOptionSelect={this.onValidationOptionChange}
										/>
									</FormGroup>
								</Col>
							</Row>
							{!isValid && <ErrorHandler t={this.props.t} errorMessage='validation_error' />}
						</FormGroup>
					</Col>
				</Row>
			</>
		);
	}
}
