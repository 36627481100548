import CompetitionModel from '../../../../../../../models/v2/competition/entity/competition.model';
import TennisCompetitionSeasonModel from '../../../../../../../models/v2/tennis-competition-season/entity/tennis-competition-season.model';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import TennisTournamentModel from '../../../../../../../models/v2/tennis-tournament/tennis-tournament.model';
import TennisRoundModel from '../../../../../../../models/v2/round/tennis-round/tennis-round.model';
import { TennisTournamentProgrammeJson } from './tennis-tournament-programme.json';
import TennisTournamentProgrammeBuilder from './tennis-tournament-programme.builder';
import { RefreshTimeValuesType } from '../../refresh-time-input/helper';

export default class TennisTournamentProgrammeModel {
	readonly competition: CompetitionModel;
	readonly season: TennisCompetitionSeasonModel;
	readonly tournament: TennisTournamentModel;
	readonly tournaments: TennisTournamentModel[];
	readonly rounds: TennisRoundModel[];
	readonly availableRounds: TennisRoundModel[];
	readonly sortDirection: string;
	readonly displayOdds: boolean = false;
	readonly bookmakers: SportOddsBookmakerModel[] | null;
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(
		competition: CompetitionModel,
		season: TennisCompetitionSeasonModel,
		tournament: TennisTournamentModel,
		tournaments: TennisTournamentModel[],
		rounds: TennisRoundModel[],
		availableRounds: TennisRoundModel[],
		sortDirection: string,
		displayOdds: boolean,
		bookmakers: SportOddsBookmakerModel[] | null,
		refreshTime: RefreshTimeValuesType,
	) {
		this.competition = competition;
		this.season = season;
		this.tournament = tournament;
		this.tournaments = tournaments;
		this.rounds = rounds;
		this.availableRounds = availableRounds;
		this.sortDirection = sortDirection;
		this.displayOdds = displayOdds;
		this.bookmakers = bookmakers;
		this.refreshTime = refreshTime;
	}

	toJson(): TennisTournamentProgrammeJson {
		return {
			competition: this.competition,
			season: this.season,
			tournament: this.tournament,
			tournaments: this.tournaments,
			rounds: this.rounds,
			availableRounds: this.availableRounds,
			sortDirection: this.sortDirection,
			displayOdds: this.displayOdds,
			bookmakers: this.bookmakers,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: TennisTournamentProgrammeJson): TennisTournamentProgrammeModel {
		return new TennisTournamentProgrammeModel(
			json.competition,
			json.season,
			json.tournament,
			json.tournaments,
			json.rounds,
			json.availableRounds,
			json.sortDirection,
			json.displayOdds,
			json.bookmakers,
			json.refreshTime,
		);
	}

	static builder(tournamentProgrammeWidget?: TennisTournamentProgrammeModel): TennisTournamentProgrammeBuilder {
		return new TennisTournamentProgrammeBuilder(tournamentProgrammeWidget);
	}
}
