import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import DateTimePickerContainer from '../../../../../../Sidebar/GeneralContentAttributes/subcomponents/DateTimePicker/DateTimePickerContainer';

type Properties = {
	t: any;
	date: string;
	onDateChange: (date: string) => any;
	id: string;
};

export const SportsDateSelect: React.FunctionComponent<Properties> = (props) => {
	const { t, date, onDateChange } = props;

	return (
		<FormGroup data-qa={props.id}>
			<Label htmlFor={props.id}>{t('select_date_label')}:</Label>
			<DateTimePickerContainer
				id={props.id}
				dateFormat='Y.m.d'
				resetButton
				date={date}
				t={t}
				enableTime={false}
				placeholder={'YYYY.MM.DD'}
				onDateChange={onDateChange}
			/>
		</FormGroup>
	);
};
