import React, { useEffect, useState } from 'react';
import * as ReactSortableHOC from 'react-sortable-hoc';
import { arrayMove, SortEnd } from 'react-sortable-hoc';
import ListsReorderModal from './lists-modal/lists-reorder.modal.component';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store/store';
import { returnObjectForListListingUpdate } from '../../../store/action-creators/ListActionCreators';
import { RowsList } from './lists-sortable.component';
import List from '../../../models/list/List';
import './styles/lists-rows-container.scss';

type ModalState = { isShown: boolean; data: { lists: List[]; shoudUpdate: boolean } };

type RowsProps = {
	content: any[] | undefined;
	onDelete: (id: string, title: string) => any;
	analytics: any;
	history: any;
	t: any;
	itemIndex?: number;
};

const SortableRowsList = ReactSortableHOC.SortableContainer(RowsList);

export const ListsRowsContainer: React.FunctionComponent<RowsProps> = ({ content, onDelete, analytics, history, t }) => {
	// We keep modal in state prevState of lists and shoudUpdate prop because we need to visualise the list new place
	// and return it if the user cancels the reordering action.
	const [showModal, toggleModal] = useState<ModalState>({
		isShown: false,
		data: { lists: [], shoudUpdate: false },
	});
	const [sortingIndex, setSortingIndex] = useState<any>({});

	const lists = useSelector((state: AppState) => state.list.lists);
	const dispatch = useDispatch();
	const shouldSort = lists && lists.length > 1;

	const onSortEnd = (sortEnd: SortEnd) => {
		if (shouldSort && sortEnd.oldIndex !== sortEnd.newIndex) {
			// onSortEnd we update lists state with new item positions and open modal to confirm the operation.
			// If the user cancels the operation we update the state with its previous value kept in *showModal.data.lists
			toggleModal({ isShown: true, data: { lists: [...lists], shoudUpdate: false } });
			// We pass sorting indexes in modal component to use them for eventually patch list update.
			setSortingIndex(sortEnd);
			const listsCopy = [...lists];
			const newLists = arrayMove(listsCopy, sortEnd.oldIndex, sortEnd.newIndex);
			dispatch(returnObjectForListListingUpdate(newLists));
		}
	};

	useEffect(() => {
		// If showModal.data.shoudUpdate is true we update lists with previous state.
		if (showModal.data.shoudUpdate === true) {
			dispatch(returnObjectForListListingUpdate(showModal.data.lists));
		}
	}, [showModal.data.shoudUpdate]);

	if (content && content.length > 0) {
		return (
			<tr className='w-100 p-0 d-flex bg-white sortable-list-table-row'>
				<td className='w-100 p-0'>
					<SortableRowsList
						t={t}
						onSortEnd={onSortEnd}
						useDragHandle
						content={content}
						onDelete={onDelete}
						analytics={analytics}
						history={history}
					/>
					{showModal.isShown && <ListsReorderModal t={t} showModal={showModal} sortEnd={sortingIndex} toggleModal={toggleModal} />}
				</td>
			</tr>
		);
	}
	return <tr />;
};
