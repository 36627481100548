import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import SportsHttpService from '../../../../../services/rest/sports-http-service';
import { customOption } from '../shared/custom-select-option';
import ErrorHandler from '../error/error-handler-component';
import BasketballStandingsModel from '../../blocks/widgets/basketball-standings/models/basketball-standings.model';
import BasketballStageModel from '../../../../../models/v2/Stage/Entity/basketball-stage.model';
import { responseToStageModel, stagesToOptions, stageToOption } from './helpers/basketball-stage-select.helper';

type Properties = {
	onStageSelect: any;
	blockData: BasketballStandingsModel;
	selectedStage: BasketballStageModel;
	t: any;
	isValid: boolean;
	isClearable?: boolean;
	isRequired?: boolean;
	isBlocky: boolean;
	language: string;
	withLabel?: boolean;
	label?: string;
	sport: string;
	id: string;
};

const BasketballStageSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [stagesOptions, setStagesOptions] = useState<BasketballStageModel[]>([]);

	useEffect(() => {
		if (props.blockData.season && props.blockData.season.id) {
			getStages(props.sport, props.blockData.season.id, props.language);
		}
	}, []);

	useEffect(() => {
		if (props.blockData.season && props.blockData.season.id) {
			getStages(props.sport, props.blockData.season.id, props.language);
		}

		if ((props.blockData.season && !props.blockData.season.id) || !props.blockData.season) {
			setStagesOptions([]);
		}
	}, [props.blockData.season && props.blockData.season.id]);

	const updateStagesOptionsState = (stages: BasketballStageModel[]) => {
		setStagesOptions(stages);
	};

	const getStages = (sport: string, seasonId: string, lang: string) => {
		SportsHttpService.getSeasonsDetails(sport, seasonId, lang)
			.then((response: any) => {
				updateStagesOptionsState(response.data.stages.map((item: BasketballStageModel) => responseToStageModel(item)));
			})
			.catch((e: any) => e);
	};

	return (
		<Row data-qa={props.id}>
			<Col>
				<Label htmlFor={props.id}>{props.t('stage')}</Label>
				{props.isRequired && <span className='text-danger'>*</span>}
				<Select
					id={props.id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					className='w-100'
					placeholder={props.t('select')}
					formatOptionLabel={customOption}
					options={stagesToOptions(stagesOptions)}
					value={props.selectedStage && props.selectedStage.id ? stageToOption(props.selectedStage) : []}
					noOptionsMessage={(inputValue) => inputValue && props.t('no_options')}
					isClearable={props.isClearable}
					onChange={(selection: any) => {
						if (selection) {
							props.onStageSelect(selection.data);
						} else {
							props.onStageSelect(null);
						}
					}}
				/>
				{!props.isValid && !props.selectedStage && <ErrorHandler t={props.t} errorMessage='field_is_required' />}
			</Col>
		</Row>
	);
};

export default BasketballStageSelectComponent as React.ComponentType<Properties>;
