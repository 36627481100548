import * as React from 'react';
import { noPreviewImage } from '../../../../../../constants/resource-constants';

type Properties = {
	url: string;
	videoType: string;
};

const VideoElement: React.FunctionComponent<Properties> = (props) => {
	const { url, videoType } = props;
	const ALLOWED_VIDEO_TYPES = ['mp4', 'webm', 'ogg', 'avi'];

	if (!ALLOWED_VIDEO_TYPES.includes(videoType)) {
		return <img src={noPreviewImage} />;
	}

	return (
		<video controls={true} width='100%'>
			<source src={url} type={`video/${videoType}`}></source>
		</video>
	);
};

export default VideoElement;
