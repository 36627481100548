import 'reflect-metadata';
import { AnyT, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import AuthorModel from '../../../../models/v2/author/author.model';
import CategoryModel from '../../../../models/v2/category/category.model';
import MainMediaModel from '../../../../models/v2/main-media/main-media.model';
import OddClientBookmakersModel from '../../../Partials/Blocky/partials/available-bookmakers-select/models/odd-client-bookmakers-full.model';
import { LIVE_BLOG_PAGE_SIZE } from '../constants/PageSize';
import LiveBlogCollaboratorsModel from './live-blog-collaborators.model';
import LiveBlogTeamModel from './teams/live-blog-team.model';
import LiveBlogTournamentModel from './tournaments/live-blog-tournament-model';

@jsonObject
export default class LiveBlogModel {
	@jsonMember(String)
	public id: string | null = null;
	@jsonMember(String)
	public title: string | null = null;
	@jsonMember(String)
	public description: string | null = null;
	@jsonMember(String)
	public slug: string | null = null;
	@jsonMember(String)
	public status: string | null = null;
	@jsonMember(String)
	public type: string = '';
	@jsonMember(String)
	public sport: string | null = null;
	@jsonMember(String)
	public language: string = '';
	@jsonMember(CategoryModel)
	public category: CategoryModel | null = null;
	@jsonArrayMember(CategoryModel, { name: 'additional_categories' })
	public additional_categories: CategoryModel[] | null = null;
	@jsonArrayMember(AuthorModel)
	public authors: AuthorModel[] = [];
	@jsonArrayMember(LiveBlogCollaboratorsModel)
	public collaborators: LiveBlogCollaboratorsModel[] = [];
	@jsonArrayMember(MainMediaModel, { name: 'main_media' })
	public main_media: MainMediaModel[] = [];
	@jsonMember(String)
	public main_media_type: string | null = null;
	@jsonMember(String, { name: 'start_time' })
	public start_time: string | null = null;
	@jsonMember(String, { name: 'created_at' })
	public created_at: string | null = null;
	@jsonMember(String, { name: 'updated_at' })
	public updated_at: string | null = null;
	@jsonMember(AuthorModel, { name: 'created_by' })
	public created_by: AuthorModel | null = null;
	@jsonArrayMember(LiveBlogTournamentModel)
	public competitions: LiveBlogTournamentModel[] = [];
	@jsonArrayMember(LiveBlogTeamModel)
	public teams: LiveBlogTeamModel[] = [];
	@jsonArrayMember(AnyT, { name: 'sport_events' })
	public sport_events: any[] = [];
	@jsonMember(Boolean, { name: 'match_header' })
	public match_header: boolean = false;
	@jsonMember(Number)
	public pagination: number = LIVE_BLOG_PAGE_SIZE;
	@jsonArrayMember(OddClientBookmakersModel)
	public sponsors: OddClientBookmakersModel[] | null | any = null;
}
