import moment from 'moment-timezone';

export type DateGenFormat = Date | string;

export const isDateObjValid = (date: any): boolean => {
	return date && date instanceof Date && !isNaN(date.getTime());
};

export const transformStringDateToDate = (dateInput: DateGenFormat): Date => {
	return typeof dateInput === 'string' ? new Date(dateInput) : dateInput;
};

export const convertTZ = (date: DateGenFormat, tzString: string = 'Europe/Sofia'): Date => {
	const dateObj = transformStringDateToDate(date);
	return new Date(dateObj.toLocaleString('en-US', { timeZone: tzString }));
};

export const formatGeneralDateAsString = (dateInput: DateGenFormat): string => {
	const dateObj = transformStringDateToDate(dateInput);
	return dateObj.toISOString().split('T')[0];
};

export const dateStringAsTimeNumber = (dateString: string): number => {
	// work for 'yyyy-mm-dd' string
	try {
		const [year, month, day] = dateString.split('-');
		const startDate = new Date(+year, +month - 1, +day);
		return startDate != null ? startDate.getTime() : 0;
	} catch {
		return 0;
	}
};

export const getHoursAndMinutesFromDate = (dateInput: DateGenFormat): string => {
	return `${formatDateHoursAs2DigitText(dateInput)}:${formatDateMinutesAs2DigitText(dateInput)}`;
};

export const getDayAndMonthFromDate = (dateInput: DateGenFormat): string => {
	return `${formatDay(dateInput)} ${formatDateMonthAsShortText(dateInput)}`;
};

const formatDateWithIntl = (dateInput: DateGenFormat, format: Record<string, string | boolean>) => {
	const dateObj = transformStringDateToDate(dateInput);
	const formatter = new Intl.DateTimeFormat('en', format);
	return formatter.format(dateObj);
};

export const formatDay = (dateInput: DateGenFormat) => {
	return formatDateWithIntl(dateInput, { day: 'numeric' });
};

export const formatDateMonthAsShortText = (dateInput: DateGenFormat) => {
	return formatDateWithIntl(dateInput, { month: 'short' });
};

export const formatDateHoursAs2DigitText = (dateInput: DateGenFormat) => {
	return formatDateWithIntl(dateInput, { hour: '2-digit', hour12: false });
};

export const formatDateMinutesAs2DigitText = (dateInput: DateGenFormat) => {
	const result = formatDateWithIntl(dateInput, { minute: '2-digit' });
	return result.padStart(2, result);
};

export const getTimezoneOffset = (timezone: string, date: Date | string) => {
	// Parse the date in the given timezone
	const parsedDate = moment.tz(date, timezone);

	// Get the UTC offset
	const utcOffset = parsedDate.format('Z'); // Format 'Z' gives the offset from UTC in hours and minutes (e.g., +02:00)
	return utcOffset.trim();
};

export const isDateInRange = (dateInput: DateGenFormat, dateForComparison: DateGenFormat, daysDiff: number = 1) => {
	const dayDiffTime = 24 * 60 * 60 * 1000;
	const mainDateAsObj = transformStringDateToDate(dateInput).setHours(0, 0, 0, 0);
	const dateForComparisonAsObj = transformStringDateToDate(dateForComparison).setHours(0, 0, 0, 0);
	const differenceInTime = Math.abs(mainDateAsObj - dateForComparisonAsObj);
	const differenceInDays = differenceInTime / dayDiffTime;
	return differenceInDays <= daysDiff;
};
