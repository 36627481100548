import BlockModel from '../../../models/block.model';
import { ViewTypes } from '../../../constants/general.constants';
import React from 'react';
import BlockUpdateController from '../../block-update-controller.component';
import { compose } from 'redux';
import { WidgetLeaderboardView } from './widget-leaderboard-view.component';
import WidgetLeaderboardEditComponent from './widget-leaderboard-edit.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
};

const WidgetLeaderboard: React.FunctionComponent<Properties> = (props) => {
	if (props.view === ViewTypes.edit) {
		return <WidgetLeaderboardEditComponent block={props.block} t={props.t} />;
	}

	if (props.view === ViewTypes.normal) {
		return <WidgetLeaderboardView widgetLeaderboard={props.block.data.widget} t={props.t} />;
	}

	return null;
};

export default compose(BlockUpdateController)(WidgetLeaderboard);
