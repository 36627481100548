import React from 'react';
import { Col, FormGroup, Label, Row, Input } from 'reactstrap';
import { LinkTypes } from './subcomponents/link-types.component';
import { openTypes, LINK_TYPES } from '../../../constants/link.constants';
import { Properties, State } from './properties/link-block-edit.properties';
import LinkBlockModel from './models/link-block.model';
import ErrorHandler from '../../../partials/error/error-handler-component';
import BlockValidation from '../../../helpers/block-validation.helper';
import { blockWrapperService, blockManagementService } from '../../../subcomponents/blocky.component';
import BlockModel from '../../../models/block.model';
const OPEN_TYPES = { newWindow: 'NEW_WINDOW', sameWindow: 'SAME_WINDOW' };

export default class LinkEdit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			linkModel:
				props.block.data && props.block.data.preview
					? props.block.data.preview.linkBlock
					: LinkBlockModel.builder().withContent('').withLink('').withLinkType(LINK_TYPES.link).withOpenType(openTypes['sameWindow']).build(),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const data = { linkModel: this.state.linkModel };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { linkBlock: this.state.linkModel };
			const tempData = { ...block.data, ...this.state.linkModel };
			const tempBlock = BlockModel.builder(block).withData(tempData).build();
			blockManagementService.blockUpdate(tempBlock, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateContent = (text: string, link: string, openType: string) => {
		let target = openType === OPEN_TYPES.newWindow ? 'target="_blank"' : 'target="_self"';

		return `<a href="${link}" ${target}>${text}</a>`;
	};

	updateLink = (e: any) => {
		const content = this.updateContent(this.state.linkModel.text, e.target.value, this.state.linkModel.open_type);
		this.setState({
			...this.state,
			linkModel: LinkBlockModel.builder(this.state.linkModel).withLink(e.target.value).withContent(content).build(),
		});
	};

	updateText = (e: any) => {
		const content = this.updateContent(e.target.value, this.state.linkModel.link, this.state.linkModel.open_type);
		this.setState({
			...this.state,
			linkModel: LinkBlockModel.builder(this.state.linkModel).withText(e.target.value).withContent(content).build(),
		});
	};

	updateOpenType = (e: any) => {
		const content = this.updateContent(this.state.linkModel.text, this.state.linkModel.link, e.target.value);
		this.setState({
			...this.state,
			linkModel: LinkBlockModel.builder(this.state.linkModel).withOpenType(e.target.value).withContent(content).build(),
		});
	};

	updateLinkType = (e: any) => {
		this.setState({
			...this.state,
			linkModel: LinkBlockModel.builder(this.state.linkModel).withLinkType(e.target.value).build(),
		});
	};

	render() {
		const { block, t } = this.props;
		const { linkModel, isValid } = this.state;

		return (
			<>
				<Row>
					<Col>
						<FormGroup>
							<Label>{t('link')}:</Label>
							<Input
								type='text'
								value={linkModel.link}
								placeholder={t('enter_link')}
								onChange={(value) => {
									this.updateLink(value);
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
				{!isValid && (!linkModel.link || (linkModel.link && linkModel.link.length === 0)) && (
					<ErrorHandler t={this.props.t} errorMessage='field_is_required' />
				)}
				<Row>
					<Col>
						<FormGroup>
							<Label>{t('text')}:</Label>
							<Input
								type='textarea'
								rows={5}
								value={linkModel.text}
								placeholder={t('enter_link_text')}
								onChange={(value) => {
									this.updateText(value);
								}}
							/>
						</FormGroup>
					</Col>
				</Row>
				{!isValid && (!linkModel.link || (linkModel.content && linkModel.content.length === 0)) && (
					<ErrorHandler t={this.props.t} errorMessage='field_is_required' />
				)}
				<Row className='mb-3'>
					<Col>
						<FormGroup check inline>
							<Input
								className='form-check-input'
								type='radio'
								onChange={this.updateOpenType}
								id={`open-in-new-${block.id}`}
								name={`open-type-radio-buttons-${block.id}`}
								checked={linkModel.open_type === openTypes['newWindow']}
								value={openTypes['newWindow']}
							/>
							<Label className='form-check-label' check htmlFor={`open-in-new-${block.id}`}>
								{t('open_in_new_window')}
							</Label>
						</FormGroup>
						<FormGroup check inline>
							<Input
								className='form-check-input'
								type='radio'
								onChange={this.updateOpenType}
								id={`open-in-same-${block.id}`}
								name={`open-type-radio-buttons-${block.id}`}
								checked={linkModel.open_type === openTypes['sameWindow']}
								value={openTypes['sameWindow']}
							/>
							<Label className='form-check-label' check htmlFor={`open-in-same-${block.id}`}>
								{t('open_in_the_same_window')}
							</Label>
						</FormGroup>
					</Col>
				</Row>
				<LinkTypes onChange={this.updateLinkType} blockId={block.id} value={linkModel.linkType} t={t} />
			</>
		);
	}
}
