import React from 'react';
import { relatedConstants } from '../../../../constants/related.constants';
import { moveElementToFront } from '../../../../global-helpers/global.helpers';

import MultisportCompetitionModelWrapper from '../models/competition-wrapper.model';
import MultisportEventModel from '../models/event.model';
import MultisportModel from '../models/sport.model';
import { assetsPlaceholder, competitionPlaceholder } from '../../../../constants/assetsPlaceholder';
import { ParticipantModelWrapper } from '../models/participant-details';
import { PreviewDataForSave, PreviewEventForSave } from '../components/modals/constants';
import { MultisportCompetitionModel } from '../models/competition.model';
import { TreeItem } from 'react-sortable-tree';
import { EventStatuses } from '../../../../constants/event.types';
import {
	formatDateMinutesAs2DigitText,
	formatDateMonthAsShortText,
	convertTZ,
	getHoursAndMinutesFromDate,
	isDateInRange,
	getDayAndMonthFromDate,
	formatDateHoursAs2DigitText,
} from '../../../../global-helpers/global-dates.helper';

export const TOP_EVENTS_SPORT = 'all';

export const getSportEventsCount = (competitions: MultisportCompetitionModelWrapper[]): number => {
	return competitions.map((competition: MultisportCompetitionModelWrapper) => competition.events.length).reduce((a, b) => a + b, 0);
};

export const checkIfEntityExistInPreviewSection = (
	selectedEntitySportSection: string,
	event: MultisportEventModel,
	previewData: MultisportModel[],
): boolean => {
	for (const sportData of previewData) {
		if (sportData.sport !== selectedEntitySportSection) continue;

		for (const competitionData of sportData.competitions) {
			if (competitionData.competition.id !== event.competition.id) continue;

			for (const eventData of competitionData.events) {
				if (eventData.id === event.id && eventData.sport === event.sport) {
					// Matching entity found
					return true;
				}
			}
		}
	}

	// No matching entity found
	return false;
};

export const checkIfEntityExistInBothSection = (event: MultisportEventModel, previewData: MultisportModel[]) => {
	return (
		checkIfEntityExistInPreviewSection(TOP_EVENTS_SPORT, event, previewData) &&
		checkIfEntityExistInPreviewSection(event.sport, event, previewData)
	);
};

export const setTopEventSectionAsFirstElement = (previewData: MultisportModel[]): MultisportModel[] => {
	const copiedDataForPreview = structuredClone(previewData);
	const topEventElement = copiedDataForPreview && copiedDataForPreview.find((el: MultisportModel) => el.sport === TOP_EVENTS_SPORT);
	return topEventElement ? moveElementToFront<MultisportModel>(copiedDataForPreview, topEventElement) : copiedDataForPreview;
};

const getEventsTypesBySport = () => [relatedConstants.types.match, relatedConstants.types.game, relatedConstants.types.event];

const getCompetitionsTypesBySport = () => [relatedConstants.types.competition, relatedConstants.types.tournament];

export const isEvent = (entityType: string) => getEventsTypesBySport().includes(entityType);

export const isCompetition = (entityType: string) => getCompetitionsTypesBySport().includes(entityType);

export const extractLogoFromCompetition = (competition: MultisportCompetitionModel) => {
	if (competition && competition.display_asset && competition.display_asset.url) {
		return competition.display_asset.url;
	} else {
		return competitionPlaceholder;
	}
};

export const extractLogoFromEvent = (event: MultisportEventModel) => {
	if (event && event.display_asset && event.display_asset.url) {
		return event.display_asset.url;
	} else {
		return assetsPlaceholder.imagePlaceholder;
	}
};

const renderParticipants = (participantsWrapper: ParticipantModelWrapper[]) => {
	if (participantsWrapper.length > 0 && participantsWrapper.length <= 2) {
		return (
			<>
				{participantsWrapper.map((participantWrapper) => {
					const { participant } = participantWrapper;
					return (
						<div key={participant.id} className='participant-wrapper'>
							<img src={participant.display_asset.url} className='participant-img' />
							<span className='participant-name'>{participant.name}</span>
						</div>
					);
				})}
			</>
		);
	}

	return null;
};

export const displayEventPreviewRow = (event: MultisportEventModel) => {
	const participantsWrapper = event.participant_details;
	const areParticipantsMoreThanTwo = participantsWrapper.length > 2;

	return (
		<div key={event.id} className='preview-event-row-data'>
			<div className='date-time-wrapper'>
				<span className='date'>{getDayAndMonthFromDate(event.start_time)}</span>
				<span className='time'>{getHoursAndMinutesFromDate(event.start_time)}</span>
			</div>
			<div className='title-container'>
				{!areParticipantsMoreThanTwo ? renderParticipants(participantsWrapper) : <span className='event-title'>{event.name}</span>}
			</div>
		</div>
	);
};

export const extractMultisportModel = (previewData: MultisportModel[], previewSectionSport: string) => {
	return previewData.find((sportData: MultisportModel) => sportData.sport === previewSectionSport);
};

export const convertPreviewDataForSave = (data: MultisportModel[]): PreviewDataForSave[] => {
	const result: PreviewDataForSave[] = [];

	data.forEach((sportItem) => {
		let eventOrder = 0;
		const eventsArray: PreviewEventForSave[] = [];

		sportItem.competitions.forEach((competition) => {
			competition.events.forEach((event) => {
				eventOrder++;

				eventsArray.push({
					eventId: event.id,
					order: eventOrder,
				});
			});
		});

		result.push({
			sport: sportItem.sport,
			events: eventsArray,
		});
	});

	return result;
};

export const populateCurrentlyCollapsedElements = (collapsedArray: TreeItem[], reduxPreviewTreeData: TreeItem[]): TreeItem[] => {
	// Helper function to find a node by ID in the array
	function findNodeById(nodeId: string, reduxPreviewTreeData: TreeItem[]): TreeItem | null {
		for (const item of reduxPreviewTreeData) {
			if (item.id === nodeId) {
				return item;
			}

			if (item.children) {
				const found = findNodeById(nodeId, [item.children]);
				if (found) return found;
			}
		}
		return null;
	}

	// Recursive function to update the 'expanded' property
	function updateNode(node: TreeItem, referenceArray: TreeItem[]) {
		const matchingNode = findNodeById(node.id, referenceArray);

		if (matchingNode) {
			node.expanded = matchingNode.expanded;
		}

		return node;
	}

	// Iterate through each node in the first array
	return reduxPreviewTreeData.map((node) => updateNode(node, collapsedArray));
};

export const formatSportEntityDate = (dateString: string, configurationDate: Date, timezone: string) => {
	if (!dateString) {
		return {
			date: EventStatuses.UNKNOWN,
			hours: EventStatuses.UNKNOWN,
			isAddableToSection: false,
		};
	}

	const timezoneDateObject = convertTZ(dateString, timezone);
	const monthAsText = formatDateMonthAsShortText(timezoneDateObject);

	return {
		date: `${timezoneDateObject.getDate()} ${monthAsText} ${timezoneDateObject.getFullYear()}`.toUpperCase(),
		hours: `${formatDateHoursAs2DigitText(timezoneDateObject)}:${formatDateMinutesAs2DigitText(timezoneDateObject)}`,
		isAddableToSection: isDateInRange(configurationDate, dateString),
	};
};
