import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'reactstrap/es/Button';
import './manual-linking.component.scss';
import DropdownEntityTag from './dropdown-entity-tag.component';
import { Tooltip } from 'reactstrap';

const ADD_ALL_ICON = require('./icons/add-all.svg');

class ManualLinkingControls extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tooltipOpen: false,
		};
	}

	onSingleTagInsert = (tag) => {
		if (this.props.tags && tag && tag.parent) {
			const pos = this.props.tags.findIndex((el) => el.id === tag.parent);
			if (pos >= 0) this.props.tags.splice(pos, 1);
		}

		this.props.onSingleTagInsert(tag);
	};

	onMultipleTagsInsert = (firstOnly) => {
		const tags = this.markTagsForOccurrenceLinking(firstOnly);
		this.props.onMultipleTagsInsert(tags);
	};

	requestTags = (withScope = true) => {
		this.props.requestTags(withScope);
	};

	removeMarkedTags = (tags) => {
		let markedTags = JSON.parse(JSON.stringify(tags ? tags : []));

		markedTags = markedTags
			? markedTags.filter((tag) => {
					if (tag.removed !== undefined) {
						return !tag.removed;
					} else {
						return true;
					}
			  })
			: [];

		return markedTags;
	};

	markTagsForOccurrenceLinking = (firstOnly) => {
		let tags = this.props.tags ? [...this.props.tags] : [];

		tags = tags.map((tag) => {
			tag['firstOnly'] = firstOnly;

			return tag;
		});

		return tags;
	};

	toggle = () => {
		this.setState({ ...this.state, tooltipOpen: !this.state.tooltipOpen });
	};

	getTooltip = (id, t) => {
		return (
			<div className='controls-container-info'>
				<i id={`tooltip-manual-linking-${id}`} className='fa fa-question-circle-o'></i>
				<Tooltip placement='top' isOpen={this.state.tooltipOpen} target={`tooltip-manual-linking-${id}`} toggle={this.toggle}>
					{t('manual_linking_tooltip')}
				</Tooltip>
			</div>
		);
	};

	render() {
		const { autoTaggingScope, disabled, isLoading, t, id } = this.props;
		const isArticle = window.location.hash.includes('/smp/articles');
		const hasAutoTaggingScope = autoTaggingScope && !!autoTaggingScope.sport;
		const tags = this.removeMarkedTags(this.props.tags);

		return (
			<div className={'controls-container' + (isLoading ? ' loading-overlay-h-auto with-text' : '')} data-loading-text={t('preparing_tags')}>
				<div className='general-controls mr-3'>
					{isArticle && (
						<Button
							disabled={disabled}
							outline
							className='mr-2'
							size='sm'
							color='primary'
							onClick={() => {
								this.requestTags();
							}}
						>
							<i className='fa fa-refresh'></i>
							{t('smart_search')}
						</Button>
					)}

					{(hasAutoTaggingScope || !isArticle) && (
						<Button
							disabled={disabled}
							outline
							className='mr-2'
							size='sm'
							color='primary'
							onClick={() => {
								this.requestTags(false);
							}}
						>
							<i className='fa fa-globe'></i>
							{t('search_everywhere')}
						</Button>
					)}

					{this.getTooltip(id, t)}
				</div>

				{tags && tags.length > 0 && (
					<>
						<div className='control-container-tags'>
							{tags.map((tag) => {
								return (
									<DropdownEntityTag
										t={t}
										tag={tag}
										key={tag ? `key-entity-tag-${tag.type}-${tag.text}` : 'key-entity-tag-'}
										onTagInsert={this.onSingleTagInsert}
									/>
								);
							})}
						</div>

						<div className='general-controls mr-3'>
							<Button
								outline
								className='mr-2 with-custom-icon'
								size='sm'
								color='primary'
								onClick={() => {
									this.onMultipleTagsInsert(false);
								}}
							>
								<div className='custom-icon' style={{ backgroundImage: `url(${ADD_ALL_ICON})` }}></div>
								{t('add_all')}
							</Button>

							<Button
								outline
								className='mr-2'
								size='sm'
								color='primary'
								onClick={() => {
									this.onMultipleTagsInsert(true);
								}}
							>
								<i className='fa fa-circle-plus'></i>
								{t('add_first_occurrences')}
							</Button>
						</div>
					</>
				)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		autoTaggingScope: state.blocky.autoTaggingScope,
	};
}

export default connect(mapStateToProps)(ManualLinkingControls);
