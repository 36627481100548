import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import { BasketballTournamentProgrammeWidgetJson } from './basketball-tournament-programme.json';
import BasketballTournamentProgrammeWidgetBuilder from './basketball-tournament-programme.builder';
import CompetitionModel from '../../../../../../../models/v2/competition/entity/competition.model';
import BasketballSeasonModel from '../../../../../../../models/v2/basketball-season/entity/basketball-season.model';
import BasketballStageModel from '../../../../../../../models/v2/Stage/Entity/basketball-stage.model';
import BasketballRoundModel from '../../../../../../../models/v2/round/entity/basketball-round.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class BasketballTournamentProgrammeWidgetModel {
	readonly competition: CompetitionModel;
	readonly season: BasketballSeasonModel;
	readonly stage: BasketballStageModel;
	readonly rounds: BasketballRoundModel[];
	readonly sortDirection: string;
	readonly displayOdds: boolean = false;
	readonly bookmakers: SportOddsBookmakerModel[] | null;
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(
		competition: CompetitionModel,
		season: BasketballSeasonModel,
		stage: BasketballStageModel,
		rounds: BasketballRoundModel[],
		sortDirection: string,
		displayOdds: boolean,
		bookmakers: SportOddsBookmakerModel[] | null,
		refreshTime: RefreshTimeValuesType,
	) {
		this.competition = competition;
		this.season = season;
		this.stage = stage;
		this.rounds = rounds;
		this.sortDirection = sortDirection;
		this.displayOdds = displayOdds;
		this.bookmakers = bookmakers;
		this.refreshTime = refreshTime;
	}

	toJson(): BasketballTournamentProgrammeWidgetJson {
		return {
			competition: this.competition,
			season: this.season,
			stage: this.stage,
			rounds: this.rounds,
			sortDirection: this.sortDirection,
			displayOdds: this.displayOdds,
			bookmakers: this.bookmakers,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: BasketballTournamentProgrammeWidgetJson): BasketballTournamentProgrammeWidgetModel {
		return new BasketballTournamentProgrammeWidgetModel(
			json.competition,
			json.season,
			json.stage,
			json.rounds,
			json.sortDirection,
			json.displayOdds,
			json.bookmakers,
			json.refreshTime,
		);
	}

	static builder(model?: BasketballTournamentProgrammeWidgetModel): BasketballTournamentProgrammeWidgetBuilder {
		return new BasketballTournamentProgrammeWidgetBuilder(model);
	}
}
