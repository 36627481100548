import * as React from 'react';
import { useEffect, useState } from 'react';
import FansUnitedGameModel from '../../blocks/widgets/fans-united/models/fans-united-game.model';
import { fansUnitedService } from '../../../../../App';
import { FansUnitedContent } from './fu-managers-content';
import FansUnitedLeaderboardTemplatesModel from '../../blocks/widgets/fans-united/models/fans-united-leaderboard-templates.model';
import { FansUnitedWidgetsTypes } from '../../constants/block.types';

type Properties = {
	maxGames: number;
	maxTemplates: number;
	selectedId: any;
	onSelect: any;
	t: any;
	type: string;
	componentType: string;
};

export const FansUnitedContentContainer: React.FunctionComponent<Properties> = ({
	t,
	maxGames,
	maxTemplates,
	selectedId,
	onSelect,
	type,
	componentType,
}) => {
	const [games, setGames] = useState<FansUnitedGameModel[]>([]);
	const [templates, setTemplates] = useState<FansUnitedLeaderboardTemplatesModel[]>([]);
	const [isContentLoading, setIsContentLoading] = useState<boolean>(false);

	useEffect(() => {
		if (componentType === FansUnitedWidgetsTypes.leaderboard) {
			loadTemplates(templates, selectedId);
		} else {
			loadGames(games, selectedId);
		}
	}, []);

	useEffect(() => {
		let openGames = fansUnitedService.markFuGameModelAsSelected(games, selectedId);
		setGames(openGames);

		let fuTemplates = fansUnitedService.markFuTemplateModelAsSelected(templates, selectedId);
		setTemplates(fuTemplates);
	}, [selectedId]);

	const loadGames = (games: FansUnitedGameModel[], selectedGameId: string) => {
		setIsContentLoading(true);
		fansUnitedService
			.getFansUnitedGames(type, maxGames)
			.then((games) => {
				const events = fansUnitedService.markFuGameModelAsSelected(games, selectedGameId);
				setGames(events);
				setIsContentLoading(false);
			})
			.catch((e: any) => {
				console.log(e);
			});
	};

	const loadTemplates = (games: FansUnitedLeaderboardTemplatesModel[], selectedId: string) => {
		setIsContentLoading(true);
		fansUnitedService
			.getLeaderboards(maxTemplates)
			.then((templates) => {
				const template = fansUnitedService.markFuTemplateModelAsSelected(templates, selectedId);
				setTemplates(template);
				setIsContentLoading(false);
			})
			.catch((e: any) => {
				console.log(e);
			});
	};

	return (
		<>
			<div className={`${isContentLoading ? 'loading-overlay' : ''}`} data-qa='widget-fans-united-content-container'>
				<FansUnitedContent templates={templates} componentType={componentType} t={t} onContentSelect={onSelect} games={games} />
			</div>
		</>
	);
};
