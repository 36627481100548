import { ContentTypes, FansUnitedWidgetsTypes, WidgetTypes } from '../../../constants/block.types';
import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import { checkComplexHTMLSyntax, checkJSONSyntax } from '../../content/embed-block/helpers/embed-block-edit.helper';
import GettyImageModel from '../../../../../v2/getty/models/getty-image.model';

export default class Validation {
	public validate = (widgetType: string, data: any) => {
		switch (widgetType) {
			case WidgetTypes.odds:
				return this.odds(data);
			case WidgetTypes.match:
				return this.match(data);
			case WidgetTypes.playerProfile:
				return this.playerProfile(data);
			case WidgetTypes.teamProfile:
				return this.teamProfile(data);
			case WidgetTypes.fixtures:
				return this.fixtures(data);
			case WidgetTypes.widgetPlayerH2H:
				return this.widgetPlayerH2H(data);
			case WidgetTypes.widgetTeamComparison:
				return this.widgetTeamComparison(data);
			case WidgetTypes.standings:
				return this.standings(data);
			case WidgetTypes.teamPlayers:
				return this.teamPlayers(data);
			case WidgetTypes.widgetTeamForm:
				return this.widgetTeamForm(data);
			case WidgetTypes.widgetMostDecoratedPlayers:
				return this.widgetMostDecoratedPlayers(data);
			case WidgetTypes.widgetPlayerCareer:
				return this.widgetPlayerCareer(data);
			case WidgetTypes.widgetSquadStatistics:
				return this.widgetSquadStatistics(data);
			case FansUnitedWidgetsTypes.widget1x2:
				return this.widgetFansUnited(data);
			case FansUnitedWidgetsTypes.doubleChance:
				return this.widgetFansUnited(data);
			case FansUnitedWidgetsTypes.correctScore:
				return this.widgetFansUnited(data);
			case FansUnitedWidgetsTypes.htFt:
				return this.widgetFansUnited(data);
			case FansUnitedWidgetsTypes.playerPredictions:
				return this.widgetPlayerPredictions(data);
			case FansUnitedWidgetsTypes.teamPredictions:
				return this.widgetFansUnited(data);
			case FansUnitedWidgetsTypes.topX:
				return this.widgetFansUnitedManagers(data);
			case FansUnitedWidgetsTypes.matchQuiz:
				return this.widgetFansUnitedManagers(data);
			case FansUnitedWidgetsTypes.leaderboard:
				return this.widgetFansUnitedLeaderboard(data);
			default:
				return false;
		}
	};

	public validateBlocks = (blockType: string, data: any) => {
		switch (blockType) {
			case ContentTypes.article:
				return this.content(data);
			case ContentTypes.video:
				return this.content(data);
			case ContentTypes.banner:
				return this.banner(data);
			case ContentTypes.image:
				return this.image(data);
			case ContentTypes.gallery:
				return this.gallery(data);
			case ContentTypes.poll:
				return this.content(data);
			case ContentTypes.embed:
				return this.embed(data);
			case ContentTypes.highlight:
				return this.highlight(data);
			case ContentTypes.link:
				return this.link(data);
			case ContentTypes.dugoutVideo:
				return this.dugoutVideo(data);
			case ContentTypes.imago:
				return this.imagoImage(data);
			case ContentTypes.onnetworkVideo:
				return this.onnetworkVideo(data);
			case ContentTypes.audioBlock:
				return this.audioBlock(data);
			case ContentTypes.instatTeamComparison:
				return this.instatTeamComparison(data);
			case ContentTypes.instatPlayerComparison:
				return this.instatPlayerComparison(data);
			case ContentTypes.instatPlayerSeasonPerformance:
				return this.instatPlayerSeasonPerformance(data);
			case ContentTypes.instatTeamSeasonPerformance:
				return this.instatTeamSeasonPerformance(data);
			case ContentTypes.instatPostMatchTeamsStats:
				return this.instatPostMatchTeamsStats(data);
			case ContentTypes.instatPostMatchPlayersStats:
				return this.instatPostMatchPlayersStats(data);
			case ContentTypes.youtubeSports:
				return this.youtubeVideo(data);
			case ContentTypes.amgBlock:
				return this.amgVideo(data);
			case ContentTypes.getty:
				return this.gettyImage(data);
			default:
				return false;
		}
	};

	bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);

	private odds = (data: any) => {
		const areBookmakersValid = this.bookmakerSelectionEnabled
			? data.oddsModel.bookmakers && data.oddsModel.bookmakers.length > 0
				? true
				: false
			: true;
		const isValid = data.oddsModel && data.oddsModel.match && data.oddsModel.match.id && areBookmakersValid ? true : false;

		return isValid;
	};

	private widgetFansUnitedManagers = (data: any) => {
		const isValidGame = data && data.widget && data.widget.game && data.widget.game.id ? true : false;
		const isValid = isValidGame;

		return isValid;
	};

	private widgetFansUnitedLeaderboard = (data: any) => {
		const isValidGame = data && data.widget && data.widget.template && data.widget.template.id ? true : false;
		const isValid = isValidGame;

		return isValid;
	};

	private widgetFansUnited = (data: any) => {
		const isValidMatch = data.widget && data.widget.match && data.widget.match.id ? true : false;
		const isDisplayOddsEnabled = data.widget.displayOdds === true;
		const areBookmakersValid = this.bookmakerSelectionEnabled ? (isDisplayOddsEnabled ? data.odds && data.odds.length > 0 : true) : true;
		const isValid = isValidMatch && areBookmakersValid;

		return isValid;
	};

	private widgetPlayerPredictions = (data: any) => {
		const isValidMatch =
			data.widget && data.widget.match && data.widget.match.id && data.widget.player && data.widget.player.id ? true : false;
		const isDisplayOddsEnabled = data.widget.displayOdds === true;
		const areBookmakersValid = this.bookmakerSelectionEnabled ? (isDisplayOddsEnabled ? data.odds && data.odds.length > 0 : true) : true;
		const isValid = isValidMatch && areBookmakersValid;

		return isValid;
	};

	private match = (data: any) => {
		const areBookmakersValid = this.bookmakerSelectionEnabled
			? data.oddsModel.bookmakers && data.oddsModel.bookmakers.length > 0
				? true
				: false
			: true;
		const isValidMatch = data.matchModel && data.matchModel.match && data.matchModel.match.id ? true : false;
		const eventId = data.oddsModel && data.oddsModel.match && data.oddsModel.match.id && areBookmakersValid ? true : false;
		const isOddsValid = data.matchModel.displayOdds ? eventId : true;
		const areColorsSelected = data.matchModel.homeTeamColor !== null && data.matchModel.awayTeamColor !== null;
		const areColorsDifferent = data.matchModel.homeTeamColor !== data.matchModel.awayTeamColor;
		return isValidMatch && isOddsValid && areColorsSelected && areColorsDifferent;
	};

	private teamProfile = (data: any) => {
		const isValidTeam =
			data.teamProfile && data.teamProfile.team && data.teamProfile.team.id && data.teamProfile.season && data.teamProfile.season.id
				? true
				: false;
		const areBookmakersValid = this.bookmakerSelectionEnabled
			? data.odds.bookmakers && data.odds.bookmakers.length > 0
				? true
				: false
			: true;
		const eventId = data.odds && data.odds.match && data.odds.match.id && areBookmakersValid ? true : false;
		const isOddsValid = data.teamProfile.displayOdds ? eventId : true;
		const isValid = isOddsValid && isValidTeam;

		return isValid;
	};

	private playerProfile = (data: any) => {
		const isValidPlayer = data.playerProfile && data.playerProfile.player ? true : false;
		const areBookmakersValid = this.bookmakerSelectionEnabled
			? data.oddsModel.bookmakers && data.oddsModel.bookmakers.length > 0
				? true
				: false
			: true;

		const eventId = data.oddsModel && data.oddsModel.match && data.oddsModel.match.id && areBookmakersValid ? true : false;
		const isValid = data.playerProfile && data.playerProfile.displayOdds ? eventId && isValidPlayer : isValidPlayer;

		return isValid;
	};

	private fixtures = (data: any) => {
		const areBookmakersValid = this.bookmakerSelectionEnabled
			? data.oddsModel.bookmakers && data.oddsModel.bookmakers.length > 0
				? true
				: false
			: true;
		const isValid = data.fixtures && data.fixtures.matches && data.fixtures.matches.length > 0 && areBookmakersValid ? true : false;

		return isValid;
	};

	private widgetPlayerH2H = (data: any) => {
		const areBookmakersValid = this.bookmakerSelectionEnabled
			? data.oddsModel.bookmakers && data.oddsModel.bookmakers.length > 0
				? true
				: false
			: true;
		const isValidOddsModel = data.oddsModel && data.oddsModel.match && data.oddsModel.match.id && areBookmakersValid ? true : false;
		const isValidPlayerH2HModel =
			data.playerH2HModel &&
			data.playerH2HModel.player1Seasons &&
			data.playerH2HModel.player1Seasons.length > 0 &&
			data.playerH2HModel.player2Seasons &&
			data.playerH2HModel.player2Seasons.length > 0
				? true
				: false;
		const isValid =
			data.playerH2HModel && data.playerH2HModel.displayOdds ? isValidOddsModel && isValidPlayerH2HModel : isValidPlayerH2HModel;

		return isValid;
	};

	private widgetTeamComparison = (data: any) => {
		const { oddsModel, teamComparisonModel } = data;
		const areBookmakersValid = this.bookmakerSelectionEnabled
			? data.oddsModel.bookmakers && data.oddsModel.bookmakers.length > 0
				? true
				: false
			: true;
		const isValidOddsModel = oddsModel && oddsModel.match && oddsModel.match.id && areBookmakersValid ? true : false;
		const isValidPlayerH2HModel =
			teamComparisonModel &&
			teamComparisonModel.team1 &&
			teamComparisonModel.team1.tournament &&
			teamComparisonModel.team1.tournament.id &&
			teamComparisonModel.team2 &&
			teamComparisonModel.team2.tournament &&
			teamComparisonModel.team2.tournament.id
				? true
				: false;
		const isValid =
			teamComparisonModel && teamComparisonModel.displayOdds ? isValidOddsModel && isValidPlayerH2HModel : isValidPlayerH2HModel;

		return isValid;
	};

	private standings = (data: any) => {
		const { standings } = data;
		const isValid =
			standings &&
			standings.tournament &&
			standings.tournament.id &&
			standings.stageStandings &&
			(!standings.startFrom ||
				(standings.stageStandings && standings.stageStandings.length > 0 && standings.stageStandings.length >= standings.startFrom))
				? true
				: false;

		return isValid;
	};

	private teamPlayers = (data: any) => {
		const { teamPlayers } = data;
		const isValid = teamPlayers && teamPlayers.team && teamPlayers.team.id ? true : false;

		return isValid;
	};

	private widgetTeamForm = (data: any) => {
		const { teamForm } = data;
		const isValidTeam = teamForm && teamForm.team.id ? true : false;
		const isValid = isValidTeam;

		return isValid;
	};

	private widgetSquadStatistics = (data: any) => {
		const { squadStatistics } = data;
		const isValidTeam = squadStatistics && squadStatistics.team.id ? true : false;
		const isValidSeason =
			squadStatistics && squadStatistics.season && squadStatistics.season.data && squadStatistics.season.data.id ? true : false;
		const isValid = isValidTeam && isValidSeason;

		return isValid;
	};

	private widgetMostDecoratedPlayers = (data: any) => {
		const { decoratedPlayers } = data;
		const isValidStartFromPosition = decoratedPlayers.startFrom
			? +decoratedPlayers.startFrom > 0 && +decoratedPlayers.startFrom <= decoratedPlayers.players.length
			: true;
		const isValid =
			decoratedPlayers &&
			decoratedPlayers.tournament &&
			decoratedPlayers.tournament.id &&
			decoratedPlayers.season &&
			decoratedPlayers.season.id
				? true
				: false;

		return isValidStartFromPosition && isValid;
	};

	private widgetPlayerCareer = (data: any) => {
		const { playerCareer } = data;
		const isValidPlayer = playerCareer && playerCareer.player ? true : false;
		const isValid = isValidPlayer;

		return isValid;
	};

	private content = (data: any) => {
		const isValid = data.contentId ? true : false;

		return isValid;
	};

	private banner = (data: any) => {
		const isValid = data.bannerId && data.bannerId.length > 0 ? true : false;

		return isValid;
	};

	private gallery = (data: any) => {
		const isValid = data.gallery.id && data.gallery.mainImage && data.gallery.mainImage.id ? true : false;

		return isValid;
	};

	private image = (data: any) => {
		const { imageBlock } = data;
		const isValid =
			imageBlock &&
			imageBlock.image &&
			imageBlock.image.id &&
			imageBlock.image.id.length > 0 &&
			((imageBlock.description && imageBlock.description.length <= 255) || !imageBlock.description) &&
			((imageBlock.alt && imageBlock.alt.length <= 255) || !imageBlock.alt) &&
			((imageBlock.caption && imageBlock.caption.length <= 255) || !imageBlock.caption)
				? true
				: false;

		return isValid;
	};

	private embed = (data: any) => {
		const isValid = data.embedContent && data.embedContent.length > 0 ? true : false;

		if (isValid && data.validationType === 'json') {
			return checkJSONSyntax(data.embedContent);
		}

		if (isValid && data.validationType === 'html') {
			return checkComplexHTMLSyntax(data.embedContent);
		}

		return isValid;
	};

	private highlight = (data: any) => {
		const isValid =
			data.highlightBlock.title &&
			data.highlightBlock.title.length > 0 &&
			data.highlightBlock.description &&
			data.highlightBlock.description.length > 0
				? true
				: false;

		return isValid;
	};

	private link = (data: any) => {
		const isValid =
			data.linkModel.link && data.linkModel.link.length > 0 && data.linkModel.content && data.linkModel.content.length > 0 ? true : false;

		return isValid;
	};

	private dugoutVideo = (data: any) => {
		const isValid = data.dugoutVideo && data.dugoutVideo.id ? true : false;

		return isValid;
	};

	private imagoImage = (data: any) => {
		const isValid = data.imagoImage && data.imagoImage.id ? true : false;

		return isValid;
	};

	private onnetworkVideo = (data: any) => {
		const isValid = data.onnetworkVideo && data.onnetworkVideo.id ? true : false;

		return isValid;
	};

	private audioBlock = (data: any) => {
		if (data.gbVisionAudio) {
			return this.gbVisionAudio(data);
		}

		if (data.spotlightAudio) {
			return this.spotlightAudio(data);
		}

		return false;
	};

	private gbVisionAudio = (data: any) => {
		const isValid = data.gbVisionAudio && data.gbVisionAudio.home && data.gbVisionAudio.id ? true : false;

		return isValid;
	};

	private spotlightAudio = (data: any) => {
		const isValid = data.spotlightAudio && data.spotlightAudio.homeTeam && data.spotlightAudio.matchId ? true : false;

		return isValid;
	};

	private youtubeVideo = (data: any) => {
		const isValid = data.youtubeVideo && data.youtubeVideo.id && data.youtubeVideo.id.videoId ? true : false;

		return isValid;
	};

	private amgVideo = (data: any) => {
		const isValid = data.amgVideo && data.amgVideo.mediaData && data.amgVideo.mediaData.entryId ? true : false;

		return isValid;
	};

	private instatTeamComparison = (data: any) => {
		const isValid =
			data.team1.stats !== undefined &&
			data.team1.stats !== null &&
			data.team2.stats !== undefined &&
			data.team2.stats !== null &&
			data.team1.stats.length > 0 &&
			data.team2.stats.length > 0;

		return isValid;
	};

	private instatPlayerComparison = (data: any) => {
		const isValid =
			data.player1.stats !== undefined &&
			data.player1.stats !== null &&
			data.player2.stats !== undefined &&
			data.player2.stats !== null &&
			data.player1.stats &&
			data.player1.stats.length > 0 &&
			data.player2.stats &&
			data.player2.stats.length > 0;

		return isValid;
	};

	private instatPlayerSeasonPerformance = (data: any) => {
		const isValid = data.player.stats !== undefined && data.player.stats !== null && data.player.stats.length > 0;

		return isValid;
	};

	private instatTeamSeasonPerformance = (data: any) => {
		const isValid = data.team.stats !== undefined && data.team.stats !== null && data.team.stats.length > 0;

		return isValid;
	};

	private instatPostMatchTeamsStats = (data: any) => {
		const isValid =
			data.match.stats !== undefined &&
			data.match.stats !== null &&
			data.match.stats.length >= 0 &&
			data.match.info !== undefined &&
			data.match.info !== null &&
			data.team1.stats !== undefined &&
			data.team1.stats !== null &&
			data.team2.stats !== undefined &&
			data.team2.stats !== null &&
			data.team1.stats.length > 0 &&
			data.team2.stats.length > 0 &&
			data.team1select !== null &&
			data.team1select !== undefined &&
			data.team1select.name.length > 0 &&
			data.team2select !== null &&
			data.team2select !== undefined &&
			data.team2select.name.length > 0 &&
			data.instatStatistics !== null &&
			data.instatStatistics !== undefined &&
			data.matchSMP !== null &&
			data.matchSMP !== undefined &&
			data.matchSMP.id !== null &&
			data.statsLoading !== true;

		return isValid;
	};

	private instatPostMatchPlayersStats = (data: any) => {
		const isValid =
			data.match.stats !== undefined &&
			data.match.stats !== null &&
			data.match.stats.length >= 0 &&
			data.match.info !== undefined &&
			data.match.info !== null &&
			data.team1.players !== undefined &&
			data.team1.players !== null &&
			data.team2.players !== undefined &&
			data.team2.players !== null &&
			data.team1.players.length > 0 &&
			data.team2.players.length > 0;

		return isValid;
	};

	private gettyImage = (data: Record<string, GettyImageModel>) => {
		const { gettyImage } = data;
		return !!(gettyImage && gettyImage.id && gettyImage.id.length > 0);
	};
}
