import React from 'react';
import GalleryInfo from './gallery-info.component';
import HttpService from '../../../../../../../services/rest/HttpService';
import { Button, Col, Row } from 'reactstrap';
import GalleryMainImage from './gallery-main-image.component';
import { Properties, State } from './properties/gallery-create.properties';
import GalleryBlockModel from '../models/gallery-block.model';
import { connect } from 'react-redux';
import { constructGalleryRelatedContent, galleryToJsonRequest, STEPS, STEPS_BUTTON_TEXT } from './helpers/gallery-create.helper';
import { remapResponceToGallery } from '../helpers/gallery-block.helper';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import { ImageProperties } from '../../../../partials/image-properties/image-properties.component';
import ErrorHandler from '../../../../partials/error/error-handler-component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import { store } from '../../../../../../../store/store';
import { multiLingualService } from '../../../../../../../App';
import { GalleryItems } from './gallery-items.component';
import Image from '../../../../../../../models/image/Image';

class GalleryCreate extends React.Component<Properties, State> {
	contentLanguages = store.getState().project.currentProject;

	constructor(props: Properties) {
		super(props);
		this.state = {
			gallery: GalleryBlockModel.builder()
				.withTitle(props.contentData.title)
				.withAdditionalCategories(props.contentData.additionalCategories)
				.withCategory(props.contentData.category)
				// TODO Refactor to set the language according to the resource language in which is created
				.withLanguage(
					multiLingualService.checkIfProjectIsMultiLingual(this.contentLanguages.languages)
						? this.props.contentLanguage
						: this.contentLanguages.language,
				)
				.build(),
			copyTags: false,
			copySportsConnections: false,
			copySportsRelatedToImages: !window.location.hash.includes('/smp/live-blogs/editorial-admin/'),
			copyAdditionalCategories: false,
			isLoading: false,
			step: 0,
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const data = { gallery: this.state.gallery };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });

		if (isValid) {
			if (this.state.step === 2) this.updateGallery(this.state.step);

			const block = Object.assign({}, this.props.block);
			block.data.id = this.state.gallery.id;
			block.data.main_image_id = this.state.gallery.mainImage.id;
			block.data.preview = { gallery: this.state.gallery };

			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onGalleryInfoChange = (selection: any) => {
		const gallery = GalleryBlockModel.builder(this.state.gallery).withTitle(selection.title).withCategory(selection.category).build();
		this.updateState(gallery, selection.copyTags, selection.copySportsConnections, selection.copyAdditionalCategories);
	};

	onGalleryItemsChange = (items: Image[]) => {
		const { gallery } = this.state;
		gallery.items && gallery.items.push(...items);
		this.updateGalleryState(gallery);
	};

	onCopySportsRelatedToImages = (checked: boolean) => {
		this.setState({ copySportsRelatedToImages: checked });
	};

	updateLoadingState = (isLoading: boolean) => {
		this.setState({ isLoading });
	};

	updateState = (gallery: GalleryBlockModel, copyTags: boolean, copySportsConnections: boolean, copyAdditionalCategories: boolean) => {
		this.setState({
			...this.state,
			gallery,
			copyTags,
			copySportsConnections,
			copyAdditionalCategories,
		});
	};

	updateMainImageState = (image: any) => {
		this.updateGalleryState(GalleryBlockModel.builder(this.state.gallery).withMainImage(image).build());
	};

	postGallery() {
		const { copyTags, copySportsConnections, copyAdditionalCategories, gallery } = this.state;
		const contentConfigRelated = this.props.contentData.related;
		const headers = { Project: this.props.currentProject.domain };

		this.updateLoadingState(true);
		HttpService.post('/galleries', galleryToJsonRequest(gallery, copyAdditionalCategories), headers)
			.then((response: any) => {
				const gallery = GalleryBlockModel.builder(remapResponceToGallery(response.data.data)).withAlignment('center').withWidth('100').build();

				if (
					contentConfigRelated &&
					((copyTags && contentConfigRelated.tags && contentConfigRelated.tags.length > 0) ||
						(copySportsConnections &&
							((contentConfigRelated.sports && contentConfigRelated.sports.length > 0) ||
								(contentConfigRelated.matches && contentConfigRelated.matches.length > 0))))
				) {
					this.setState({ ...this.state, gallery, isValid: true });
					this.addGalleryRelated(gallery, contentConfigRelated, copyTags, copySportsConnections);
				} else {
					this.createGalleryAndContinue(gallery);
				}
			})
			.catch(() => this.updateLoadingState(false));
	}

	addGalleryRelated = (gallery: GalleryBlockModel, related: any, copyTagsRelated: boolean, copySportsRelated: boolean) => {
		const headers = { Project: this.props.currentProject.domain };

		HttpService.post(`/galleries/${gallery.id}/related`, constructGalleryRelatedContent(copySportsRelated, copyTagsRelated, related), headers)
			.then(() => this.updateStepAndLoadingState(1, false))
			.catch(() => this.updateLoadingState(false));
	};

	updateGallery(step: number) {
		const { gallery, copyAdditionalCategories } = this.state;
		this.updateLoadingState(true);
		const headers = { Project: this.props.currentProject.domain };

		HttpService.patch(`/galleries/${gallery.id}`, galleryToJsonRequest(gallery, copyAdditionalCategories), headers)
			.then(() => {
				if (step <= 1) this.updateStepAndLoadingState(step + 1, false);
			})
			.catch(() => {
				this.updateLoadingState(false);
			});
	}

	createGalleryAndContinue(gallery: GalleryBlockModel) {
		this.setState({
			...this.state,
			gallery,
			step: 1,
			isLoading: false,
			isValid: true,
		});
	}

	updateStepAndLoadingState(step: number, isLoading: boolean) {
		this.setState({
			...this.state,
			step,
			isLoading,
			isValid: true,
		});
	}

	updateGalleryState = (gallery: GalleryBlockModel) => {
		this.setState({ ...this.state, gallery });
	};

	returnToPreviewsTab = () => {
		const step = this.state.step - 1;
		this.setState({ ...this.state, step });
	};

	render() {
		const { isLoading, step, gallery, copyAdditionalCategories, copySportsRelatedToImages } = this.state;
		let buttonTitle = STEPS_BUTTON_TEXT[STEPS[step]];

		if (step === 0) {
			buttonTitle = this.props.t('continue_to_add_images');
		} else if (step === 1) {
			buttonTitle = this.props.t('save_images_and_cover_image');
		}

		return (
			<>
				<Row>
					<Col>
						{step === 0 && (
							<GalleryInfo
								gallery={gallery}
								copyAdditionalCategories={copyAdditionalCategories}
								copyTags={this.state.copyTags}
								copySportsConnections={this.state.copySportsConnections}
								onGalleryInfoChange={(selection: any) => {
									this.onGalleryInfoChange(selection);
								}}
								t={this.props.t}
								block={this.props.block}
								allCategories={this.props.allCategories}
								adminCategories={this.props.adminCategories}
								updateState={this.updateState}
								isValid={this.state.isValid}
							/>
						)}
						{step === 1 && (
							<GalleryItems
								onCopySportsRelated={(checked: boolean) => {
									this.onCopySportsRelatedToImages(checked);
								}}
								copySportsRelated={copySportsRelatedToImages}
								sportsRelatedContent={this.props.contentData.related}
								onImagesUploaded={(images: Image[]) => {
									this.onGalleryItemsChange(images);
								}}
								onGalleryItemsChange={this.onGalleryItemsChange}
								gallery={gallery}
							/>
						)}
						{step === 2 && (
							<>
								<GalleryMainImage
									selectedImageId={gallery.mainImage ? gallery.mainImage.id : ''}
									images={gallery.items}
									t={this.props.t}
									onSelect={(image: Image) => {
										this.updateMainImageState(image);
									}}
								/>
								<ImageProperties
									isImageBlock={false}
									t={this.props.t}
									blockId={this.props.block.id}
									blockData={this.state.gallery}
									onChange={this.updateGalleryState}
									contentType='gallery'
									authors={[]}
									displayDescriptionCheckbox={false}
								/>
							</>
						)}
					</Col>
				</Row>
				{!this.state.isValid && !this.state.gallery.mainImage && step === 2 && <ErrorHandler t={this.props.t} errorMessage='no_main_image' />}
				{!this.state.isValid && !this.state.gallery.mainImage && step !== 2 && (
					<ErrorHandler t={this.props.t} errorMessage='continue_to_add_main_image' />
				)}
				<Row className={'mt-3 d-flex '}>
					{step <= 1 && (
						<Col className={'clearfix'}>
							<Button
								color='primary'
								disabled={
									isLoading ||
									(step === 0 &&
										(gallery === undefined ||
											gallery.category === undefined ||
											gallery.title === null ||
											gallery.title === undefined ||
											gallery.title.length < 1 ||
											Object.entries(gallery.category).length < 1)) ||
									(step === 1 && (gallery === undefined || gallery.items === undefined || gallery.items.length < 1))
								}
								className={'cursor-pointer'}
								onClick={() => {
									if (step === 0) this.postGallery();

									if (step === 1) this.updateGallery(step);
								}}
							>
								{isLoading && <i className={'fa fa-spinner fa-pulse'}></i>} {buttonTitle}
							</Button>
						</Col>
					)}
					{step > 0 && (
						<Button color='primary' className={'cursor-pointer ml-auto'} onClick={this.returnToPreviewsTab}>
							{this.props.t('back')}
						</Button>
					)}
				</Row>
			</>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		allCategories: state.category.allCategories,
		adminCategories: state.category.adminCategories,
	};
}

export default connect(mapStateToProps)(GalleryCreate) as React.ComponentType<any>;
