import React from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import HttpService from '../../../../../services/rest/HttpService';
import { Properties, State } from './properties/team-season.properties';
import { createTournamentGroupOptions, seasonToOption } from './helpers/team-season.helper';
import SeasonModel from '../../blocks/widgets/player-profile/models/season/season.model';
import ErrorHandler from '../error/error-handler-component';

export default class TeamSeason extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			seasonsOptions: [],
		};
	}

	componentDidMount() {
		if (this.props.teamId) {
			this.getSeasons(this.props.teamId);
		}
	}

	componentDidUpdate(prevProps: Properties) {
		if (prevProps.teamId !== this.props.teamId) {
			this.getSeasons(this.props.teamId);
		}
	}

	updateSeasonsOptionsState(seasons: any) {
		this.setState({ ...this.state, seasonsOptions: seasons });
	}

	getSeasons(teamId: string) {
		if (teamId) {
			HttpService.getPlayerSeasonStatisticsByTeamId(teamId)
				.then((response: any) => {
					const options = createTournamentGroupOptions(response.data);
					this.updateSeasonsOptionsState(options);
				})
				.catch((e: any) => e);
		}
	}

	render() {
		const { onSeasonSelect, selectedSeason, teamId, t } = this.props;

		if (teamId === undefined || teamId === null || teamId.length < 1) {
			return null;
		}

		return (
			<Row>
				<Col>
					<Label>{t('season')}:</Label>
					<Select
						placeholder={t('select')}
						options={this.state.seasonsOptions}
						value={seasonToOption(selectedSeason)}
						noOptionsMessage={(inputValue) => inputValue && t('no_options')}
						onChange={(selection: any) => onSeasonSelect(selection)}
					/>

					{!this.props.isValid && <ErrorHandler t={this.props.t} errorMessage='field_is_required' />}
				</Col>
			</Row>
		);
	}
}
