import { Col, FormGroup, Label, Row } from 'reactstrap';
import React from 'react';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import { Properties, State } from './properties/gallery-select.properties';
import HttpService from '../../../../../../../services/rest/HttpService';
import {
	checkDuplicateContentId,
	constructContentEntitySearchUrl,
	SearchEntityTypes,
} from '../../content-block/helpers/content-blocks.helper';
import { createOptionFromData } from '../helpers/gallery-select.helper';
import GalleryBlockModel from '../models/gallery-block.model';
import { remapResponceToGallery } from '../helpers/gallery-block.helper';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import { ImageProperties } from '../../../../partials/image-properties/image-properties.component';
import GalleryItems from './gallery-items.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import ErrorHandler from '../../../../partials/error/error-handler-component';
import Masonry from 'react-masonry-css';
import './style/gallery-block-masonry.scss';
import { multiLingualService } from '../../../../../../../App';
import { customOption } from '../../../../../Sidebar/Media/subcomponents/media-content/helpers/MainContentMediaContainer';

export default class GallerySelect extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			gallery:
				props.gallery && props.gallery.id
					? props.gallery
					: GalleryBlockModel.builder().withWidth('100').withAlignment('center').withStatus(props.gallery.status).build(),
			newValue: '',
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const data = { gallery: this.state.gallery };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.id = this.state.gallery.id;
			block.data.preview = { gallery: this.state.gallery };
			block.data.main_image_id = this.state.gallery.mainImage.id;

			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>) {
		if (this.props.gallery.id !== prevProps.gallery.id) {
			this.setState({ ...this.state, gallery: this.props.gallery });
		}
	}

	onInputChange = (input: string) => {
		this.setState({ ...this.state, newValue: input });

		return input;
	};

	searchGallery = (search: string, callback: any) => {
		let headers = { Project: this.props.currentProject.domain };
		if (
			multiLingualService.checkIfProjectIsMultiLingual(this.props.currentProject.languages) &&
			multiLingualService.checkIfContentTypeIsValidForMultiLingual(this.props.contentData.contentType)
		) {
			HttpService.get(
				multiLingualService.constructContentEntityMultiLingualSearchUrl(search, SearchEntityTypes.galleries, this.props.contentData.language),
				null,
				headers,
			)
				.then((response: any) => {
					checkDuplicateContentId(response, callback);
				})
				.catch((e: any) => e);
		} else {
			HttpService.get(constructContentEntitySearchUrl(search, SearchEntityTypes.galleries), null, headers)
				.then((response: any) => {
					checkDuplicateContentId(response, callback);
				})
				.catch((e: any) => e);
		}
	};

	updateGalleryState = (gallery: GalleryBlockModel) => {
		this.setState({ ...this.state, gallery });
	};

	onGalleryChange = (galleryId: string) => {
		const header = { Project: this.props.currentProject.domain };

		HttpService.get(`/galleries/${galleryId}`, null, header)
			.then((response: any) => {
				let gallery = GalleryBlockModel.builder(remapResponceToGallery(response.data.data))
					.withStatus(response.data.data.status)
					.withAlignment('center')
					.withWidth('100')
					.build();
				this.updateGalleryState(gallery);
			})
			.catch((e: any) => e);
	};

	render() {
		const { gallery } = this.state;

		return (
			<>
				<Row>
					<Col>
						<FormGroup>
							<Label>{this.props.t('choose_gallery')}:</Label>
							<AsyncSelect
								className={'mb-2'}
								loadOptions={debounce(this.searchGallery, 500)}
								onInputChange={debounce(this.onInputChange, 500)}
								value={createOptionFromData(gallery)}
								onChange={(selection: any) => {
									this.onGalleryChange(selection.value);
								}}
								formatOptionLabel={customOption}
							/>
							{!this.state.isValid && !this.state.gallery.id && <ErrorHandler t={this.props.t} errorMessage='field_is_required' />}
						</FormGroup>
					</Col>
				</Row>

				{gallery && gallery.id && (
					<>
						<Row>
							<Col>
								<FormGroup>
									<Label>{this.props.t('change_display_image')}:</Label>
									<Row>
										{gallery.items.length > 0 ? (
											<Masonry
												breakpointCols={{
													default: 5,
													1360: 4,
													1100: 3,
													982: 5,
													720: 4,
													560: 3,
													400: 2,
												}}
												className='gallery-block-masonry-grid'
												columnClassName='gallery-block-grid_column'
											>
												{gallery.items.map((item: any) => (
													<GalleryItems key={item.id} item={item} gallery={gallery} updateGalleryState={this.updateGalleryState} />
												))}
											</Masonry>
										) : (
											<h6 className='gallery-block-missing-images'>{this.props.t('missing_images')}</h6>
										)}
									</Row>
									{!this.state.isValid && this.state.gallery.mainImage === null && (
										<ErrorHandler t={this.props.t} errorMessage='choose_main_media' />
									)}
								</FormGroup>
							</Col>
						</Row>

						<ImageProperties
							t={this.props.t}
							isImageBlock={false}
							blockId={this.props.block.id}
							blockData={this.state.gallery}
							onChange={this.updateGalleryState}
							authors={[]}
							contentType='gallery'
							displayDescriptionCheckbox={false}
						/>
					</>
				)}
			</>
		);
	}
}
