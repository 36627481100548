import * as React from 'react';
import { Properties, State } from './Properties/GalleryItemsProps';
import Select from 'react-select';
import { Col, Row } from 'reactstrap';
import CustomCollapsibleElement from '../../../Partials/BaseComponents/CustomCollapsibleElement/CustomCollapsibleElement';
import ImageListingContainer from '../../../Partials/Sidebar/Media/subcomponents/media-main-image/subcomponents/image-listing/ImageListingContainer';
import Image from '../../../../models/image/Image';
import GalleryItem from '../../../../models/gallery/item/GalleryItem';
import { GalleryItemConstants } from '../../../../constants/GalleryItemConstants';
import * as ReactSortableHOC from 'react-sortable-hoc';
import { SortEnd } from 'react-sortable-hoc';
import GallerySortableItemList from './Subcomponents/GalleryItemsSortableList';
import arrayMove from 'array-move';
import ImageUploadButtonContainer from '../../../Partials/Shared/image-upload/image-upload-button-container';
import ImageCropContainer from '../../../Partials/Shared/image-crop/image-crop-container';
import LightboxContainer from '../../Images/Subcomponents/lightbox/LightboxContainer';
import ImageEditModal from '../../Images/Subcomponents/image-edit-modal/ImageEditModal';
import {
	deselectAllKeys,
	extractNotWatermarkedItem,
	extractWatermarkedItem,
	galleryItemsActions,
	generateMapImageIdFilenameForWatermarkAdd,
	generateMapImageIdFilenameWatermark,
	imageToGalleryItem,
	selectAllItemsCondition,
	selectAllKeys,
} from './helpers/gallery-items.helpers';
import UpdateGalleryItemsModal from './gallery-items-update-modal/gallery-items-update-modal-container';
import Related from '../../../../models/related/Related';
import HttpService from '../../../../services/rest/HttpService';
import EnumItem from '../../../../models/enum/EnumItem';
import { toast } from 'react-toastify';
import { customOption } from './Subcomponents/custom-option';
import { ContentTypes } from '../../../Partials/Blocky/constants/block.types';
import Author from '../../../../models/author/Author';
import { extractImageAuthorsIds, extractNonDefaultOrigin } from '../../Images/Subcomponents/helpers/image-cells.helpers';
import WatermarkService from '../../../../services/watermark-service/WatermarkService';
import { checkForWatermarkFeature, getQuickWatermark } from '../../../../services/watermark-service/watermark-helper';
import Watermark from '../../../../models/watermarks/Watermark';
import { actionService, featuresService, multiLingualService } from '../../../../App';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import { connect } from 'react-redux';
import { Subscription } from 'rxjs';
import { IMAGE_UPLOAD_FAILED, IMAGE_UPLOAD_REQUEST, IMAGE_UPLOAD_SUCCESS } from '../../../../store/action-creators/ImageActionCreator';

const SortableList = ReactSortableHOC.SortableContainer(GallerySortableItemList);

class GalleryItems extends React.Component<Properties, State> {
	private refSelect: React.RefObject<HTMLInputElement>;
	private actionServiceSubject = new Subscription();

	watermarkService = new WatermarkService();

	constructor(props: any) {
		super(props);
		this.state = {
			imageId: '',
			showCropModal: false,
			showEditModal: false,
			enlargeImage: false,
			imageToEnlarge: {} as Image,
			showUpdateItemModal: false,
			selectedItems: [],
			actionDropdownValue: [],
			selectedItemsWatermarked: [],
			selectedItemsForWatermark: [],
			quickWatermarkChecked: this.props.quickWatermarkChecked,
			quickWatermarkEnabled: false,
			defaultWatermark: Watermark.builder().build(),
			disableQuickWatermarkCheckBox: false,
			uploadingFiles: false,
			resourcesLoading: null,
			itemsCount: props.items ? props.items.length : 0,
		};
		this.refSelect = React.createRef();
		this.registerActionEventListener();
		this.onApplyQuickWatermarkChecked = this.onApplyQuickWatermarkChecked.bind(this);
	}

	initWaterMarkService() {
		if (featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WATERMARK)) {
			this.watermarkService = new WatermarkService();
			return true;
		} else return false;
	}

	registerActionEventListener() {
		this.actionServiceSubject = actionService.onActionReceived().subscribe((action: string) => this.watermarkCheckboxStateUpdate(action));
	}

	componentDidMount() {
		document.addEventListener('keydown', this.addAllImagesForUpdate);
		document.addEventListener('keydown', this.removeAllImagesForUpdate);

		if (checkForWatermarkFeature()) {
			this.setState({ quickWatermarkEnabled: getQuickWatermark() });
		}

		this.initWaterMarkService() &&
			this.watermarkService.getDefaultWatermark().then((defaultWatermark) => {
				this.setState({ defaultWatermark: defaultWatermark });
			});
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any) {
		if (prevProps !== this.props) {
			if (this.props.quickWatermarkChecked) {
				this.setState({ quickWatermarkChecked: true });
			} else {
				this.setState({ quickWatermarkChecked: false });
			}
			this.removeAllItems();
		}

		if (this.props.items.length !== this.state.itemsCount) {
			this.setState({ ...this.state, itemsCount: this.props.items.length });
		}

		if (this.state.uploadingFiles === true && prevState.uploadingFiles === false) {
			this.setState({ ...this.state, resourcesLoading: true });
		}

		if (prevState.itemsCount !== this.state.itemsCount && !this.state.uploadingFiles) {
			this.setState({ ...this.state, resourcesLoading: false });
		}
	}

	onApplyQuickWatermarkChecked = (e: any) => {
		this.props.onQuickWatermarkChange(e.target.checked);
		this.setState({ quickWatermarkChecked: e.target.checked });
	};

	addAllItems = () => {
		let imagesWithoutWatermark = this.props.items.filter(
			(item: GalleryItem) => this.checkIfValidForWatermark(item) && (!item.generic || !item.generic.watermark),
		);
		this.setState({
			...this.state,
			selectedItems: this.props.items.map((item: GalleryItem) => item.data.id),
			selectedItemsWatermarked: generateMapImageIdFilenameWatermark(this.props.items),
			selectedItemsForWatermark: generateMapImageIdFilenameForWatermarkAdd(imagesWithoutWatermark),
		});
	};

	/**
	 * This function is responsible for handling the enabled/disabled state of the watermark checkbox
	 * while an image is being uploaded and after the upload has failed/succeeded
	 *  */
	watermarkCheckboxStateUpdate = (action: string) => {
		if (action === IMAGE_UPLOAD_REQUEST) {
			this.setState({ disableQuickWatermarkCheckBox: true });
		}

		if (action === IMAGE_UPLOAD_SUCCESS || action === IMAGE_UPLOAD_FAILED) {
			this.setState({ disableQuickWatermarkCheckBox: false });
		}
	};

	// Potential addition of 3rd party flag in the Content API for project origins to be able to get the images only eligable for watermark
	// TODO Refactor after Content API supports type for origins for a given project
	checkIfValidForWatermark = (image: GalleryItem) => {
		const ownersNotValidForWatermark = [extractNonDefaultOrigin(this.props.owners)];
		let result = [];
		let isValid: boolean;
		ownersNotValidForWatermark.forEach((item: any) => {
			result = item.filter((origin: any) => image.data.owner && image.data.owner.name === origin.label);
		});

		if (result.length === 0) {
			isValid = true;
		} else {
			isValid = false;
		}

		return isValid;
	};

	removeAllItems = () =>
		this.setState({
			...this.state,
			selectedItems: [],
			selectedItemsWatermarked: [],
			selectedItemsForWatermark: [],
		});

	addAllImagesForUpdate = (e: any) => {
		e.stopPropagation();

		if (selectAllItemsCondition(e, selectAllKeys)) {
			this.addAllItems();
		}
	};

	removeAllImagesForUpdate = (e: any) => {
		e.stopPropagation();

		if (selectAllItemsCondition(e, deselectAllKeys)) {
			this.removeAllItems();
		}
	};

	onImageSelect(image: Image) {
		let imagesWithSameId = this.props.items.filter((item: GalleryItem) => item.id === image.id);

		if (!imagesWithSameId.length) {
			const item: GalleryItem = GalleryItem.builder()
				.withComment(image.description ? image.description : '')
				.withId(image.id)
				.withType(GalleryItemConstants.IMAGE_TYPE)
				.withData(image)
				.withGeneric(image.generic)
				.build();
			this.addItem(item);
		} else {
			toast.warn(this.props.t('gallery_item_already_added'));
		}
	}

	onItemUpdate(item: GalleryItem, index: number) {
		let items = this.props.items;
		items[index] = item;
		this.updateItemList(items);
	}

	addItem(item: GalleryItem) {
		const galleryItems = this.props.items;
		galleryItems.push(item);
		this.props.onItemsChange(galleryItems);
	}

	updateItemList(items: GalleryItem[]) {
		this.props.onItemsChange(items);
	}

	onContentDelete = (index: number) => {
		let items = this.props.items;
		items.splice(index, 1);
		this.updateItemList(items);
	};

	deleteSelectedItems = () => {
		const { selectedItems } = this.state;
		this.updateItemList(this.props.items.filter((item: GalleryItem) => !selectedItems.includes(item.data.id)));
		this.setState({
			...this.state,
			selectedItems: [],
			actionDropdownValue: [],
			selectedItemsWatermarked: [],
			selectedItemsForWatermark: [],
		});
	};

	removeWatermarkFromItems = () => {
		const { selectedItemsWatermarked } = this.state;
		try {
			selectedItemsWatermarked.forEach((image: object) => {
				let imageID = Object.keys(image)[0];
				let imageFileName = Object.values(image)[0];

				this.watermarkService.deleteWatermarkSingleImage(imageFileName, imageID).then((resp: any) => {
					let newItems = this.props.items.map((item: GalleryItem) => {
						if (item.id === imageID && resp) {
							return GalleryItem.builder()
								.withType('image')
								.withComment(item.comment ? item.comment : '')
								.withId(resp.data && resp.data.data && resp.data.data.id && resp.data.data.id)
								.withGeneric(resp.data && resp.data.data && resp.data.data.generic)
								.withData(resp.data && resp.data.data)
								.withAddDescriptionToMedia(item.addDescriptionToMedia)
								.build();
						} else {
							return item;
						}
					});
					this.props.onItemsChange(newItems);
					this.setState({ selectedItemsWatermarked: [] });
				});
			});
		} catch (e) {
			toast.error(this.props.t('delete_watermark_failed'));
		}
	};

	async imageOriginPatchRequest(owner: EnumItem) {
		const headers = { Project: this.props.project.domain };
		const itemsForUpdate = this.props.items.filter((item: GalleryItem) => this.state.selectedItems.includes(item.data.id));
		itemsForUpdate.map((itemUpdate: GalleryItem) => {
			return HttpService.patch(`/images/${itemUpdate.data.id}`, { owner: owner.id }, headers).then((resp: any) => {
				let newItems = this.props.items.map((item: GalleryItem) => {
					if (item.id === itemUpdate.data.id && resp) {
						return GalleryItem.builder()
							.withType('image')
							.withComment(item.comment ? item.comment : '')
							.withId(resp.data && resp.data.data && resp.data.data.id && resp.data.data.id)
							.withData(resp.data && resp.data.data)
							.withAddDescriptionToMedia(item.addDescriptionToMedia)
							.build();
					} else {
						return item;
					}
				});
				this.updateItemList(newItems);
			});
		});
	}

	addWatermarkToItems = () => {
		const { selectedItemsForWatermark } = this.state;

		try {
			selectedItemsForWatermark.forEach((image: object) => {
				let imageID = Object.keys(image)[0];
				let imageFileName = Object.values(image)[0];

				this.watermarkService.addWatermarkSingleImage(imageFileName, imageID, this.state.defaultWatermark.id).then((resp: any) => {
					let newItems = this.props.items.map((item: GalleryItem) => {
						if (item.id === imageID && resp) {
							return GalleryItem.builder()
								.withType('image')
								.withComment(item.comment ? item.comment : '')
								.withId(resp.data && resp.data.data && resp.data.data.id && resp.data.data.id)
								.withGeneric(resp.data && resp.data.data && resp.data.data.generic)
								.withData(resp.data && resp.data.data)
								.withAddDescriptionToMedia(item.addDescriptionToMedia)
								.build();
						} else {
							return item;
						}
					});
					this.props.onItemsChange(newItems);
					this.setState({ selectedItemsForWatermark: [] });
				});
			});
		} catch (e) {
			toast.error(this.props.t('add_watermark_failed'));
		}
	};

	async updateImageRelated(imageRelated: Related[]) {
		const headers = { Project: this.props.project.domain };
		this.state.selectedItems.forEach((item: string) => HttpService.post(`/images/${item}/related`, imageRelated, headers));
	}

	updateSportsConnections = async (imageRelated: Related[]) => {
		await this.updateImageRelated(imageRelated)
			.then(() => toast.success(this.props.t('sports_connections_update_success')))
			.catch(() => toast.error(this.props.t('sports_connections_update_failed')));
		this.setState({ ...this.state, selectedItems: [], actionDropdownValue: [] });
	};

	updateOrigin = async (owner: EnumItem) => {
		await this.imageOriginPatchRequest(owner)
			.then(() => toast.success(this.props.t('origin_update_success')))
			.catch(() => toast.error(this.props.t('origin_update_failed')));
		this.setState({ ...this.state, selectedItems: [], actionDropdownValue: [] });
		this.updateItemList(this.props.items);
	};

	async imageAuthorsPatchRequest(authors: Author[]) {
		const headers = { Project: this.props.project.domain };
		const itemsForUpdate = this.props.items.filter((item: GalleryItem) => this.state.selectedItems.includes(item.data.id));
		const imageAuthors = extractImageAuthorsIds(authors);

		itemsForUpdate.forEach((item: GalleryItem) => HttpService.patch(`/images/${item.data.id}`, { authors: imageAuthors }, headers));
	}

	updateAuthors = async (authors: Author[]) => {
		await this.imageAuthorsPatchRequest(authors)
			.then(() => toast.success(this.props.t('author_update_success')))
			.catch(() => toast.error(this.props.t('author_update_failed')));
		this.setState({ ...this.state, selectedItems: [], actionDropdownValue: [] });
	};

	mapTypeToItems = async (type: any) => {
		const headers = { Project: this.props.project.domain };
		const itemsForUpdate = this.props.items.filter((item: GalleryItem) => this.state.selectedItems.includes(item.data.id));
		itemsForUpdate.forEach((item: GalleryItem) => HttpService.patch(`/images/${item.data.id}`, { type }, headers));
	};

	updateType = async (type: any) => {
		await this.mapTypeToItems(type)
			.then(() => toast.success(this.props.t('image_type_update_success')))
			.catch(() => toast.error(this.props.t('image_type_update_failed')));
		this.setState({ ...this.state, selectedItems: [], actionDropdownValue: [] });
	};

	onSortEnd = (sortEnd: SortEnd) => {
		let items = this.props.items;
		items = arrayMove(items, sortEnd.oldIndex, sortEnd.newIndex);
		this.updateItemList(items);
	};

	onImagesUploaded = (imageList: Image[]) => {
		let items = this.props.items;
		const convertedItemList = imageList.map((image: Image) => imageToGalleryItem(image));
		items.push(...convertedItemList);
		this.updateItemList(items);
	};

	toggleEditModal = (imageId: string, isOpen: boolean) => {
		if (!isOpen) {
			this.setState({ ...this.state, showEditModal: isOpen, imageId });
		} else this.setState({ ...this.state, showEditModal: isOpen, imageId });
	};

	toggleCropModal = (imageId: string, isOpen: boolean) => {
		this.setState({ ...this.state, showCropModal: isOpen, imageId });
	};

	toggleUpdateItemsModal = (isOpen: boolean) => {
		this.setState((state: State) => {
			return { ...state, showUpdateItemModal: isOpen, actionDropdownValue: isOpen ? state.actionDropdownValue : [] };
		});
	};

	onUpdateActionSelect = (selection: any) => {
		// react-select issue - it does not lose focus when onChange opens a modal.
		// Using ref and explicitly setting blur fix it.
		// @ts-ignore
		this.refSelect.current.blur();
		this.setState({ ...this.state, actionDropdownValue: selection, showUpdateItemModal: true });
	};

	toggleImageEnlarge = (image: Image, enlarge: boolean) => {
		this.setState({ ...this.state, enlargeImage: enlarge, imageToEnlarge: image });
	};

	toggleItemsForUpdate = (itemId: string) => {
		const { selectedItems, selectedItemsWatermarked, selectedItemsForWatermark } = this.state;
		let galleryItemForWatermarkRemoval = extractWatermarkedItem(itemId, this.props.items);
		let galleryItemsForWatermarkAdd = extractNotWatermarkedItem(itemId, this.props.items);
		let mapImageIDandFilename = generateMapImageIdFilenameWatermark(galleryItemForWatermarkRemoval);
		let mapImageForWatermarkIDAndName = generateMapImageIdFilenameForWatermarkAdd(galleryItemsForWatermarkAdd);

		if (selectedItems.includes(itemId)) {
			//Check if the item is already added and remove it from the selection, update the state.
			let newSelectedGalleryItems: string[] = selectedItems.filter((id: string) => id !== itemId);
			let newWatermarkedItems = selectedItemsWatermarked.filter((item) => Object.keys(item)[0] !== itemId);
			let newItemsForWatermark = selectedItemsForWatermark.filter((item) => Object.keys(item)[0] !== itemId);
			this.setState({
				...this.state,
				selectedItems: newSelectedGalleryItems,
				selectedItemsWatermarked: newWatermarkedItems,
				selectedItemsForWatermark: newItemsForWatermark,
			});
		} else {
			let updatedWatermarkState =
				mapImageIDandFilename && mapImageIDandFilename.length > 0
					? [...this.state.selectedItemsWatermarked, mapImageIDandFilename[0]]
					: [...this.state.selectedItemsWatermarked];

			let updatedForWatermarkState =
				mapImageForWatermarkIDAndName && mapImageForWatermarkIDAndName.length > 0
					? [...this.state.selectedItemsForWatermark, mapImageForWatermarkIDAndName[0]]
					: [...this.state.selectedItemsForWatermark];

			this.setState((state) => {
				return {
					...state,
					selectedItems: [...state.selectedItems, itemId],
					selectedItemsWatermarked: updatedWatermarkState,
					selectedItemsForWatermark: updatedForWatermarkState,
				};
			});
		}
	};

	displayList(items: GalleryItem[], t: any) {
		if (items.length > 0) {
			return (
				<SortableList
					toggleItemsForUpdate={this.toggleItemsForUpdate}
					selectedItems={this.state.selectedItems}
					items={items}
					useDragHandle
					t={t}
					onEdit={(imageId: string) => this.toggleEditModal(imageId, true)}
					onImageClick={(image: Image) => this.toggleImageEnlarge(image, true)}
					onCrop={(imageId: string) => this.toggleCropModal(imageId, true)}
					onItemUpdate={this.onItemUpdate.bind(this)}
					onSortEnd={this.onSortEnd.bind(this)}
					onDelete={this.onContentDelete.bind(this)}
					analytics={this.props.analytics}
				/>
			);
		} else return <div className={'m-5 text-center text-muted'}>{t('gallery_items_placeholder')}</div>;
	}

	actionForSelectedItemsUpdate = () => {
		return {
			sportsConnections: this.updateSportsConnections,
			origin: this.updateOrigin,
			type: this.updateType,
			authors: this.updateAuthors,
			delete: this.deleteSelectedItems,
			deleteWatermark: this.state.quickWatermarkEnabled && this.removeWatermarkFromItems,
			addWatermark: featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WATERMARK) && this.addWatermarkToItems,
		};
	};

	toggleUploadingFiles = (isUploading: boolean) => {
		this.setState({ ...this.state, uploadingFiles: isUploading });
	};

	render() {
		const { t, project, items } = this.props;
		const {
			imageId,
			imageToEnlarge,
			enlargeImage,
			showCropModal,
			actionDropdownValue,
			showEditModal,
			showUpdateItemModal,
			selectedItems,
			quickWatermarkEnabled,
			quickWatermarkChecked,
			selectedItemsWatermarked,
			selectedItemsForWatermark,
			disableQuickWatermarkCheckBox,
			resourcesLoading,
		} = this.state;
		const itemsForUpdate = items.filter((item: GalleryItem) => selectedItems.includes(item.data.id));
		const watermarkedItemsIDs = selectedItemsWatermarked.map((item: object) => {
			return Object.keys(item)[0];
		});
		const NonWatermarkedItemsIDs = selectedItemsForWatermark.map((item: object) => {
			return Object.keys(item)[0];
		});
		const watermarkItemsForUpdate = items.filter((item: GalleryItem) => watermarkedItemsIDs.includes(item.data.id));
		const itemsForWatermarkUpdate = items.filter((item: GalleryItem) => NonWatermarkedItemsIDs.includes(item.data.id));
		const customStyles = {
			option: (base: any) => ({
				...base,
				padding: 0,
			}),
		};
		return (
			<>
				<Row>
					<Col>
						<div className='card'>
							<div className='card-header'>
								<div className='d-flex align-items-center'>
									<div className='mr-auto mb-0'> {t('gallery_items')}</div>
									{quickWatermarkEnabled && (
										<div className='d-flex align-items-center py-2 ml-2'>
											<input
												type='checkbox'
												id='apply-quick-watermark'
												onChange={(event) => this.onApplyQuickWatermarkChecked(event)}
												checked={quickWatermarkChecked}
												disabled={disableQuickWatermarkCheckBox}
											/>
											<label className='mx-2 mb-0' htmlFor='apply-quick-watermark-items'>
												{t('quick_watermark')}
											</label>
										</div>
									)}
									{this.props.children}
								</div>
							</div>
							<div className='d-flex flex-wrap pt-3 px-2'>
								{items.length > 0 && (
									<div
										className='d-flex align-items-center py-2 ml-2'
										title={`${selectedItems.length === 0 ? 'Ctrl + Shift + A' : 'Ctrl + Shift + L'}`}
									>
										<input
											type='checkbox'
											id='selected-all-gallery-items'
											onChange={selectedItems.length !== items.length ? this.addAllItems : this.removeAllItems}
											checked={selectedItems.length === items.length}
										/>
										<label className='mx-2 mb-0' htmlFor='selected-all-gallery-items'>
											{selectedItems.length !== items.length ? t('select_all_items') : t('deselect_all_items')}
										</label>
									</div>
								)}
								{items.length > 0 && (
									<Col xs='8' sm='4' md='4' lg='3'>
										<Select
											// @ts-ignore
											ref={this.refSelect}
											formatOptionLabel={customOption}
											onChange={this.onUpdateActionSelect}
											className='mb-0'
											placeholder={t('edit_gallery_items')}
											options={galleryItemsActions(t)}
											value={actionDropdownValue}
											styles={customStyles}
										/>
									</Col>
								)}
								<div className='ml-auto mr-2'>
									<ImageUploadButtonContainer
										imageUploadButtonId={'galleries-image-upload-button'}
										project={project}
										applyQuickWatermark={this.props.quickWatermarkChecked}
										t={t}
										onMultiImageUploadSuccess={this.onImagesUploaded.bind(this)}
										onImageUploadSuccess={() => undefined}
										display
										toggleUploading={this.toggleUploadingFiles}
									/>
								</div>
							</div>
							<div className={resourcesLoading && this.state.uploadingFiles ? 'loading-overlay' : 'card-body'}>{this.displayList(items, t)}</div>
						</div>
					</Col>
					<Col col='4' lg='4' md='12' sm='12' xs='12'>
						<CustomCollapsibleElement status={'open'} title={t('images')} iconClass={'fa fa-search-plus'}>
							<ImageListingContainer
								withToggle={false}
								onImageSelect={this.onImageSelect.bind(this)}
								t={t}
								currentProject={project}
								contentType={ContentTypes.gallery}
							/>
						</CustomCollapsibleElement>
					</Col>
				</Row>
				<ImageEditModal
					items={this.props.items}
					onItemsChange={this.props.onItemsChange}
					onClose={() => this.toggleEditModal('', false)}
					imageId={imageId}
					currentProject={project}
					open={showEditModal}
					t={t}
					isPlayingSurface={false}
					watermarkEnabled={featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.WATERMARK)}
					isImagoImage={false}
				/>
				<ImageCropContainer
					t={t}
					imageId={imageId}
					currentProject={project}
					open={showCropModal}
					onClose={() => this.toggleCropModal('', false)}
				/>
				{enlargeImage && (
					<LightboxContainer t={t} currentProject={project} imageToEnlarge={imageToEnlarge} toggleImageEnlarge={this.toggleImageEnlarge} />
				)}
				<UpdateGalleryItemsModal
					toggleItemsForUpdate={this.toggleItemsForUpdate}
					project={project}
					updateAction={actionDropdownValue}
					isToggledModal={showUpdateItemModal}
					toggleModal={this.toggleUpdateItemsModal}
					onContentUpdate={actionDropdownValue.value && this.actionForSelectedItemsUpdate()[actionDropdownValue.value]}
					content={itemsForUpdate}
					watermarkedContent={watermarkItemsForUpdate}
					contentForWatermark={itemsForWatermarkUpdate}
					t={t}
					contentLanguage={
						multiLingualService.checkIfProjectIsMultiLingual(this.props.project.languages)
							? this.props.project.languages.defaultLanguageCode.languageCode
							: this.props.project.language
					}
				/>
			</>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		owners: state.imageOwners.owners,
	};
}

export default connect(mapStateToProps, null)(GalleryItems) as React.ComponentType<any>;
