import ImageModel from '../../../../../../models/v2/image/image.model';
import ListModel from '../../../../../../models/v2/list/list';
import ListItemModel from '../../../../../../models/v2/list/list-item';
import { imageApiProcessUrl, imagePlaceholderBase64 } from '../../../../../../global-helpers/global.constants';
import { ContentTypes } from '../../../../../../constants/content-types';
import LiveBlogModel from '../../../models/live-blog.model';
import AuthorModel from '../../../../../../models/v2/author/author.model';
import { getListDetails } from '../../../../../Partials/Sidebar/list-refactored/helpers/list-http.helper';
import { isTempIdOrEmpty } from '../../../../../../global-helpers/global.helpers';
import HttpService from '../../../../../../services/rest/HttpService';
import { responseToModelList } from '../../../../../../models/v2/list/list.mapper';
import { toast } from 'react-toastify';

export const getImageSrcOrPlaceholderImage = (image: ImageModel) => {
	return image && image.path ? `${imageApiProcessUrl}${image.path}` : imagePlaceholderBase64;
};

export const getNotLiveBlogItemImageSrcOrPlaceholderImage = (imagePath: string): string => {
	return imagePath ? `${imageApiProcessUrl}${imagePath}` : imagePlaceholderBase64;
};

export const shouldShowListItem = (selectedList: ListModel, contentModelSlug: string) => {
	if (selectedList.items && selectedList.items.length > 0) {
		const listItemsSlugs = selectedList.items.map((item: ListItemModel) => item.data && item.data.slug);
		return listItemsSlugs.includes(contentModelSlug);
	}

	return false;
};

export const generateNewListItem = (contentModel: LiveBlogModel): ListItemModel => {
	return {
		type: ContentTypes.LIVE_BLOG,
		meta: {},
		data: contentModel,
		lockedPosition: null,
	} as ListItemModel;
};

export const returnListDetails = async (selectedListId: string, projectDomain: string): Promise<ListModel> => {
	return getListDetails(selectedListId, projectDomain);
};

export const isLiveBlogListItem = (type: string) => {
	return type === ContentTypes.LIVE_BLOG;
};

export const displayAuthorNamesString = (t: any, authors: AuthorModel[]): string => {
	const authorsString = authors && authors.length > 0 ? authors.map((author: AuthorModel) => author.name).join(', ') : '';
	return authorsString ? `${t('authors')}: ${authorsString}` : '';
};

export const addIdToListItemsOnCreate = (list: ListModel, liveBlogId: string) => {
	let finalData = list;
	if (liveBlogId && finalData && finalData.items && finalData.items.length > 0) {
		finalData.items.forEach((item) => {
			if (!item.data.id) {
				item.data['id'] = liveBlogId;
			}
			return finalData.items.concat(item);
		});
	}

	return finalData;
};

export const getPresentInData = (
	contentModelId: string,
	projectDomain: string,
	setIsLoading: (value: boolean) => void,
	setListPresentIn: (data: ListModel[]) => void,
) => {
	const headers = { Project: projectDomain };

	HttpService.get(`/lists/search?query=*&itemIds=${contentModelId}`, null, headers)
		.then((response: any) => {
			const listsFromResponse = response.data.data;
			const remappedListData = listsFromResponse.map((respList: any) => responseToModelList(respList)) || [];
			setListPresentIn(remappedListData);
			setIsLoading(false);
		})
		.catch((e: any) => {
			toast.error(e.response.data.message);
			setIsLoading(false);
		});
};

export const getListsContent = (projectDomain: string, setStateFunc: Function, page: string = '1', limit: string = '200') => {
	requestListsContent(projectDomain, page, limit).then((response: any) => {
		const listsFromResponse = response.data.data;
		const remappedLists = listsFromResponse.map((respList: any) => responseToModelList(respList));
		setStateFunc(remappedLists);
	});
};

export const requestListsContent = (projectDomain: string, page: string = '1', limit: string = '200') => {
	const header = { Project: projectDomain };

	return HttpService.get(`/v2/lists?page=${page}&content_type=editorial&limit=${limit}`, null, header);
};
