import * as React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { DebounceInput } from 'react-debounce-input';

type BioProps = {
	t: any;
	onChange: (text: string) => any;
	value?: string;
};

export const Bio: React.FunctionComponent<BioProps> = ({ onChange, t, value }) => {
	return (
		<FormGroup>
			<Label htmlFor='bio'>{t('bio')}</Label>
			<DebounceInput
				element='textarea'
				name='bio-input'
				id='bio-input'
				rows='7'
				className='form-control-lg form-control'
				onChange={(event: any) => onChange(event.target.value)}
				value={value ? value : ''}
				placeholder={t('bio_placeholder')}
			/>
		</FormGroup>
	);
};
