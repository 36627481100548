import 'reflect-metadata';
import { AnyT, jsonMember, jsonObject } from 'typedjson';
import BlacklistModel from '../../../../../../../models/v2/blacklist/blaclist';
import LiveBlogTeamModel from '../../../../models/teams/live-blog-team.model';

@jsonObject()
export default class LiveBlogMatchModel {
	@jsonMember(AnyT)
	public id: string | number = '';

	@jsonMember(AnyT)
	public event_status: any;

	@jsonMember(String)
	public start_time: string = '';

	@jsonMember(String)
	public finished_at: string = '';

	@jsonMember(String)
	public started_at: string = '';

	@jsonMember(LiveBlogTeamModel)
	public home_team: LiveBlogTeamModel = new LiveBlogTeamModel();

	@jsonMember(LiveBlogTeamModel)
	public away_team: LiveBlogTeamModel = new LiveBlogTeamModel();

	@jsonMember(Number)
	public goal_home: number = 0;

	@jsonMember(Number)
	public goal_away: number = 0;

	@jsonMember(AnyT)
	public tournament_season_stage: any;

	@jsonMember(String)
	public type: string | null = null;

	@jsonMember(Number)
	public minutes: number | null = null;

	@jsonMember(String)
	public round: string | null = null;

	@jsonMember(String)
	public updated_at: string | null = null;

	@jsonMember(Boolean)
	public live_updates: boolean | null = null;

	@jsonMember(Boolean)
	public lineup_available: boolean | null = null;

	@jsonMember(BlacklistModel)
	public blacklist: BlacklistModel | null = null;

	@jsonMember(String)
	public slug: string = '';
}
