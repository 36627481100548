import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import TennisTournamentModel from '../../../../../../models/v2/tennis-tournament/tennis-tournament.model';
import ErrorHandler from '../../../partials/error/error-handler-component';
import { customOption } from '../../../partials/shared/custom-select-option';
import { tournamentsToOptions, tournamentToOption } from './tennis-tournament-select.helper';
import TennisRoundModel from '../../../../../../models/v2/round/tennis-round/tennis-round.model';
import { responseToModelTennisRound } from '../../../../../../models/v2/round/tennis-round/tennis-round-mapper';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';
import { useTranslation } from 'react-i18next';
import SportsHttpService from '../../../../../../services/rest/sports-http-service';
import { responseToModelTennisTournament } from '../../../../../../models/v2/tennis-tournament/tennis-tournament-mapper';
import CompetitionModel from '../../../../../../models/v2/competition/entity/competition.model';
import ResponseMatchModel from '../../../../../../models/v2/match/entity/response-match.model';

type Properties = {
	onTournamentSelect: (selection: TennisTournamentModel, availableRounds: TennisRoundModel[], competition?: CompetitionModel) => void;
	selectedTournament: TennisTournamentModel;
	isValid: boolean;
	isClearable?: boolean;
	isRequired?: boolean;
	language: string;
	sport: string;
	id: string;
	tournaments: TennisTournamentModel[];
	playerId?: string;
	isAthleteProgramme?: boolean;
};

export const TennisTournamentSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [tournamentOptions, setTournamentOptions] = useState<TennisTournamentModel[]>([]);
	const [tournamentRounds, setTournamentRounds] = useState<TennisRoundModel[]>([]);
	const [competition, setCompetition] = useState<CompetitionModel>();

	const [t] = useTranslation();
	const {
		tournaments,
		id,
		selectedTournament,
		onTournamentSelect,
		isAthleteProgramme,
		playerId,
		isClearable,
		isRequired,
		isValid,
		language,
		sport,
	} = props;

	useEffect(() => {
		if (playerId && playerId.length > 0 && isAthleteProgramme) {
			getTournaments(sport, playerId, language);
		} else {
			updateTournamentsOptionsState(tournaments);
		}
	}, [playerId, tournaments]);

	const updateTournamentsOptionsState = (tournaments: TennisTournamentModel[]) => {
		setTournamentOptions(tournaments);
	};

	const updateTournamentRoundsState = (rounds: TennisRoundModel[]) => {
		setTournamentRounds(rounds);
	};

	const updateCompetitionState = (competition: CompetitionModel) => {
		setCompetition(competition);
	};

	const getTournaments = (sport: string, playerId: string, lang: string) => {
		SportsHttpService.getTournamentsByPlayerId(sport, playerId, lang, '200')
			.then((response: any) => {
				return updateTournamentsOptionsState(
					response.data.data
						.filter((item: TennisTournamentModel) => item.competition)
						.map((item: TennisTournamentModel) => responseToModelTennisTournament(item)),
				);
			})
			.catch((e: any) => e);
	};

	const getCompetitionDetails = (
		sport: string,
		selectedTournament: TennisTournamentModel,
		competition: CompetitionModel,
		season: string,
		lang: string,
	) => {
		SportsHttpService.getMatchesByPlayerId(sport, selectedTournament.id, playerId ? playerId : '', lang)
			.then((response: any) => {
				const rounds: TennisRoundModel[] = [];
				response.data.matches.map((item: ResponseMatchModel) => {
					const round = responseToModelTennisRound(item.round);
					rounds.push(round);
					const filteredRounds = rounds.filter((obj, index) => rounds.findIndex((item) => item.id === obj.id) === index);
					updateTournamentRoundsState(filteredRounds);
					onTournamentSelect(selectedTournament, filteredRounds, competition);
				});
			})
			.catch((e: any) => e);
	};

	const onTournamentProgrammeSelect = (selection: TennisTournamentModel) => {
		if (selection && selection.id !== null) {
			updateCompetitionState(selection.competition);
			if (selection.competition && selection.competition.id) {
				getCompetitionDetails(sport, selection, selection.competition, selection.seasonYear, language);
			}
		} else {
			onTournamentSelect({} as TennisTournamentModel, []);
		}
	};

	const isSelected = selectedTournament.id && selectedTournament.id.length > 0;

	return (
		<Row data-qa={id}>
			<Col>
				<Label htmlFor={id}>{t('tournament')}</Label>
				{isRequired && <span className='text-danger'>*</span>}
				<Select
					id={id}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					className='w-100'
					placeholder={t('select')}
					formatOptionLabel={customOption}
					options={tournamentsToOptions(tournamentOptions, isAthleteProgramme)}
					value={selectedTournament && selectedTournament.id ? tournamentToOption(selectedTournament, isAthleteProgramme) : {}}
					noOptionsMessage={(inputValue) => inputValue && t('no_options')}
					isClearable={isClearable && isSelected}
					onChange={(selection: SelectMenuOptionType) => {
						if (isAthleteProgramme) {
							selection ? onTournamentProgrammeSelect(selection.data) : onTournamentSelect({} as TennisTournamentModel, []);
						} else {
							if (selection && selection.data !== null) {
								const rounds = selection.data.rounds.map((item: TennisRoundModel) => responseToModelTennisRound(item));
								onTournamentSelect(selection.data, rounds);
							} else {
								onTournamentSelect({} as TennisTournamentModel, []);
							}
						}
					}}
				/>
				{!isValid && !selectedTournament && <ErrorHandler t={t} errorMessage='field_is_required' />}
			</Col>
		</Row>
	);
};
