import TranslationGroupModel from '../../models/translation-groups/translation-group-model';
import { contentBuilders } from '../../views/Partials/BaseComponents/ListingComponent/subcomponents/Rows/helpers/RowsContainerHelper';
import Article from '../../models/article/Article';
import TranslationsModel from '../../models/translation-groups/translations/translations-model';
import LanguageCodeModel from '../../models/content-languages/language-code/language-code-model';
import { SearchEntityTypes } from '../../views/Partials/Blocky/blocks/content/content-block/helpers/content-blocks.helper';
import { store } from '../../store/store';
import Video from '../../models/video/Video';
import Gallery from '../../models/gallery/Gallery';
import Wiki from '../../models/wiki/Wiki';

export default class MultiLingualService {
	contentBuilders = {
		article: Article,
		video: Video,
		gallery: Gallery,
		wiki: Wiki,
	};

	setCorrectAcceptLanguageCode = (projectLanguage: string) => {
		if (window.location && window.location.hash.includes('/edit')) {
			if (window.location.hash.includes('/articles/')) return store.getState().tempArticle.articleEdit.language;
			if (window.location.hash.includes('/videos/')) return store.getState().tempVideo.videoEdit.language;
			if (window.location.hash.includes('/galleries/')) return store.getState().tempGallery.galleryEdit.language;
			if (window.location.hash.includes('/wiki-pages/')) return store.getState().tempWiki.wiki.language;
		}

		if (window.location && window.location.hash.includes('/create')) {
			if (window.location.hash.includes('/articles/')) return store.getState().tempArticle.article.language;
			if (window.location.hash.includes('/videos/')) return store.getState().tempVideo.video.language;
			if (window.location.hash.includes('/galleries/')) return store.getState().tempGallery.gallery.language;
			if (window.location.hash.includes('/wiki-pages/')) return store.getState().tempWiki.wikiEdit.language;
		}

		if (window.location && window.location.hash.includes('/smp/live-blogs/editorial-admin/')) {
			const state = store.getState();

			return (
				(state.liveBlogEditorial && state.liveBlogEditorial.configuration && state.liveBlogEditorial.configuration.language) || projectLanguage
			);
		}

		return projectLanguage;
	};

	checkRoutePath = () => {
		if (window.location.hash.includes('/articles')) return true;
		if (window.location.hash.includes('/videos')) return true;
		if (window.location.hash.includes('/galleries')) return true;
		if (window.location.hash.includes('/widgets-page')) return true;
		if (window.location.hash.includes('/smp/live-blogs/editorial-admin/')) return true;

		return false;
	};

	checkIfContentTypeIsValidForMultiLingual = (type: any) => {
		if (type === 'article' || type === 'video' || type === 'gallery') {
			return true;
		}

		return false;
	};

	checkIfProjectIsMultiLingual = (contentLanguages: any) => {
		if (contentLanguages && contentLanguages.multiple) {
			return true;
		} else {
			return false;
		}
	};

	checkIfContentCanBeTranslated = (content: any) => {
		if (content && content.translationGroup && content.translationGroup.masterContentId !== undefined) {
			return true;
		} else {
			return false;
		}
	};

	getMasterContentId = (content: any) => {
		return content && content.translationGroup && content.translationGroup.masterContentId;
	};

	checkIfContentIsTranslatedInGroup = (content: any, selectedLanguage: any) => {
		let hasTranslation = false;
		for (let i = 0; i < content.translationGroup.translations.length; i++) {
			if (content.translationGroup.translations[i].language === selectedLanguage) {
				hasTranslation = true;
				break;
			}
		}
		return hasTranslation;
	};

	getCorrectContentTranslation = (content: any, languageCode: any, contentType: string) => {
		let editContentPath = '';

		for (let i = 0; i < content.translationGroup.translations.length; i++) {
			if (content.translationGroup.translations[i].language === languageCode) {
				editContentPath = `/smp/${contentType}/edit/${content.translationGroup.translations[i].id}`;
				break;
			}
		}
		return editContentPath;
	};

	extractAvailableLanguages = (contentLanguages: any) => {
		let availableLanguages: any[] = [];

		if (this.checkIfProjectIsMultiLingual(contentLanguages)) {
			contentLanguages.all.map((language: any) => {
				availableLanguages.push(language);
			});
		}
		return availableLanguages;
	};

	remapContentTranslationToTempCreate = (
		content: any,
		contentType: string,
		languageCode: string,
		translationGroup: TranslationGroupModel,
		t: any,
	) => {
		return contentBuilders[contentType]
			.builder(content)
			.withId('temp-copy-id')
			.withTitle(`[${t('copy_content')}] ${content.title}`)
			.withLanguage(languageCode)
			.withTranslationGroup(translationGroup)
			.build();
	};

	remmapTempContentTranslationLang = (content: any) => {
		return content
			? TranslationsModel.builder().withId(content.id).withLanguage(content.language).build()
			: TranslationsModel.builder().build();
	};

	extractMissingTranslations = (contentTranslations: any[], projectTranslations: any[], content: any) => {
		const missingTranslations = projectTranslations.filter((language: LanguageCodeModel) => {
			const existingTranslation =
				contentTranslations && contentTranslations.find((translation: TranslationsModel) => translation.language === language.languageCode);

			if (existingTranslation) {
				return false;
			}

			return true;
		});

		return missingTranslations;
	};

	extractTranslations = (contentTranslations: any[], projectTranslations: any[]) => {
		const translations =
			projectTranslations &&
			projectTranslations.filter((language: LanguageCodeModel) => {
				const existingTranslation =
					contentTranslations && contentTranslations.find((translation: TranslationsModel) => translation.language === language.languageCode);

				if (existingTranslation) {
					return true;
				}

				return false;
			});

		return translations;
	};

	extractContentLanguageCode = (contentTranslations: TranslationsModel[], contentId: string) => {
		if (contentTranslations && contentTranslations.length > 0) {
			const contentLanguageCode = contentTranslations.filter((translation: TranslationsModel) => translation.id === contentId);

			return contentLanguageCode && contentLanguageCode.length > 0 ? contentLanguageCode[0] : TranslationsModel.builder().build();
		}

		return TranslationsModel.builder().build();
	};

	constructContentEntityMultiLingualSearchUrl = (search: string, entityType: string, languageCode: any) => {
		const searchVersion = entityType !== SearchEntityTypes.polls ? '/v2/' : '/';

		return `${searchVersion}${SearchEntityTypes[entityType]}/search?language=${languageCode}&page=1&query=${search}`;
	};

	getProjectLanguage = (): string => {
		const project = store.getState().project.currentProject;
		return this.checkIfProjectIsMultiLingual(project.languages) ? project.languages.defaultLanguageCode.languageCode : project.language;
	};
}
