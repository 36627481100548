import React from 'react';
import BlockModel from '../../../../models/block.model';
import TournamentSelectInstat from '../partials/tournament-select/tournament-select.component';
import TournamentSelectModel from '../partials/tournament-select/tournament-select.model';
import SeasonSelect from '../partials/seasons-in-tournament-select/seasons-in-tournament-select.component';
import TeamSelect from '../partials/teams-in-season-select/teams-in-season-select.component';
import { Col, Label, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import _ from 'lodash';
import InstatHttpService from '../../../../../../../services/rest/instat/InstatHttpService';
import PlayerSelect from '../partials/players-int-team-select/players-in-team-select.component';
import ErrorHandler from '../../../../partials/error/error-handler-component';

type Properties = {
	t: any;
	block: BlockModel;
};

export default class InstatPlayerPerformanceSeasonBlockEdit extends React.Component<any, any> {
	private httpInstat = new InstatHttpService();

	constructor(props: Properties) {
		super(props);
		props.block.data.preview = props.block.data.preview ? props.block.data.preview : {};

		const { selectedTournamentTeam, selectedSeasonTeam, selectedTeam, selectedPlayer } = props.block.data.preview;
		const { player } = props.block.data;

		this.state = {
			selectedTournamentTeam: selectedTournamentTeam ? selectedTournamentTeam : null,
			selectedSeasonTeam: selectedSeasonTeam,
			selectedTeam: selectedTeam,
			selectedPlayer: selectedPlayer,
			stats: player ? player.stats : null,
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onSave = (index: number) => {
		const block = Object.assign({}, this.props.block);
		const validation = new BlockValidation();
		const data = {
			player: { info: this.state.selectedPlayer, stats: this.state.stats },
		};

		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });

		if (isValid) {
			block.data = data;
			block.data.preview = {
				selectedTournamentTeam: this.state.selectedTournamentTeam,
				selectedSeasonTeam: this.state.selectedSeasonTeam,
				selectedTeam: this.state.selectedTeam,
				selectedPlayer: this.state.selectedPlayer,
			};
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	onTournamentChange = (tournament: any) => {
		this.setState((state: any) => {
			return {
				...state,
				selectedTournamentTeam: tournament,
				selectedSeasonTeam: null,
				selectedTeam: null,
				selectedPlayer: null,
				stats: [],
				isValid: true,
			};
		});
	};

	onSeasonChange = (season: any) => {
		this.setState((state: any) => {
			return { ...state, selectedSeasonTeam: season, selectedTeam: null, selectedPlayer: null, stats: [], isValid: true };
		});
	};

	onTeamChange = (team: any) => {
		this.setState((state: any) => {
			return { ...state, selectedTeam: team, selectedPlayer: null, stats: [], isValid: true };
		});
	};

	onPlayerChange = (player: any) => {
		this.setState(
			(state: any) => {
				return { ...state, selectedPlayer: player, stats: [], isValid: true };
			},
			() => {
				const { selectedTournamentTeam, selectedSeasonTeam, selectedPlayer } = this.state;
				if (selectedPlayer) {
					this.requestPlayerAggStats(selectedTournamentTeam.id, selectedSeasonTeam.id, selectedPlayer.id);
				}
			},
		);
	};

	private requestPlayerAggStats = (tournamentId: string, seasonId: string, playerId: string) => {
		this.httpInstat.getPlayerAggStats(tournamentId, seasonId, playerId).then((data) => {
			this.setState({ stats: data });
		});
	};

	private isPlayerDatAvailable = (stats: any) => {
		if (stats && stats.length > 0) {
			return <div>Data loaded</div>;
		} else if (stats && stats.length < 1) {
			return <div>No data available</div>;
		} else {
			return null;
		}
	};

	render() {
		const { t } = this.props;
		const { selectedTournamentTeam, selectedSeasonTeam, selectedTeam, selectedPlayer, stats, isValid } = this.state;

		return (
			<div>
				<Row className='mb-3'>
					<Col xs={12} md={6}>
						<Label>{t('tournament')}</Label>
						<TournamentSelectInstat
							value={selectedTournamentTeam}
							onSelect={(selection: TournamentSelectModel | null) => this.onTournamentChange(selection)}
						/>
						<div className='mt-1'>
							{!isValid && selectedTournamentTeam === null && <ErrorHandler t={this.props.t} errorMessage='field_is_required' />}
						</div>
					</Col>
					<Col xs={12} md={6}>
						{selectedTournamentTeam ? (
							<>
								<Label>{t('season')}</Label>
								<SeasonSelect
									value={selectedSeasonTeam}
									tournamentId={selectedTournamentTeam.id}
									onSelect={(season) => this.onSeasonChange(season)}
								/>
								<div className='mt-1'>
									{!isValid && selectedTournamentTeam !== null && selectedSeasonTeam === null && (
										<ErrorHandler t={this.props.t} errorMessage='field_is_required' />
									)}
								</div>
							</>
						) : null}
					</Col>
				</Row>
				<Row className='mb-3'>
					<Col xs={12} md={6}>
						{selectedSeasonTeam ? (
							<>
								<Label>{t('team')}</Label>
								<TeamSelect
									value={selectedTeam}
									tournamentId={selectedTournamentTeam.id}
									seasonId={selectedSeasonTeam.id}
									onSelect={(team) => this.onTeamChange(team)}
								/>
								<div className='mt-1'>
									{!isValid && selectedSeasonTeam !== null && selectedTeam === null && (
										<ErrorHandler t={this.props.t} errorMessage='field_is_required' />
									)}
								</div>
							</>
						) : null}
					</Col>
					<Col xs={12} md={6}>
						{selectedTeam ? (
							<>
								<Label>{t('player')}</Label>
								<PlayerSelect value={selectedPlayer} teamId={selectedTeam.id} onSelect={(player) => this.onPlayerChange(player)} />
								<div className='mt-1'>
									{!isValid && selectedTeam !== null && selectedPlayer === null && (
										<ErrorHandler t={this.props.t} errorMessage='field_is_required' />
									)}
								</div>
							</>
						) : null}
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={12} className='d-flex flex-column justify-content-center align-items-center pt-4 mb-1'>
						{this.isPlayerDatAvailable(stats)}
					</Col>
				</Row>
			</div>
		);
	}
}
