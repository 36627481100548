import BlockModel from '../../../models/block.model';
import { ViewTypes } from '../../../constants/general.constants';
import React from 'react';
import BlockUpdateController from '../../block-update-controller.component';
import { compose } from 'redux';
import WidgetDoubleChanceEdit from './widget-double-chance-edit.component';
import { WidgetDoubleChanceView } from './widget-double-chance-view.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
	lang: string;
	contentData: any;
	bookmakers: [];
};

const WidgetDoubleChance: React.FunctionComponent<Properties> = (props) => {
	if (props.view === ViewTypes.edit) {
		return <WidgetDoubleChanceEdit contentData={props.contentData} block={props.block} t={props.t} />;
	}

	if (props.view === ViewTypes.normal) {
		return (
			<WidgetDoubleChanceView
				t={props.t}
				widgetDoubleChance={props.block.data.widget}
				bookmakers={props.block.data.widget && props.block.data.widget.odds ? props.block.data.widget.odds : []}
			/>
		);
	}

	return null;
};

export default compose(BlockUpdateController)(WidgetDoubleChance);
