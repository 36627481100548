import React from 'react';
import { blockManagementService, blockWrapperService } from '../../../subcomponents/blocky.component';
import BlockValidation from '../../../helpers/block-validation.helper';
import TeamModel from '../../../partials/team-select/models/team.model';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import TeamSelect from '../../../partials/team-select/team-select.component';
import MatchesContainer from '../../../partials/matches/matches-container';
import MatchModel from '../../../partials/match-select/models/match.model';
import GenericSelect from '../../../partials/generic-select/generic-select-component';
import { getDefaultType } from '../../content/embed-block/helpers/embed-block-edit.helper';
import { Widget1x2Market } from '../../../subcomponents/helpers/fans-united-block.constants';
import FansUnitedWidgetModel from './models/fans-united-widget.model';
import { Properties, State } from './properties/fans-united-widget-edit.properties';
import ErrorHandler from '../../../partials/error/error-handler-component';
import { remapMatchFromOption } from '../match/helpers/match-widget.helper';
import { featuresService } from '../../../../../../App';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import BookmakerSelect from '../../../partials/bookmaker-select/bookmaker-select.component';

export default class Widget1x2Edit extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel:
				props.block && props.block.data && props.block.data.widget && props.block.data.widget.match && props.block.data.widget.match.id
					? FansUnitedWidgetModel.builder(props.block.data.widget).build()
					: FansUnitedWidgetModel.builder()
							.withMatch({} as MatchModel)
							.withTeams([])
							.withMarket(
								props.block.data.widget && props.block.data.widget.market
									? props.block.data.widget.market
									: getDefaultType(Widget1x2Market, props.t),
							)
							.build(),
			bookmakers:
				props.block && props.block.data && props.block.data.widget && props.block.data.widget.odds ? props.block.data.widget.odds : [],
			isValid: true,
		};
	}
	componentDidMount() {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any) {
		if (this.state.widgetModel.displayOdds !== prevState.widgetModel.displayOdds && !this.state.widgetModel.displayOdds) {
			this.setState({ ...this.state, bookmakers: [] });
		}
	}

	onSave = (index: number) => {
		const data = { widget: this.state.widgetModel, odds: this.state.bookmakers };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data);
		const bookmakerSelectionEnabled = featuresService && featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		// @ts-ignore
		this.setState({ ...this.state, isValid });

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.widget = {
				match: this.state.widgetModel.match,
				market: this.state.widgetModel.market,
				teams: this.state.widgetModel.teams,
				displayOdds: this.state.widgetModel.displayOdds,
				odds: bookmakerSelectionEnabled ? this.state.bookmakers : null,
			};
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	updateWidgetModelState = (model: FansUnitedWidgetModel) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onTeamChange = (teams: TeamModel[]) => {
		this.updateWidgetModelState(
			FansUnitedWidgetModel.builder(this.state.widgetModel)
				.withTeams(teams)
				.withMatch({} as MatchModel)
				.withDisplayOdds(false)
				.build(),
		);
	};

	onMatchChange = (match: MatchModel) => {
		const widgetModel = FansUnitedWidgetModel.builder(this.state.widgetModel)
			.withMatch(remapMatchFromOption(match))
			.withDisplayOdds(true)
			.build();
		this.setState({ ...this.state, isValid: true, widgetModel });
	};

	onMarketChange = (market: any) => {
		const widgetModel = FansUnitedWidgetModel.builder(this.state.widgetModel).withMarket(market).build();
		this.setState({ ...this.state, isValid: true, widgetModel });
	};

	onDisplayOddsChange = (e: any) => {
		this.updateWidgetModelState(FansUnitedWidgetModel.builder(this.state.widgetModel).withDisplayOdds(e.target.checked).build());
	};

	onBookmakerChange = (bookmakers: []) => {
		this.setState({ ...this.state, bookmakers });
	};

	render() {
		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const { t } = this.props;
		const { widgetModel, isValid, bookmakers } = this.state;
		return (
			<>
				<Row>
					<Col>
						<FormGroup>
							<TeamSelect
								inputId='widget-1x2-team-select'
								isMulti={true}
								t={t}
								value={widgetModel.teams ? widgetModel.teams : []}
								onSelect={(team: TeamModel[]) => this.onTeamChange(team)}
								isValid={isValid}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<MatchesContainer
								t={t}
								teamIds={
									widgetModel && widgetModel.teams && widgetModel.teams.length > 0
										? [widgetModel.teams.filter((team) => team).map((team: any) => team.id)]
										: []
								}
								isMulti={false}
								hideMatchStatusTabs={true}
								maxNotStartedMatches={20}
								maxFinishedMatches={20}
								maxInterruptedMatches={20}
								teamNames={
									widgetModel && widgetModel.teams && widgetModel.teams.length > 0
										? widgetModel.teams.filter((team) => team).map((team: TeamModel) => team.name)
										: ['']
								}
								selectedMatchId={widgetModel && widgetModel.match && widgetModel.match.id ? widgetModel.match.id : ''}
								onSelect={this.onMatchChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<Label>{t('select_market')}</Label>
							<GenericSelect
								t={t}
								isMulti={false}
								options={Widget1x2Market}
								value={widgetModel.market ? widgetModel.market : getDefaultType(Widget1x2Market, t)}
								onGenericOptionSelect={this.onMarketChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				{widgetModel && widgetModel.match && bookmakerSelectionEnabled && (
					<Row className='mb-2'>
						<Col>
							<FormGroup key={`display-odds-markets`} check className='radio'>
								<Input
									key={`display-odds-markets-input`}
									className='form-check-input'
									type='checkbox'
									id={`display-odds-markets-checkbox-${this.props.block.id}`}
									name='radios'
									onChange={this.onDisplayOddsChange}
									checked={widgetModel.displayOdds}
								/>
								<Label
									key={`display-odds-markets-label`}
									check
									className='form-check-label'
									for={`display-odds-markets-checkbox-${this.props.block.id}`}
								>
									{this.props.t('display_odds')}
								</Label>
							</FormGroup>
						</Col>
					</Row>
				)}
				{bookmakerSelectionEnabled && widgetModel.displayOdds && (
					<BookmakerSelect
						eventId={widgetModel.match ? widgetModel.match.id : null}
						isMulti
						showAllBookmakers={true}
						bookmakerSelectionEnabled={bookmakerSelectionEnabled}
						onSelect={this.onBookmakerChange}
						value={bookmakers ? bookmakers : null}
						valueList={bookmakers ? bookmakers : null}
						t={t}
						isValid={isValid}
						forFansUnited={true}
					/>
				)}
				{!isValid && widgetModel.match && !widgetModel.match.id && (
					<div className='mb-3'>
						<ErrorHandler t={t} errorMessage='no_matches_selected' />
					</div>
				)}
			</>
		);
	}
}
