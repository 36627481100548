import { TypedJSON } from 'typedjson';
import { convertIdToString } from '../../../../helpers/utility.helper';
import LiveBlogMatchModel from './live-blog-match-model';

export const modelMatchToResponseModel = (response: any): LiveBlogMatchModel => {
	const serializer = new TypedJSON(LiveBlogMatchModel);
	const result = serializer.parse(response);

	if (result) {
		result.id = convertIdToString(result.id);
		result.home_team.id = convertIdToString(result.home_team.id);
		result.away_team.id = convertIdToString(result.away_team.id);

		return result;
	}

	return new LiveBlogMatchModel();
};
