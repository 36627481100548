import { Button, Col, FormGroup, Row } from 'reactstrap';
import React from 'react';
import ErrorHandler from '../../../partials/error/error-handler-component';
import BlockModel from '../../../models/block.model';
import YoutubeVideoSelect from '../../../partials/youtube-video-select/youtube-video-select.component';
import YoutubeVideoModel from '../../../../../../models/youtube-sports/youtube-video/youtube-video.model';
import YoutubeSportsModel from '../../../../../../models/youtube-sports/youtube-sports.model';
import YoutubeVideoContainer from './video-rows/youtube-video-container';

export type Properties = {
	t: any;
	onSelect: (video: YoutubeVideoModel) => void;
	updateSearchText: (search: string) => any;
	block?: BlockModel;
	selectedYoutubeVideo: YoutubeVideoModel;
	isValid?: boolean;
	inBlocky: boolean;
	linkedTags: any;
};

export type State = {
	youtubeVideos: YoutubeSportsModel;
	toggleLoadingState: boolean;
	page: number;
	shouldRequest: boolean;
	pageToken: string;
};

class YoutubeSportsContainer extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			youtubeVideos: YoutubeSportsModel.builder().build(),
			toggleLoadingState: false,
			page: props.page,
			shouldRequest: false,
			pageToken: '',
		};
	}

	onVideosChange = (youtubeVideos: YoutubeSportsModel, title: string) => {
		this.setState({ ...this.state, youtubeVideos });
		this.props.updateSearchText(title);
	};

	toggleLoadingState = (loading: boolean) => {
		this.setState({ ...this.state, toggleLoadingState: loading });
	};

	onPageUp = () => this.setState({ ...this.state, pageToken: this.state.youtubeVideos.nextPageToken });

	onPageDown = () => this.setState({ ...this.state, pageToken: this.state.youtubeVideos.prevPageToken });

	render() {
		const { t, onSelect, selectedYoutubeVideo, linkedTags } = this.props;
		const { youtubeVideos, toggleLoadingState, pageToken } = this.state;

		return (
			<div className={'mb-2'}>
				<div>
					<Row>
						<Col>
							<FormGroup>
								<YoutubeVideoSelect
									t={t}
									onSelect={this.onVideosChange}
									onSelectedYoutubeVideoChange={onSelect}
									toggleLoadingState={this.toggleLoadingState}
									searchText={this.props.block && this.props.block.data && this.props.block.data.preview && this.props.block.data.preview.title}
									linkedTags={linkedTags}
									pageToken={pageToken}
								/>
							</FormGroup>
						</Col>
					</Row>
					<div className={`${toggleLoadingState ? 'loading-overlay rounded opacity-06' : ''} py-2`}>
						{youtubeVideos && youtubeVideos.videoList && youtubeVideos.videoList.length > 0 ? (
							<div className='pb-2'>
								<YoutubeVideoContainer
									t={t}
									onSelect={onSelect}
									videos={youtubeVideos.videoList}
									toggleLoadingState={toggleLoadingState}
									selectedVideo={selectedYoutubeVideo}
								/>
							</div>
						) : (
							<div className='text-center'>{t('no_data')}</div>
						)}
						{youtubeVideos && youtubeVideos.videoList && youtubeVideos.videoList.length > 0 && (
							<div className='m-2 d-flex flex-row justify-content-end'>
								<Button
									id='youtube-prev-page'
									size='sm'
									outline
									className='mr-2 d-flex align-items-center'
									color='primary'
									disabled={!youtubeVideos.prevPageToken || youtubeVideos.prevPageToken === ''}
									onClick={this.onPageDown}
								>
									<i className='fa fa-2x fa-angle-left px-1' />
									{t('previous')}
								</Button>
								<Button
									id='youtube-next-page'
									size='sm'
									className='d-flex align-items-center'
									outline
									color='primary'
									disabled={!youtubeVideos.nextPageToken || youtubeVideos.nextPageToken === ''}
									onClick={this.onPageUp}
								>
									{t('next')}
									<i className='fa fa-2x fa-angle-right px-1' />
								</Button>
							</div>
						)}
					</div>
					{this.props.inBlocky && (
						<div className='p-1'>
							{!this.props.isValid && selectedYoutubeVideo && selectedYoutubeVideo.id === undefined && (
								<ErrorHandler t={this.props.t} errorMessage='no_selected_video' />
							)}
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default YoutubeSportsContainer;
