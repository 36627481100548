import React from 'react';
import { Col, Row } from 'reactstrap';
import StandingsWidgetV2Model from '../models/standings-widget-v2.model';
import { createGroups } from '../../../partials/helpers/group-stage-select/group-stage-select.helper';
import { generateRefreshTimeOptionViewText } from '../../../partials/refresh-time-input/helper';

type Properties = {
	preview: StandingsWidgetV2Model;
	t: any;
};

export const FootballStandingsView: React.FunctionComponent<Properties> = ({ preview, t }) => {
	if (preview && preview.tournament && preview.tournament.id) {
		return (
			<>
				<Row className='px-4' data-qa='standings-view-component'>
					<Col className='text-left'>
						<Row className='mb-2'>
							<Col>
								<div>
									<strong>{t('tournament')}:</strong>
									<img className='ml-1 mr-1' width='35px' src={preview.tournament.urlLogo} alt={preview.tournament.name} />
									{preview.tournament.name} {preview.season && preview.season.name && `- ${preview.season.name}`}
									{`${preview.stage && preview.stage.name ? `(${preview.stage.name})` : ''}`}
								</div>
							</Col>
						</Row>
						<Row className='mb-2'>
							<Col>
								{preview.tournament && preview.season && preview.stage && preview.group && preview.group.length > 0 && (
									<p>
										<strong>{t('selected_groups')}:</strong> {createGroups(preview.group)}
									</p>
								)}
							</Col>
						</Row>
					</Col>
				</Row>
				{generateRefreshTimeOptionViewText(preview.refreshTime)}
			</>
		);
	}

	return null;
};
