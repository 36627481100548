import { Editor } from '@tiptap/core';
import { EditorView } from '@tiptap/pm/view';
import { Content, EditorContent, useEditor } from '@tiptap/react';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { NodeClassNames } from '../../../constants/NodeClassNames';
import { getDefaultExtensions } from '../../../helpers/collaborative-editor-extensions.helper';
import createCustomNodes from '../../../helpers/create-custom-nodes';
import getDataAttributes from '../../../helpers/get-data-attributes';
import { OpenModalCallback } from '../../main-components/live-blog-editorial-admin.component';
import ContentBlocksSelection from '../content-blocks-selection/content-blocks-selection';
import CollaborativeEditorToolbar, { EditorToolbarProps, ToolbarAction } from './collaborative-editor-toolbar';
import { DATA_QA_ATTRIBUTES } from './data-qa.attributes';

import '../../../style/collaborative-editor.scss';

const COLLABORATIVE_EDITOR_CONTENT_UPDATE_TIMEOUT = 10;

declare module '@tiptap/core' {
	interface Commands<ReturnType> {
		smp_widget: {
			/**
			 * Inserts a widget.
			 */
			insertAdvancedContent: (attributes: WidgetDetails['attrs']) => ReturnType;
		};
	}
}

export type WidgetDetails = {
	subDocumentId?: string;
	attrs: Record<Attr['name'], Attr['value']>;
};

export interface CustomNode {
	name: string;
	content?: string;
	group?: string;
	atom?: boolean;
	attributes: Record<string, { default: any }>;
	parseHTMLDetails: {
		tag: string;
		getAttrs: (node: string | HTMLElement) => false | null;
	}[];
	renderDetails: { tag: string; additionalAttributes?: Record<string, any> };
	component: FC;
}

export enum WidgetActionType {
	ADD = 'add',
	EDIT = 'edit',
}

export enum SpecialCommands {
	TRANSFORM_TO = 'transform-to',
}

export const PASTE_OPTION = 'tiptap-editor-paste-option';

export type CollaborativeEditorProps = {
	content?: Content;
	lastUpdatedWidget?: {
		actionType: WidgetActionType;
		widgetDetails: WidgetDetails;
	};
	onCreate?: (editor: Editor) => void;
	onUpdate?: (editor: Editor) => void;
	onWidgetBeginEdit?: (widgetDetails: WidgetDetails) => void;
	subDocumentId?: string;
	customNodes?: CustomNode[];
	resetToken?: {} | null;
	actions?: ToolbarAction[];
	onKeyDown?: (view: EditorView, event: KeyboardEvent) => void | boolean;
	onSpecialCommand?: (command: SpecialCommands, value: any) => void;
	enableCode?: boolean;
	showToolbarOnFocus?: boolean;
	editorClassName?: string;
} & (
	| {
			editable: true;
			openModalCallback: OpenModalCallback;
	  }
	| { editable: false; openModalCallback: undefined }
);

const stripFormatting = (html: string) => {
	const transformedHtml = html
		.replace(/<br( )?\/>/g, ' ')
		.replace(/\r\n|\n|\r/gm, ' ')
		.replace(/<xml>[\s\S]+<\/xml>/g, '') // Strips additional info (Word desktop app)
		.replace(/<style.+?<\/style>/g, ''); // Strips out style tags (Word desktop app and macOS Notes app)

	return transformedHtml.replace(/<\/?[^>]+(>|$)/g, '');
};

const CollaborativeEditor: FC<CollaborativeEditorProps> = ({
	content,
	editable,
	lastUpdatedWidget,
	onCreate,
	onUpdate,
	onWidgetBeginEdit,
	subDocumentId,
	customNodes = [],
	resetToken,
	openModalCallback,
	actions,
	onKeyDown,
	onSpecialCommand,
	enableCode,
	showToolbarOnFocus = false,
	editorClassName = '',
}) => {
	const [linkClicked, setLinkClicked] = useState<EditorToolbarProps['linkClicked']>(null);
	const [isToolbarShown, setIsToolbarShown] = useState(!showToolbarOnFocus);
	const componentInitialized = useRef(false);
	const editorCreated = useRef(false);
	const editorWrapper = useRef<HTMLDivElement>(null);
	const blurTimeout = useRef<NodeJS.Timeout | null>(null);

	const onLinkClicked = (element: HTMLAnchorElement) => {
		setLinkClicked({
			doNotAutoLink: element.classList.contains('autolink-disabled'),
			href: element.href,
			openInNewTab: element.target === '_blank',
		});
	};

	const onWidgetEditClicked = (element: Element) => {
		if (!onWidgetBeginEdit) {
			return;
		}

		onWidgetBeginEdit({
			subDocumentId,
			attrs: getDataAttributes(element),
		});
	};

	const customExtensions = useMemo(() => createCustomNodes(customNodes), [customNodes]);

	const editor = useEditor({
		onCreate: ({ editor }) => {
			editorCreated.current = true;

			if (onCreate) {
				onCreate(editor);
			}
		},
		onUpdate: ({ editor }) => {
			if (onUpdate) {
				onUpdate(editor);
			}
		},
		onFocus: () => {
			showToolbarOnFocus && setIsToolbarShown(true);
		},
		editorProps: {
			attributes: {
				id: subDocumentId || 'editor',
			},
			transformPastedHTML: (html, view) => {
				const pasteOption = (localStorage.getItem(PASTE_OPTION) || ToolbarAction.PASTE_WORD_FORMATTING) as
					| ToolbarAction.PASTE_WORD_FORMATTING
					| ToolbarAction.PASTE_ALL_FORMATTING
					| ToolbarAction.PASTE_NO_FORMATTING;

				if (
					pasteOption === ToolbarAction.PASTE_ALL_FORMATTING ||
					(view.dom.parentElement && view.dom.parentElement.classList.contains('list')) // Do not transform pasted HTML if it's inside a list blocky
				) {
					return html;
				}

				if (
					pasteOption === ToolbarAction.PASTE_WORD_FORMATTING &&
					(html.includes('_MSFontService') || html.includes('urn:schemas-microsoft-com:office:word'))
				) {
					// Word web app in OneDrive - _MSFontService
					// Word desktop app - urn:schemas-microsoft-com:office:word
					return html;
				}

				return stripFormatting(html);
			},
			handleClick: (_view, _pos, event) => {
				const targetElement = event.target;

				if (!editable || !targetElement || !(targetElement instanceof HTMLElement || targetElement instanceof SVGElement)) {
					return;
				}

				const link = targetElement.closest(`.${NodeClassNames.LINK}`);

				if (link) {
					onLinkClicked(targetElement as HTMLAnchorElement);
				} else if (!(targetElement instanceof HTMLAnchorElement)) {
					const widgetPreviewElement = targetElement.closest('.editor-widget-preview');

					if (widgetPreviewElement) {
						onWidgetEditClicked(widgetPreviewElement);
					}
				}
			},
			handleKeyDown(view, event) {
				if (event.code === 'KeyK' && (event.ctrlKey || event.metaKey)) {
					const { state } = view;
					const marks = state.storedMarks || state.selection.$head.marks();

					if (marks && marks.length > 0 && marks[0].type.name === 'link') {
						setLinkClicked({
							doNotAutoLink: marks[0].attrs.class === 'autolink-disabled',
							href: marks[0].attrs.href,
							openInNewTab: marks[0].attrs.target === '_blank',
						});
					} else {
						setLinkClicked({
							doNotAutoLink: false,
							href: '',
							openInNewTab: false,
						});
					}

					return;
				}

				if (onKeyDown) {
					return onKeyDown(view, event);
				}
			},
		},
		extensions: [...customExtensions, ...getDefaultExtensions(enableCode)],
		content,
		editable,
	});

	useEffect(() => {
		if (lastUpdatedWidget && lastUpdatedWidget.widgetDetails.subDocumentId === subDocumentId && editor) {
			const timeout = setTimeout(() => {
				if (lastUpdatedWidget.actionType === WidgetActionType.ADD) {
					editor
						.chain()
						.focus()
						.insertAdvancedContent({
							...lastUpdatedWidget.widgetDetails.attrs,
						})
						.createParagraphNear()
						.run();
				} else if (lastUpdatedWidget.actionType === WidgetActionType.EDIT) {
					editor
						.chain()
						.focus()
						.updateAttributes('smp_widget', {
							...lastUpdatedWidget.widgetDetails.attrs,
						})
						.run();
				}
			}, COLLABORATIVE_EDITOR_CONTENT_UPDATE_TIMEOUT);

			return () => clearTimeout(timeout);
		}
	}, [lastUpdatedWidget, editor, subDocumentId]);

	useEffect(() => {
		if (editor && resetToken !== undefined && resetToken !== null) {
			const timeout = setTimeout(() => editor.commands.clearContent(), COLLABORATIVE_EDITOR_CONTENT_UPDATE_TIMEOUT); // Timeout is necessary in order to avoid a Tiptap bug: https://github.com/ueberdosis/tiptap/issues/3764

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [editor, resetToken]);

	useEffect(() => {
		if (!componentInitialized.current) {
			componentInitialized.current = true;
			return;
		}

		if (editor && editorCreated.current) {
			let timeout: NodeJS.Timeout;

			if (content) {
				timeout = setTimeout(() => editor.commands.setContent(content), COLLABORATIVE_EDITOR_CONTENT_UPDATE_TIMEOUT); // Timeout is necessary in order to avoid a Tiptap bug: https://github.com/ueberdosis/tiptap/issues/3764
			} else {
				timeout = setTimeout(() => editor.commands.clearContent(), COLLABORATIVE_EDITOR_CONTENT_UPDATE_TIMEOUT); // Timeout is necessary in order to avoid a Tiptap bug: https://github.com/ueberdosis/tiptap/issues/3764
			}

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [content, editor]);

	useEffect(() => {
		if (editor) {
			return () => editor.destroy();
		}
	}, [editor]);

	useEffect(() => {
		return () => {
			if (blurTimeout.current) {
				clearTimeout(blurTimeout.current);
			}
		};
	}, []);

	if (!editor) {
		return null;
	}

	return (
		<div
			className='collaborative-editor-wrapper'
			ref={editorWrapper}
			tabIndex={0}
			onBlur={() => {
				blurTimeout.current = setTimeout(() => {
					if (
						editorWrapper.current &&
						document.activeElement &&
						(editorWrapper.current.contains(document.activeElement) || document.activeElement.closest('.collaborative-editor-toolbar-popover'))
					) {
						return;
					}

					showToolbarOnFocus && setIsToolbarShown(false);
				}, 100);
			}}
			data-qa={DATA_QA_ATTRIBUTES.WRAPPER}
		>
			{editable && isToolbarShown && (
				<CollaborativeEditorToolbar
					editor={editor}
					linkClicked={linkClicked}
					actions={actions}
					onSpecialCommand={onSpecialCommand}
					showOnFocus={showToolbarOnFocus}
				></CollaborativeEditorToolbar>
			)}

			<EditorContent editor={editor} data-qa={DATA_QA_ATTRIBUTES.EDITOR} className={editorClassName} />

			{editable && (!actions || actions.includes(ToolbarAction.CONTENT_BLOCKS)) && (
				<ContentBlocksSelection openModalCallback={openModalCallback} subDocumentId={subDocumentId} editor={editor} />
			)}
		</div>
	);
};

export default CollaborativeEditor;
