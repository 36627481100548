import BlockModel from '../../../models/block.model';
import { ViewTypes } from '../../../constants/general.constants';
import React from 'react';
import BlockUpdateController from '../../block-update-controller.component';
import { compose } from 'redux';
import { WidgetTopXManagerView } from './widget-topx-manager-view.component';
import WidgetTopXManagerEditComponent from './widget-topx-manager-edit.component';

type Properties = {
	block: BlockModel;
	view: ViewTypes;
	t: any;
};

const WidgetTopXManager: React.FunctionComponent<Properties> = (props) => {
	if (props.view === ViewTypes.edit) {
		return <WidgetTopXManagerEditComponent block={props.block} t={props.t} />;
	}

	if (props.view === ViewTypes.normal) {
		return <WidgetTopXManagerView widgetTopXManager={props.block.data.widget} t={props.t} />;
	}

	return null;
};

export default compose(BlockUpdateController)(WidgetTopXManager);
