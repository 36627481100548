import React, { FunctionComponent } from 'react';
import { Col, Label, Row } from 'reactstrap';
import { assetsPlaceholder } from '../../../../../../constants/assetsPlaceholder';
import LiveBlogMatchModel from './models/live-blog-match-model';

type Properties = {
	event: LiveBlogMatchModel;
	handleDeleteRow: Function;
	t: any;
	index: number;
};

export const EventInfoCard: FunctionComponent<Properties> = ({ event, t, handleDeleteRow, index }) => {
	const placeholder = assetsPlaceholder.team;
	return event.home_team && event.away_team ? (
		<div className='card-body'>
			<Row>
				<Col>
					{event.id && (
						<div id={`${event.id}-${index + 1}`}>
							<Row className='mb-1'>
								<Label className='mb-1'>
									<strong>{t('event')}</strong>
								</Label>
							</Row>
							{event.home_team.urlLogo && (
								<img
									width='20'
									height='20'
									className='team-image'
									src={event.home_team.urlLogo ? event.home_team.urlLogo : placeholder}
									onError={(i: any) => (i.target.src = assetsPlaceholder.team)}
									alt={event.home_team.name ? event.home_team.name : ''}
								/>
							)}
							<span className='ml-0'>
								<strong>{event.home_team.name}</strong>
							</span>
							{'  :  '}
							{event.away_team.urlLogo && (
								<img
									width='20'
									height='20'
									className='team-image'
									src={event.away_team.urlLogo ? event.away_team.urlLogo : placeholder}
									onError={(i: any) => (i.target.src = assetsPlaceholder.team)}
									alt={event.away_team.name ? event.away_team.name : ''}
								/>
							)}
							<span className='ml-0'>
								<strong>{event.away_team.name}</strong>
							</span>
							<Row className='mt-2'>
								<Label className='mb-1'>
									<strong>{t('sports_connections')}</strong>
								</Label>
							</Row>
							<div
								key={`sport-entity-pill-key-${event.home_team.id}`}
								style={{ marginBottom: '5px' }}
								className={`sport-entity-pill mr-1 team-border`}
							>
								<img
									width='20'
									height='20'
									className='team-image'
									src={event.home_team.urlLogo ? event.home_team.urlLogo : placeholder}
									onError={(i: any) => (i.target.src = assetsPlaceholder.team)}
									alt={event.home_team.name ? event.home_team.name : ''}
								/>
								<div className='sport-entity-pill-name mr-1'>{event.home_team.name}</div>
							</div>
							<div
								key={`sport-entity-pill-key-${event.away_team.id}`}
								style={{ marginBottom: '5px' }}
								className={`sport-entity-pill mr-1 team-border`}
							>
								<img
									width='20'
									height='20'
									className='team-image'
									src={event.away_team.urlLogo ? event.away_team.urlLogo : placeholder}
									onError={(i: any) => (i.target.src = assetsPlaceholder.team)}
									alt={event.away_team.name ? event.away_team.name : ''}
								/>
								<div className='sport-entity-pill-name mr-1'>{event.away_team.name}</div>
							</div>
						</div>
					)}
				</Col>
				<Col md={1}>
					<div className='cursor-pointer' onClick={() => handleDeleteRow(event.id)}>
						<i className='fa fa-trash-can p-1' />
					</div>
				</Col>
			</Row>
		</div>
	) : null;
};
