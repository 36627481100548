import React from 'react';
import BlockModel from '../../../../models/block.model';
import TournamentSelectInstat from '../partials/tournament-select/tournament-select.component';
import TournamentSelectModel from '../partials/tournament-select/tournament-select.model';
import SeasonSelect from '../partials/seasons-in-tournament-select/seasons-in-tournament-select.component';
import MatchSelect from '../partials/matches-in-season-select/matches-in-season-select.component';
import { Col, Label, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import _ from 'lodash';
import InstatHttpService from '../../../../../../../services/rest/instat/InstatHttpService';
import ErrorHandler from '../../../../partials/error/error-handler-component';

type Properties = {
	t: any;
	block: BlockModel;
};

export default class InstatPostMatchPlayersStatsBlockEdit extends React.Component<any, any> {
	private httpInstat = new InstatHttpService();

	constructor(props: Properties) {
		super(props);
		props.block.data.preview = props.block.data.preview ? props.block.data.preview : {};

		const { selectedTournamentMatch, selectedSeasonMatch, selectedMatch, goalkeepers, showAllPlayers } = props.block.data.preview;
		const { match, team1, team2 } = props.block.data;

		this.state = {
			selectedTournamentMatch: selectedTournamentMatch ? selectedTournamentMatch : null,
			selectedSeasonMatch: selectedSeasonMatch,
			selectedMatch: selectedMatch,
			goalkeepers: goalkeepers,
			statsMatch: match ? match.stats : null,
			playersTeam1: team1 ? team1.players : null,
			playersTeam2: team2 ? team2.players : null,
			statsTeam1: team1 ? team1.stats : null,
			statsTeam2: team2 ? team2.stats : null,
			isValid: true,
			showAllPlayers: showAllPlayers,
			statsLoading: false,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onSave = (index: number) => {
		const block = Object.assign({}, this.props.block);
		const validation = new BlockValidation();

		const data = {
			match: { info: this.state.selectedMatch, stats: this.state.statsMatch },
			team1: {
				info: {
					id: this.state.selectedMatch ? this.state.selectedMatch.team1_id : null,
					name: this.state.selectedMatch ? this.state.selectedMatch.team1_name : null,
					logo: this.state.selectedMatch ? this.state.selectedMatch.team1_logo : null,
				},
				players: this.remapPlayerStats(this.state.playersTeam1),
				stats: this.state.statsTeam1,
			},
			team2: {
				info: {
					id: this.state.selectedMatch ? this.state.selectedMatch.team2_id : null,
					name: this.state.selectedMatch ? this.state.selectedMatch.team2_name : null,
					logo: this.state.selectedMatch ? this.state.selectedMatch.team2_logo : null,
				},
				players: this.remapPlayerStats(this.state.playersTeam2),
				stats: this.state.statsTeam2,
			},
			showAllPlayers: this.state.showAllPlayers ? this.state.showAllPlayers : false,
		};
		const isValid = validation.validate(this.props.block, data);

		this.setState({ ...this.state, isValid });

		if (isValid) {
			block.data = data;
			block.data.preview = {
				selectedTournamentMatch: this.state.selectedTournamentMatch,
				selectedSeasonMatch: this.state.selectedSeasonMatch,
				selectedMatch: this.state.selectedMatch,
				playersTeam1: this.state.playersTeam1,
				playersTeam2: this.state.playersTeam2,
				statsTeam1: this.state.statsTeam1,
				statsTeam2: this.state.statsTeam2,
				goalkeepers: this.state.goalkeepers,
				showAllPlayers: this.state.showAllPlayers ? this.state.showAllPlayers : false,
			};
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	onTournamentChange = (tournament: any) => {
		this.setState((state: any) => {
			return {
				...state,
				selectedTournamentMatch: tournament,
				selectedSeasonMatch: null,
				selectedMatch: null,
				playersTeam1: null,
				playersTeam2: null,
				statsTeam1: [],
				statsTeam2: [],
				goalkeepers: null,
				isValid: true,
			};
		});
	};

	onSeasonChange = (season: any) => {
		this.setState((state: any) => {
			return {
				...state,
				selectedSeasonMatch: season,
				selectedMatch: null,
				playersTeam1: null,
				playersTeam2: null,
				statsTeam1: [],
				statsTeam2: [],
				goalkeepers: null,
				isValid: true,
			};
		});
	};

	onMatchChange = (match: any) => {
		this.setState(
			(state: any) => {
				return {
					...state,
					selectedMatch: match,
					playersTeam1: [],
					playersTeam2: [],
					goalkeepers: [],
					statsMatch: [],
					statsTeam1: [],
					statsTeam2: [],
					statsLoading: true,
					isValid: true,
				};
			},
			() => {
				const { selectedMatch } = this.state;
				if (selectedMatch) {
					this.getMatchGoalsStats(selectedMatch.id);
					this.getMatchGoalkeepers(selectedMatch.id);
					this.getMatchPlayersStats(selectedMatch.id);
				} else {
					this.setState({
						statsLoading: false,
						playersTeam1: [],
						playersTeam2: [],
						goalkeepers: [],
						statsMatch: [],
						statsTeam1: [],
						statsTeam2: [],
					});
				}
			},
		);
	};

	private getMatchGoalsStats = (matchId: string) => {
		this.httpInstat.getMatchGoals(matchId).then((data) => {
			this.setState({ statsMatch: data, statsLoading: data ? false : true });
		});
	};

	private getMatchGoalkeepers = (matchId: string) => {
		this.httpInstat.getMatchGoalkeepers(matchId).then((data) => {
			this.setState({ ...this.state, goalkeepers: data, statsLoading: data ? false : true });
		});
	};

	private getMatchPlayersStats = (matchId: string) => {
		this.httpInstat.getMatchPlayersStats(matchId).then((data) => {
			this.setState({
				...this.state,
				playersTeam1: data && data[0] ? data[0].player : [],
				playersTeam2: data && data[1] ? data[1].player : [],
			});
		});
	};

	private getMatchTeamsStats = (matchId: string) => {
		this.httpInstat.getMatchTeamsStats(matchId).then((data) => {
			this.setState({
				...this.state,
				statsTeam1: data && data[0] ? data[0].param : [],
				statsTeam2: data && data[1] ? data[1].param : [],
				statsLoading: data ? false : true,
			});
		});
	};

	onShowAllPlayersCheckboxChecked = (e: any) => {
		const { selectedMatch } = this.state;

		if (selectedMatch && e.target.checked) {
			this.setState({ statsLoading: true });
			this.getMatchTeamsStats(selectedMatch.id);
		}

		if (e.target.checked) {
			this.setState({ showAllPlayers: e.target.checked });
		} else {
			this.setState({ showAllPlayers: e.target.checked, statsTeam1: [], statsTeam2: [] });
		}
	};

	private isMatchDatAvailable = (statsMatch: any, playersTeam1: any, playersTeam2: any, goalkeepers: any) => {
		if (
			statsMatch &&
			statsMatch.length >= 0 &&
			playersTeam1 &&
			playersTeam1.length > 0 &&
			playersTeam2 &&
			playersTeam2.length > 0 &&
			goalkeepers &&
			goalkeepers.length > 0
		) {
			return <div>Data loaded</div>;
		} else if (statsMatch && statsMatch.length < 1) {
			return <div>No data available</div>;
		} else {
			return null;
		}
	};

	private remapPlayerStats = (players: any) => {
		if (players && players.length > 0) {
			return players.map((player: any) => {
				const goalkeepers =
					this.state.goalkeepers && this.state.goalkeepers.length > 0
						? this.state.goalkeepers.filter((object: any) => Object.values(object).some((i: any) => i.includes(player.id)))
						: [];

				return {
					...player,
					logo: `https://instatscout.com/images/players/180/${player.id}.png`,
					position: goalkeepers && goalkeepers.length > 0 ? 'goalkeeper' : 'player',
				};
			});
		}
	};

	render() {
		const { t } = this.props;
		const {
			selectedTournamentMatch,
			selectedSeasonMatch,
			selectedMatch,
			statsMatch,
			playersTeam1,
			playersTeam2,
			statsLoading,
			isValid,
			goalkeepers,
			showAllPlayers,
		} = this.state;

		return (
			<div>
				<Row className='mb-3'>
					<Col xs={12} md={6}>
						<Label>{t('tournament')}</Label>
						<TournamentSelectInstat
							value={selectedTournamentMatch}
							onSelect={(selection: TournamentSelectModel | null) => this.onTournamentChange(selection)}
						/>
						<div className='mt-1'>
							{!isValid && selectedTournamentMatch === null && <ErrorHandler t={this.props.t} errorMessage='field_is_required' />}
						</div>
					</Col>
					<Col xs={12} md={6}>
						{selectedTournamentMatch ? (
							<>
								<Label>{t('season')}</Label>
								<SeasonSelect
									value={selectedSeasonMatch}
									tournamentId={selectedTournamentMatch.id}
									onSelect={(season) => this.onSeasonChange(season)}
								/>
								{!isValid && !selectedSeasonMatch && <ErrorHandler t={this.props.t} errorMessage='field_is_required' />}
							</>
						) : null}
					</Col>
				</Row>
				<Row className='mb-3'>
					<Col xs={12} md={6}>
						{selectedSeasonMatch ? (
							<>
								<Label>{t('match')}</Label>
								<MatchSelect
									value={selectedMatch}
									tournamentId={selectedTournamentMatch.id}
									seasonId={selectedSeasonMatch.id}
									onSelect={(match) => this.onMatchChange(match)}
								/>
								{!isValid && !selectedMatch && <ErrorHandler t={this.props.t} errorMessage='field_is_required' />}
							</>
						) : null}
					</Col>

					<Col xs={12} md={6} className='d-flex flex-column justify-content-center align-items-center pt-4 mb-1'>
						{!statsLoading ? (
							this.isMatchDatAvailable(statsMatch, playersTeam1, playersTeam2, goalkeepers)
						) : (
							<i className={'fa fa-refresh fa-spin text-dark pull-right mt-1'} />
						)}
					</Col>
				</Row>
				<Row className={'mb-1'}>
					<Col>
						{selectedMatch ? (
							<>
								<input
									className={'mr-1'}
									id={`show-all-players-checkbox`}
									checked={selectedMatch && showAllPlayers}
									onChange={this.onShowAllPlayersCheckboxChecked}
									type={'checkbox'}
								/>
								<label htmlFor={`show-all-players-checkbox`}>{this.props.t('show_all_players')}</label>
							</>
						) : null}
					</Col>
				</Row>
				{statsLoading &&
					statsMatch &&
					statsMatch.length === 0 &&
					playersTeam1 &&
					playersTeam1.length === 0 &&
					playersTeam2 &&
					playersTeam2.length === 0 &&
					goalkeepers &&
					goalkeepers.length === 0 && <ErrorHandler t={this.props.t} errorMessage='whait_for_the_data' />}
			</div>
		);
	}
}
