import React from 'react';
import BlockModel from '../../../../models/block.model';
import TournamentSelectInstat from '../partials/tournament-select/tournament-select.component';
import TournamentSelectModel from '../partials/tournament-select/tournament-select.model';
import SeasonSelect from '../partials/seasons-in-tournament-select/seasons-in-tournament-select.component';
import TeamSelect from '../partials/teams-in-season-select/teams-in-season-select.component';
import { Col, Label, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import _ from 'lodash';
import InstatHttpService from '../../../../../../../services/rest/instat/InstatHttpService';
import ErrorHandler from '../../../../partials/error/error-handler-component';

type Properties = {
	t: any;
	block: BlockModel;
};

export default class InstatTeamComparisonBlockEdit extends React.Component<any, any> {
	private httpInstat = new InstatHttpService();

	constructor(props: Properties) {
		super(props);
		props.block.data.preview = props.block.data.preview ? props.block.data.preview : {};

		const {
			selectedTournamentTeam1,
			selectedSeasonTeam1,
			selectedTeam1,
			selectedTournamentTeam2,
			selectedSeasonTeam2,
			selectedTeam2,
		} = props.block.data.preview;
		const { team1, team2 } = props.block.data;

		this.state = {
			selectedTournamentTeam1: selectedTournamentTeam1 ? selectedTournamentTeam1 : null,
			selectedSeasonTeam1: selectedSeasonTeam1,
			selectedTeam1: selectedTeam1,
			selectedTournamentTeam2: selectedTournamentTeam2 ? selectedTournamentTeam2 : null,
			selectedSeasonTeam2: selectedSeasonTeam2,
			selectedTeam2: selectedTeam2,
			stats1: team1 ? team1.stats : null,
			stats2: team2 ? team2.stats : null,
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onSave = (index: number) => {
		const block = Object.assign({}, this.props.block);
		const validation = new BlockValidation();
		const data = {
			team1: { info: this.state.selectedTeam1, stats: this.state.stats1 },
			team2: { info: this.state.selectedTeam2, stats: this.state.stats2 },
		};

		const isValid = validation.validate(this.props.block, data);
		this.setState({ ...this.state, isValid });

		if (isValid) {
			block.data = data;
			block.data.preview = {
				selectedTournamentTeam1: this.state.selectedTournamentTeam1,
				selectedSeasonTeam1: this.state.selectedSeasonTeam1,
				selectedTeam1: this.state.selectedTeam1,
				selectedTournamentTeam2: this.state.selectedTournamentTeam2,
				selectedSeasonTeam2: this.state.selectedSeasonTeam2,
				selectedTeam2: this.state.selectedTeam2,
			};
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	onTournamentChange = (tournament: any, isFirst: boolean) => {
		if (isFirst) {
			this.setState((state: any) => {
				return { ...state, selectedTournamentTeam1: tournament, selectedSeasonTeam1: null, selectedTeam1: null, stats1: [], isValid: true };
			});
		} else {
			this.setState((state: any) => {
				return { ...state, selectedTournamentTeam2: tournament, selectedSeasonTeam2: null, selectedTeam2: null, stats2: [], isValid: true };
			});
		}
	};

	onSeasonChange = (season: any, isFirst: boolean) => {
		if (isFirst) {
			this.setState((state: any) => {
				return { ...state, selectedSeasonTeam1: season, selectedTeam1: null, stats1: [], isValid: true };
			});
		} else {
			this.setState((state: any) => {
				return { ...state, selectedSeasonTeam2: season, selectedTeam2: null, stats2: [], isValid: true };
			});
		}
	};

	onTeamChange = (team: any, isFirst: boolean) => {
		if (isFirst) {
			this.setState(
				(state: any) => {
					return { ...state, selectedTeam1: team, stats1: [], isValid: true };
				},
				() => this.requestTeam1AggStat(),
			);
		} else {
			this.setState(
				(state: any) => {
					return { ...state, selectedTeam2: team, stats2: [], isValid: true };
				},
				() => this.requestTeam2AggStat(),
			);
		}
	};

	private requestTeam1AggStat = () => {
		const { selectedTournamentTeam1, selectedSeasonTeam1, selectedTeam1 } = this.state;
		if (selectedTeam1) {
			this.requestTeamAggStats(selectedTournamentTeam1.id, selectedSeasonTeam1.id, selectedTeam1.id, true);
		}
	};

	private requestTeam2AggStat = () => {
		const { selectedTournamentTeam2, selectedSeasonTeam2, selectedTeam2 } = this.state;
		if (selectedTeam2) {
			this.requestTeamAggStats(selectedTournamentTeam2.id, selectedSeasonTeam2.id, selectedTeam2.id, false);
		}
	};

	private requestTeamAggStats = (tournamentId: string, seasonId: string, teamId: string, isFirst: boolean) => {
		this.httpInstat.getTeamAggStats(tournamentId, seasonId, teamId).then((data) => {
			this.setState(isFirst ? { stats1: data } : { stats2: data });
		});
	};

	private isTeamDatAvailable = (stats: any) => {
		if (stats && stats.length > 0) {
			return <div>Data loaded</div>;
		} else if (stats && stats.length < 1) {
			return <div>No data available</div>;
		} else {
			return null;
		}
	};

	render() {
		const { t } = this.props;
		const {
			selectedTournamentTeam1,
			selectedSeasonTeam1,
			selectedTeam1,
			selectedTournamentTeam2,
			selectedSeasonTeam2,
			selectedTeam2,
			stats1,
			stats2,
			isValid,
		} = this.state;

		return (
			<div>
				<Row className='border-bottom mb-2'>
					<Col>
						<Label>{t('team_1')}</Label>
					</Col>
				</Row>
				<Row className='mb-3'>
					<Col xs={12} md={6}>
						<Label>{t('tournament')}</Label>
						<TournamentSelectInstat
							value={selectedTournamentTeam1}
							onSelect={(selection: TournamentSelectModel | null) => this.onTournamentChange(selection, true)}
						/>
						<div className='mt-1'>
							{!isValid && selectedTournamentTeam1 === null && <ErrorHandler t={this.props.t} errorMessage='field_is_required' />}
						</div>
					</Col>
					<Col xs={12} md={6}>
						{selectedTournamentTeam1 ? (
							<>
								<Label>{t('season')}</Label>
								<SeasonSelect
									value={selectedSeasonTeam1}
									tournamentId={selectedTournamentTeam1.id}
									onSelect={(season) => this.onSeasonChange(season, true)}
								/>
								<div className='mt-1'>
									{!isValid && selectedTournamentTeam1 !== null && selectedSeasonTeam1 == null && (
										<ErrorHandler t={this.props.t} errorMessage='field_is_required' />
									)}
								</div>
							</>
						) : null}
					</Col>
				</Row>
				<Row className='mb-3'>
					<Col xs={12} md={6}>
						{selectedSeasonTeam1 ? (
							<>
								<Label>{t('team')}</Label>
								<TeamSelect
									value={selectedTeam1}
									tournamentId={selectedTournamentTeam1.id}
									seasonId={selectedSeasonTeam1.id}
									onSelect={(team) => this.onTeamChange(team, true)}
								/>
								<div className='mt-1'>
									{!isValid && selectedSeasonTeam1 !== null && selectedTeam1 == null && (
										<ErrorHandler t={this.props.t} errorMessage='field_is_required' />
									)}
								</div>
							</>
						) : null}
					</Col>
					<Col xs={12} md={6} className='d-flex flex-column justify-content-center align-items-center pt-4'>
						{this.isTeamDatAvailable(stats1)}
					</Col>
				</Row>
				<Row className='border-bottom mb-2'>
					<Col>
						<Label>{t('team_2')}</Label>
					</Col>
				</Row>
				<Row className='mb-3'>
					<Col xs={12} md={6}>
						<Label>{t('tournament')}</Label>

						<TournamentSelectInstat
							value={selectedTournamentTeam2}
							onSelect={(selection: TournamentSelectModel | null) => this.onTournamentChange(selection, false)}
						/>
						<div className='mt-1'>
							{!isValid && selectedTournamentTeam2 === null && <ErrorHandler t={this.props.t} errorMessage='field_is_required' />}
						</div>
					</Col>
					<Col xs={12} md={6}>
						{selectedTournamentTeam2 ? (
							<>
								<Label>{t('season')}</Label>
								<SeasonSelect
									value={selectedSeasonTeam2}
									tournamentId={selectedTournamentTeam2.id}
									onSelect={(season) => this.onSeasonChange(season, false)}
								/>
								<div className='mt-1'>
									{!isValid && selectedTournamentTeam2 !== null && selectedSeasonTeam2 == null && (
										<ErrorHandler t={this.props.t} errorMessage='field_is_required' />
									)}
								</div>
							</>
						) : null}
					</Col>
				</Row>
				<Row className='mb-3'>
					<Col xs={12} md={6}>
						{selectedSeasonTeam2 ? (
							<>
								<Label>{t('team')}</Label>
								<TeamSelect
									value={selectedTeam2}
									tournamentId={selectedTournamentTeam2.id}
									seasonId={selectedSeasonTeam2.id}
									onSelect={(team) => this.onTeamChange(team, false)}
								/>
								<div className='mt-1'>
									{!isValid && selectedSeasonTeam2 !== null && selectedTeam2 == null && (
										<ErrorHandler t={this.props.t} errorMessage='field_is_required' />
									)}
								</div>
							</>
						) : null}
					</Col>
					<Col xs={12} md={6} className='d-flex flex-column justify-content-center align-items-center pt-4'>
						{this.isTeamDatAvailable(stats2)}
					</Col>
				</Row>
			</div>
		);
	}
}
