import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import BlockModel from '../../../../models/block.model';
import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import StageModel from '../../../../../../../models/v2/Stage/Entity/StageModel';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import TeamProgrammeWidgetModel from '../models/team-programme-widget.model';
import TournamentSelectComponent from '../../../partials/tournament-select/tournament-select.component';
import RoundSelectComponent from '../../../partials/round-select/round-select.component';
import TeamSelectComponent from '../../../partials/team-select/team-select.component';
import SeasonSelectComponent from '../../../partials/season-select/season-select.component';
import StageSelectComponent from '../../../partials/stage-select/stage-select.component';
import TeamModel from '../../../../../../../models/v2/Team/Entity/TeamModel';
import SortDirectionSelectComponent from '../../../partials/sort-direction-select/sort-direction-select.component';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import { createBlock } from '../../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import RoundModel from '../../../../../../../models/v2/round/entity/round-model';
import GenericSelect from '../../../../partials/generic-select/generic-select-component';
import { WidgetTeamProgrammeMatchTypeOptions } from '../../football-team/constants/football-team.constants';
import { getDefaultType } from '../../../../blocks/content/embed-block/helpers/embed-block-edit.helper';
import { onRefreshTimeChangeFromMainComponent } from '../../../partials/refresh-time-input/helper';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import RefreshTimeWrapper from '../../../partials/refresh-time-input/refresh-time-wrapper';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import TournamentProgrammeWidgetModel from '../../football-tournament-programme/models/football-tournament-programme.model';
import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import SportBookmakerSelect from '../../../../partials/sport-bookmaker-select/sport-bookmaker-select.component';

type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: TeamProgrammeWidgetModel;
	isValid: boolean;
};

export default class FootballTeamProgrammeEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetFootballTeamProgramme,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { widgetModel: this.state.widgetModel };
		const isValid = validation.validate(this.props.block, data) === true;

		this.setState({ ...this.state, isValid });

		const bookmakerSelectionEnabled = featuresService && featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const teamProgrammeModel = TeamProgrammeWidgetModel.builder(this.state.widgetModel)
			.withBookmakers(bookmakerSelectionEnabled ? this.state.widgetModel.bookmakers : null)
			.build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: teamProgrammeModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateWidgetModelState = (model: TeamProgrammeWidgetModel) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onTournamentSelect = (tournament: TournamentModel) => {
		this.updateWidgetModelState(
			TeamProgrammeWidgetModel.builder(this.state.widgetModel)
				.withTournament(tournament)
				.withSeason({} as SeasonModel)
				.withStage({} as StageModel)
				.withRound([])
				.withTeam({} as TeamModel)
				.build(),
		);
	};

	onSeasonSelect = (season: SeasonModel) => {
		this.updateWidgetModelState(
			TeamProgrammeWidgetModel.builder(this.state.widgetModel)
				.withSeason(season)
				.withStage({} as StageModel)
				.withRound([])
				.withTeam({} as TeamModel)
				.build(),
		);
	};

	onStageSelect = (stage: StageModel) => {
		this.updateWidgetModelState(
			TeamProgrammeWidgetModel.builder(this.state.widgetModel)
				.withStage(stage)
				.withRound([])
				.withTeam({} as TeamModel)
				.build(),
		);
	};

	onRoundSelect = (rounds: RoundModel[]) => {
		this.updateWidgetModelState(TeamProgrammeWidgetModel.builder(this.state.widgetModel).withRound(rounds).build());
	};

	onTeamSelect = (team: TeamModel) => {
		this.updateWidgetModelState(TeamProgrammeWidgetModel.builder(this.state.widgetModel).withTeam(team).withDisplayOdds(true).build());
	};

	onSortDirectionSelect = (sortDirection: any) => {
		this.updateWidgetModelState(
			TeamProgrammeWidgetModel.builder(this.state.widgetModel)
				.withSortDIrection(sortDirection && sortDirection.value)
				.build(),
		);
	};

	onMatchTypeChange = (type: any) => {
		this.updateWidgetModelState(TeamProgrammeWidgetModel.builder(this.state.widgetModel).withMatchType(type).build());
	};

	onDisplayOddsChange = (e: any) => {
		this.updateWidgetModelState(TeamProgrammeWidgetModel.builder(this.state.widgetModel).withDisplayOdds(e.target.checked).build());
	};

	onBookmakerChange = (bookmakers: SportOddsBookmakerModel[]) => {
		this.updateWidgetModelState(TeamProgrammeWidgetModel.builder(this.state.widgetModel).withBookmakers(bookmakers).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, TeamProgrammeWidgetModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	render() {
		const { widgetModel, isValid } = this.state;
		const { t } = this.props;
		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);

		return (
			<>
				<Row data-qa='football-team-programme-edit'>
					<Col>
						<FormGroup>
							<TournamentSelectComponent
								isMulti={false}
								isBlocky
								isValid={isValid}
								selectedTournament={widgetModel.tournament}
								onTournamentSelect={this.onTournamentSelect}
								t={this.props.t}
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TOURNAMENT_SELECT}`}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<SeasonSelectComponent
								withLabel
								isValid={isValid}
								isBlocky
								selectedSeason={widgetModel.season}
								tournamentId={widgetModel.tournament && widgetModel.tournament.id ? widgetModel.tournament.id : ''}
								onSeasonSelect={this.onSeasonSelect}
								t={this.props.t}
								preselectSeason
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.SEASON_SELECT}`}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<StageSelectComponent
								withLabel
								isValid={isValid}
								selectedStage={widgetModel.stage}
								seasonId={widgetModel.season && widgetModel.season.id && widgetModel.season.id}
								onStageSelect={this.onStageSelect}
								t={this.props.t}
								isClearable
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.STAGE_SELECT}`}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormGroup>
							<RoundSelectComponent
								isMulti={true}
								isBlocky
								isValid={isValid}
								preselectRound={false}
								selectedRound={widgetModel.rounds}
								stageId={widgetModel.stage ? widgetModel.stage.id : null}
								onRoundSelect={(round: RoundModel[]) => this.onRoundSelect(round)}
								t={this.props.t}
								isClearable
								isRequired={false}
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.ROUND_SELECT}`}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<TeamSelectComponent
								isMulti={false}
								isBlocky
								isValid={isValid}
								selectedTeam={widgetModel.team}
								stageId={widgetModel.stage ? widgetModel.stage.id : null}
								seasonId={widgetModel.season ? widgetModel.season.id : null}
								onTeamSelect={this.onTeamSelect}
								t={this.props.t}
								isClearable
								isRequired
								searchByKeyword={false}
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.TEAM_SELECT}`}
							/>
						</FormGroup>
					</Col>
				</Row>
				<hr />
				<Row>
					<Col>
						<Label>
							<strong>{t('filters')}</strong>
						</Label>
					</Col>
				</Row>
				{widgetModel.team && widgetModel.team.id && (
					<Row className='mt-2' data-qa='football-team-programme-match-type-select'>
						<Col>
							<FormGroup>
								<Label htmlFor={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.MATCH_TYPE}`}>{t('match_type')}</Label>
								<GenericSelect
									t={t}
									isMulti={false}
									options={widgetModel.team && widgetModel.team.id && WidgetTeamProgrammeMatchTypeOptions}
									value={
										widgetModel.matchType ? widgetModel.matchType : this.onMatchTypeChange(getDefaultType(WidgetTeamProgrammeMatchTypeOptions, t))
									}
									onGenericOptionSelect={this.onMatchTypeChange}
								/>
							</FormGroup>
						</Col>
					</Row>
				)}
				<Row>
					<Col>
						<FormGroup>
							<SortDirectionSelectComponent
								selectedSortDirection={widgetModel.sortDirection ? widgetModel.sortDirection : ''}
								t={t}
								onSortDirectionSelect={this.onSortDirectionSelect}
								withLabel
								isClearable
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.SORT_DIRECTION_SELECT}`}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col>
						<FormGroup key={`display-odds-markets`} check className='radio'>
							<Input
								data-qa={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.DISPLAY_ODDS_SELECT}`}
								key={`display-odds-markets-input`}
								className='form-check-input'
								type='checkbox'
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.DISPLAY_ODDS_SELECT}-${this.props.block.id}`}
								name='radios'
								onChange={this.onDisplayOddsChange}
								checked={widgetModel.displayOdds ? widgetModel.displayOdds : false}
							/>
							<Label
								htmlFor={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.DISPLAY_ODDS_SELECT}`}
								key={`display-odds-markets-label`}
								check
								className='form-check-label'
								for={`display-odds-markets-checkbox-${this.props.block.id}`}
							>
								{this.props.t('display_odds')}
							</Label>
						</FormGroup>
					</Col>
				</Row>
				{widgetModel && widgetModel.displayOdds && bookmakerSelectionEnabled && (
					<Row className='mb-2'>
						<Col>
							<SportBookmakerSelect
								isMulti={false}
								isValid={this.state.isValid}
								showAllBookmakers={false}
								bookmakerSelectionEnabled={bookmakerSelectionEnabled}
								t={this.props.t}
								valueList={widgetModel.bookmakers ? widgetModel.bookmakers : []}
								onSelect={this.onBookmakerChange}
								contentLanguage={this.props.contentLanguage}
								sportType={SportTypes.FOOTBALL}
								id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.BOOKMAKER_SELECT}`}
							/>
						</Col>
					</Row>
				)}
				<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
			</>
		);
	}
}
