import React from 'react';
import { Col, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import ErrorHandler from '../../../../partials/error/error-handler-component';
import BlockModel from '../../../../models/block.model';
import BasketballStandingsModel from '../models/basketball-standings.model';
import CompetitionModel from '../../../../../../../models/v2/competition/entity/competition.model';
import BasketballSeasonModel from '../../../../../../../models/v2/basketball-season/entity/basketball-season.model';
import BasketballStageModel from '../../../../../../../models/v2/Stage/Entity/basketball-stage.model';
import CompetitionSelectComponent from '../../../../partials/competition-select/competition-select.component';
import BasketballSeasonSelectComponent from '../../../../partials/season-select/basketball-season-select.component';
import BasketballStageSelectComponent from '../../../../partials/stage-select/basketball-stage-select.component';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../../../v2/widgets-v2/helpers/component-attributes.constants';
import { createBlock } from '../../../../v2/partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import { onRefreshTimeChangeFromMainComponent } from '../../../../v2/partials/refresh-time-input/helper';
import RefreshTimeWrapper from '../../../../v2/partials/refresh-time-input/refresh-time-wrapper';
import HeaderDefaultOptionWrapper from '../../../../v2/partials/header-default-option-input/header-default-option-wrapper';
import {
	BASKETBALL_STANDINGS_HEADER_DEFAULT_OPTIONS,
	onSelectionChangeFromMainComponent,
} from '../../../../v2/partials/header-default-option-input/helper';

type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: BasketballStandingsModel;
	isValid: boolean;
};

export default class BasketballStandingsEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetBasketballStandings,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			isValid: true,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	onSave = (index: number) => {
		const data = { widgetModel: this.state.widgetModel };
		const validation = new BlockValidation();
		const isValid = validation.validate(this.props.block, data) ? true : false;
		this.setState({ ...this.state, isValid });

		const standingsModel = BasketballStandingsModel.builder(this.state.widgetModel).build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: standingsModel };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	updateWidgetModelState = (model: BasketballStandingsModel) => this.setState({ ...this.state, widgetModel: model });

	onCompetitionSelect = (competition: CompetitionModel) => {
		this.updateWidgetModelState(
			BasketballStandingsModel.builder(this.state.widgetModel)
				.withTournament(competition)
				.withSeason({} as BasketballSeasonModel)
				.withStage({} as BasketballStageModel)
				.build(),
		);
	};

	onSeasonSelect = (season: BasketballSeasonModel) => {
		this.updateWidgetModelState(
			BasketballStandingsModel.builder(this.state.widgetModel)
				.withSeason(season)
				.withStage({} as BasketballStageModel)
				.build(),
		);
	};

	onStageSelect = (stage: BasketballStageModel) => {
		this.updateWidgetModelState(BasketballStandingsModel.builder(this.state.widgetModel).withStage(stage).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, BasketballStandingsModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	onHeaderDefaultOptionChange = (selectedHeaderDefaultOption: SelectMenuOptionType) => {
		onSelectionChangeFromMainComponent(
			selectedHeaderDefaultOption,
			BasketballStandingsModel,
			this.state.widgetModel,
			this.updateWidgetModelState,
		);
	};

	render() {
		const { widgetModel, isValid } = this.state;
		const { t, contentLanguage } = this.props;

		return (
			<div data-qa='basketball-standings-edit-component'>
				<Row className='mb-2'>
					<Col>
						<CompetitionSelectComponent
							onCompetitionSelect={this.onCompetitionSelect}
							t={t}
							selectedCompetition={
								widgetModel.competition && widgetModel.competition.id && widgetModel.competition.id.length > 0
									? widgetModel.competition
									: ({} as CompetitionModel)
							}
							isValid={isValid}
							language={contentLanguage}
							isRequired
							isClearable
							isBlocky
							sport={SportTypes.BASKETBALL}
							id={`${SportTypes.BASKETBALL}-${SPORT_SELECT_ATTRIBUTES.COMPETITION_SELECT}`}
						/>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col>
						<BasketballSeasonSelectComponent
							onSeasonSelect={this.onSeasonSelect}
							t={t}
							selectedSeason={
								widgetModel.season && widgetModel.season.id && widgetModel.season.id.length > 0 ? widgetModel.season : ({} as BasketballSeasonModel)
							}
							isValid={isValid}
							blockData={widgetModel}
							language={contentLanguage}
							isClearable
							isBlocky
							sport={SportTypes.BASKETBALL}
							id={`${SportTypes.BASKETBALL}-${SPORT_SELECT_ATTRIBUTES.SEASON_SELECT}`}
						/>
					</Col>
				</Row>
				<Row className='mb-2'>
					<Col>
						<BasketballStageSelectComponent
							onStageSelect={this.onStageSelect}
							t={t}
							selectedStage={
								widgetModel.stage && widgetModel.stage.id && widgetModel.stage.id.length > 0 ? widgetModel.stage : ({} as BasketballStageModel)
							}
							isValid={isValid}
							blockData={widgetModel}
							language={contentLanguage}
							isClearable
							isBlocky
							sport={SportTypes.BASKETBALL}
							id={`${SportTypes.BASKETBALL}-${SPORT_SELECT_ATTRIBUTES.STAGE_SELECT}`}
						/>
					</Col>
				</Row>
				{(!isValid && widgetModel.competition && widgetModel.competition.id === '') ||
					(widgetModel.competition === null && (
						<div className='mb-3'>
							<ErrorHandler t={t} errorMessage='no_competition_selected' />
						</div>
					))}
				<HeaderDefaultOptionWrapper
					options={BASKETBALL_STANDINGS_HEADER_DEFAULT_OPTIONS}
					value={this.state.widgetModel.headerDefaultOption}
					onOptionChange={this.onHeaderDefaultOptionChange}
				/>
				<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
			</div>
		);
	}
}
