import { call, put, takeEvery } from 'redux-saga/effects';
import HttpService from '../../services/rest/HttpService';
import { onError } from '../action-creators/GeneralActions';
import {
	USER_STATISTICS_CONTENT_REQUEST,
	returnObjectForUserStatisticsContentReceived,
	returnObjectForAllStatisticsContentReceived,
	ALL_STATISTICS_CONTENT_REQUEST,
	returnObjectForPlayerStatisticsReceived,
	PLAYER_STATISTICS_REQUEST,
	TEAM_STATISTICS_REQUEST,
	CATEGORY_RELATED_STATISTICS_REQUEST,
	returnObjectForCategoryRelatedStatisticsReceived,
	returnObjectForTeamStatisticsReceived,
} from '../action-creators/statistics-creator';

function* fetchUserStatisticsFromApi(action: any) {
	try {
		let headers = { 'x-project': action.payload.project.domain };
		let startDate = action.payload.startDate;
		let createdBy = action.payload.createdBy;

		const userStatistic = yield call(
			HttpService.getStatistics,
			`/content?resource_type=all&start_date=${startDate}&created_by=${createdBy}`,
			null,
			headers,
		);

		yield put(returnObjectForUserStatisticsContentReceived(userStatistic, 'all'));
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchAllStatisticsFromApi(action: any) {
	try {
		let headers = { 'x-project': action.payload.project.domain };
		let startDate = action.payload.startDate;

		const allStatistics = yield call(HttpService.getStatistics, `/content?resource_type=all&start_date=${startDate}`, null, headers);

		yield put(returnObjectForAllStatisticsContentReceived(allStatistics, 'all'));
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchFilteredStatisticsFromApi(action: any) {
	try {
		let headers = { 'x-project': action.payload.project.domain };
		let resourceType = action.payload.resourceType;
		let relatedType = action.payload.relatedType;
		let relatedId = action.payload.relatedId;
		let startDate = action.payload.startDate;
		let endDate = action.payload.endDate;

		const statistics = yield call(
			HttpService.getStatistics,
			`/content?resource_type=${resourceType}&related_type=${relatedType}&related_id=${relatedId}&start_date=${startDate}&end_date=${endDate}`,
			null,
			headers,
		);

		relatedType === 'player'
			? yield put(returnObjectForPlayerStatisticsReceived(statistics, resourceType))
			: yield put(returnObjectForTeamStatisticsReceived(statistics, resourceType));
	} catch (error) {
		yield put(onError(error));
	}
}

function* fetchCategoryRealtedStatisticsFromApi(action: any) {
	try {
		let headers = { 'x-project': action.payload.project.domain };
		let resourceType = action.payload.resourceType;
		let startDate = action.payload.startDate;
		let endDate = action.payload.endDate;
		let categoryId = action.payload.categoryId;

		const statistics = yield call(
			HttpService.getStatistics,
			`/content?resource_type=${resourceType}&start_date=${startDate}&end_date=${endDate}&category_id=${categoryId}`,
			null,
			headers,
		);

		yield put(returnObjectForCategoryRelatedStatisticsReceived(statistics, resourceType));
	} catch (error) {
		yield put(onError(error));
	}
}

function* statisticsSaga() {
	yield takeEvery(USER_STATISTICS_CONTENT_REQUEST, fetchUserStatisticsFromApi);
	yield takeEvery(ALL_STATISTICS_CONTENT_REQUEST, fetchAllStatisticsFromApi);
	yield takeEvery(PLAYER_STATISTICS_REQUEST, fetchFilteredStatisticsFromApi);
	yield takeEvery(TEAM_STATISTICS_REQUEST, fetchFilteredStatisticsFromApi);
	yield takeEvery(CATEGORY_RELATED_STATISTICS_REQUEST, fetchCategoryRealtedStatisticsFromApi);
}

export default statisticsSaga;
