import axios, { CancelTokenSource } from 'axios';
import { featuresService } from '../../App';
import { SportTypes } from '../../constants/sport.types';
import REACT_APP_URLS from '../../global-helpers/global-url.helpers';
import { ArticleAutoTaggingScope } from '../../models/article/ArticleJson';
import { store } from '../../store/store';
import { loaderUiService } from '../../views/Partials/Blocky/blocks/editor/editor-block-edit.component';
import EditorHelper from '../../views/Partials/Blocky/blocks/editor/helpers/editor-block-edit.helper';
import { FeatureTypes } from '../feature-service/features.enum';

interface IRequestParams {
	sports?: string[];
	text: string;
	input_language: string;
	competition_ids?: string[];
}

export interface IEntity {
	id: string | number; // Number for legacy reasons (football only)
	name: string;
	slug: string;
	entity_type: string;
	sport: string;
	translations: {
		language: string;
		name: string;
	}[];
	display_asset: {
		url: string | null;
	};
	competition_ids: string | null;
	country_id: string;
	type: string;
	gender: string | null;
	legacy_id: string;
}

interface IResponse {
	tags: {
		entities: IEntity[];
		positions: {
			start: number;
			end: number;
		}[];
		tagged_text: string;
	}[];
}

export default class AutoTagHttp {
	source?: CancelTokenSource;

	cancel() {
		if (this.source && this.source.hasOwnProperty('cancel')) {
			this.source.cancel('Cancelled because of component close!');
		}
	}

	/**
	 * Auto-tagging v1 API
	 * @deprecated
	 */
	instanceAutoTag = () =>
		axios.create({
			baseURL: REACT_APP_URLS.REACT_APP_AUTO_TAGGING_URL,
			headers: {
				'X-Project': store.getState().project.currentProject.domain,
			},
		});

	/**
	 * Auto-tagging v2 API
	 */
	instanceAutoTagV2 = () =>
		axios.create({
			baseURL: REACT_APP_URLS.REACT_APP_AUTO_TAGGING_URL,
			headers: {
				'X-Project': store.getState().project.currentProject.domain,
				Authorization: REACT_APP_URLS.REACT_APP_AUTO_TAGGING_V2_API_AUTH_HEADER,
			},
		});

	getTags = (text: string, id: string, withScope = true) => {
		const { linkOptions, linkOptionsPerSport } = featuresService.getFeatureConfig(FeatureTypes.AUTO_TAGGING);
		const regex = /(<([^>]+)>)/gi;
		const regexSpace = /&nbsp;/gi;
		const state = store.getState();
		const lang: string = state.project.currentProject.language.split('-')[0];
		const postObj: IRequestParams = {
			input_language: lang,
			text: text.replace(regex, '').replace(regexSpace, ' ').trim(),
		};
		const supportedSports: string[] = [];

		if (linkOptionsPerSport) {
			supportedSports.push(...Object.keys(linkOptionsPerSport).map((sport) => sport));
		} else if (linkOptions) {
			supportedSports.push(SportTypes.FOOTBALL);
		}

		if (withScope) {
			const autoTaggingScope: ArticleAutoTaggingScope = state.blocky.autoTaggingScope;

			if (autoTaggingScope) {
				const { sport, competition_ids } = autoTaggingScope;

				if (sport) {
					postObj.sports = [sport];
				}

				if (competition_ids) {
					postObj.competition_ids = competition_ids;
				}
			}
		}

		if (!postObj.sports) {
			postObj.sports = supportedSports;
		}

		return new Promise((resolve, reject) => {
			const cancelToken = axios.CancelToken;
			this.source = cancelToken.source();
			loaderUiService.toggleIsAutolinking({ isLoading: true, id });
			this.instanceAutoTagV2()
				.post<IResponse>('/tag', postObj, { cancelToken: this.source.token })
				.then((response) => {
					if (!response.data.tags) {
						loaderUiService.toggleIsAutolinking({ isLoading: false, id });
						resolve({});
						return;
					}

					const data = {};

					response.data.tags.forEach((tag) => {
						const applicableEntities = tag.entities.filter(({ entity_type, sport }) =>
							new EditorHelper().autoTagFeatureLinkOptionsHasLinkType(entity_type, sport),
						);

						if (applicableEntities.length === 0) {
							return;
						}

						const dataInner = applicableEntities[0];

						applicableEntities.forEach((entity) => {
							if (entity.sport === SportTypes.FOOTBALL && entity.legacy_id) {
								// Make id numeric for legacy reasons (football only)
								entity.id = Number(entity.legacy_id);
							}
						});

						dataInner['type'] = dataInner.sport;
						dataInner['subtype'] = dataInner.entity_type;
						dataInner['more'] = applicableEntities.length;
						dataInner['moreTags'] = JSON.parse(JSON.stringify(applicableEntities));
						data[tag.tagged_text] = [dataInner];
					});

					loaderUiService.toggleIsAutolinking({ isLoading: false, id });
					resolve({ ...data });
				})
				.catch((e) => {
					loaderUiService.toggleIsAutolinking({ isLoading: false, id });
					reject(e);
				});
		});
	};
}
