import React from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import SortDirection from '../../../../../../models/v2/sort-direction/sort-direction.model';
import { selectOptions, SORT_DIRECTION_OPTIONS } from '../helpers/sort-direction-select/sort-direction-select-component.helper';

type Properties = {
	onSortDirectionSelect: (direction: SortDirection) => void;
	selectedSortDirection: string;
	t: any;
	withLabel?: boolean;
	isClearable?: boolean;
	id: string;
};

const SortDirectionSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const { t, withLabel, onSortDirectionSelect, selectedSortDirection, isClearable } = props;

	return (
		<Row data-qa={props.id}>
			<Col>
				{withLabel && <Label htmlFor={props.id}>{t('sort_direction')}:</Label>}
				<Select
					id={props.id}
					value={selectOptions(selectedSortDirection, t)}
					options={SORT_DIRECTION_OPTIONS(t)}
					placeholder={t('sort_direction_placeholder')}
					onChange={onSortDirectionSelect}
					isClearable={isClearable}
				/>
			</Col>
		</Row>
	);
};

export default SortDirectionSelectComponent as React.ComponentType<Properties>;
