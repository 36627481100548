import { featuresService } from '../../../../../../App';
import { relatedConstants } from '../../../../../../constants/related.constants';
import { SportTypes } from '../../../../../../constants/sport.types';
import { FeatureTypes } from '../../../../../../services/feature-service/features.enum';
import { removeSuggestedEntitiesByOrigin } from '../../../../../../store/action-creators/suggested-entities';
import { store } from '../../../../../../store/store';
import { TiptapAction } from '../../../../../Pages/Live Blog/components/subcomponents/collaborative-editor/collaborative-editor-toolbar';
import { EditorTypes } from '../../../constants/block.types';

export default class EditorHelper {
	autoTagFeatureLinkOptionsHasLinkType = (type, sport) => {
		const config = featuresService.getFeatureConfig(FeatureTypes.AUTO_TAGGING);

		if (!config) {
			return false;
		}

		const { linkOptions, linkOptionsPerSport } = config;

		if (linkOptionsPerSport) {
			if (!linkOptionsPerSport[sport]) {
				return false;
			}

			if (type === relatedConstants.types.competition) {
				// Fallback if competition link option is not set in feature control
				const option =
					config.linkOptionsPerSport[sport][relatedConstants.types.competition] ||
					config.linkOptionsPerSport[sport][relatedConstants.types.tournament];

				return option && option.length > 0;
			}

			return linkOptionsPerSport[sport][type] && linkOptionsPerSport[sport][type].length > 0;
		} else if (linkOptions) {
			// If only linkOptions is set, we treat it as football only
			if (sport !== SportTypes.FOOTBALL) {
				return false;
			}

			if (type === relatedConstants.types.competition) {
				// Fallback if competition link option is not set in feature control
				const option = config.linkOptions[relatedConstants.types.competition] || config.linkOptions[relatedConstants.types.tournament];

				return option && option.length > 0;
			}

			return config.linkOptions[type] && config.linkOptions[type].length > 0;
		} else {
			return false;
		}
	};

	hasAutoTagLinkOptionsConfig = () => {
		const config = featuresService.getFeatureConfig(FeatureTypes.AUTO_TAGGING);

		return config && (config.hasOwnProperty('linkOptions') || config.hasOwnProperty('linkOptionsPerSport'));
	};

	getContentForNewParagraph = () => {
		const content = localStorage.getItem('new-paragraph-content');

		if (content) {
			localStorage.removeItem('new-paragraph-content');
			return content;
		}
	};

	getLinkingFeatureConfig = () => {
		const isEntityLinkingEnabled = featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.AUTO_TAGGING);
		const autoTagConfig = featuresService.getFeatureConfig(FeatureTypes.AUTO_TAGGING)
			? featuresService.getFeatureConfig(FeatureTypes.AUTO_TAGGING)
			: null;
		const isEntityLinkingManual = autoTagConfig ? featuresService.getFeatureConfig(FeatureTypes.AUTO_TAGGING).isManual : false;

		return {
			isLinkingEnabled: isEntityLinkingEnabled,
			isManual: isEntityLinkingManual,
			config: autoTagConfig,
		};
	};

	static cleanupEditor(placeholderName, blockType) {
		placeholderName = placeholderName || 'tiptap-editor-fresh-init';

		try {
			// Remove suggested entities related with the current paragraph
			if (blockType === EditorTypes.paragraph) {
				store.dispatch(removeSuggestedEntitiesByOrigin(placeholderName));
			}
		} catch (e) {}
	}

	static addAdditionalAttributesToHeading(attributeName, attributeValue, editor) {
		editor && editor.commands.updateAttributes(TiptapAction.HEADING, { [attributeName]: attributeValue || null });
	}

	static extractID(content) {
		let flag = /id=/;

		if (content && content.match(flag)) {
			const paramsPattern = /id="(.*?)"/g;
			let extractParams = content.match(paramsPattern);
			const replacedString = extractParams && extractParams[0].replace('id=', '').replaceAll('"', '');

			if (replacedString !== null && replacedString != undefined) return replacedString;
		}

		return '';
	}

	static extractTabIndex(content) {
		let flag = /tabindex=/;
		let tabIndex;

		if (content && content.match(flag)) {
			const paramsPattern = /tabindex="(.*?)"/g;
			let extractParams = content.match(paramsPattern);
			tabIndex = extractParams && extractParams[0].replace(/[", tabindex=]/g, '');
			if (tabIndex !== null && tabIndex != undefined) return tabIndex;
		}

		return '';
	}
}
