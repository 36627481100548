import React, { FunctionComponent, ChangeEvent } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ErrorHandler from '../../v2/partials/error-handler/error-handler';

export interface Properties {
	value: string;
	onLimitChange: (e: string) => void;
	isRequired?: boolean;
	isValid: boolean;
}

const LimitInput: FunctionComponent<Properties> = ({ value, onLimitChange, isRequired, isValid }) => {
	const [t] = useTranslation();
	const isValueEmpty = !isValid && (!value || value === '');
	const isNegativeValue = (Number(value) < 1 && value !== '') || (value && value.length > 0 && isNaN(Number(value)));

	return (
		<FormGroup data-qa='limit-select'>
			<Label>{t('limit')}:</Label>
			{isRequired && <span className='text-danger'>*</span>}
			<DebounceInput
				type='number'
				id='limit-select'
				className='form-control'
				value={value ? value : ''}
				min={1}
				placeholder={t('limit_step')}
				debounceTimeout={500}
				onChange={(event: ChangeEvent<HTMLInputElement>) => onLimitChange(event.target.value)}
			/>
			<div className='mt-2'>
				<ErrorHandler arePropsValid={isValid} isValueSelected={isValueEmpty} t={t} errorMessage='field_is_required' />
				{isNegativeValue ? (
					<ErrorHandler arePropsValid={isValid} isValueSelected={isNegativeValue} t={t} errorMessage='positive_value_required' />
				) : null}
			</div>
		</FormGroup>
	);
};

export default LimitInput;
