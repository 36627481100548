import React from 'react';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import FansUnitedWidgetModel from './models/fans-united-widget.model';

type Properties = {
	widgetTopXManager: FansUnitedWidgetModel;
	t: any;
};

export const WidgetTopXManagerView: React.FunctionComponent<Properties> = ({ widgetTopXManager, t }) => {
	if (widgetTopXManager && widgetTopXManager.game && widgetTopXManager.game.id) {
		return (
			<>
				<div data-qa='widget-topx-view'>
					<Row className='d-flex p-2  align-items-center mb-1'>
						<Col className='ml-2'>
							<strong>
								{moment(widgetTopXManager.game.predictions_cutoff ? widgetTopXManager.game.predictions_cutoff : '').format('MMM Do YYYY, HH:mm')}
							</strong>
						</Col>
						<Col className='mr-1'>
							<strong>{widgetTopXManager.game.title}</strong>
						</Col>
					</Row>
					<Row className='px-4'>
						<Col>
							<hr />
						</Col>
					</Row>
					<Row className='px-4 pt-1'>
						{widgetTopXManager.game && widgetTopXManager.game.status && (
							<Col className='text-left'>
								<span>{t('status')}: </span>
								<strong>{t(widgetTopXManager.game.status)}</strong>
							</Col>
						)}
					</Row>
					<Row className='px-4 pt-1'>
						{widgetTopXManager.game && widgetTopXManager.game.description && (
							<Col className='text-left'>
								<span>{t('description')}: </span>
								<strong>{t(widgetTopXManager.game.description)}</strong>
							</Col>
						)}
					</Row>
				</div>
			</>
		);
	}

	return null;
};
