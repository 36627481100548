import REACT_APP_URLS from '../../../../global-helpers/global-url.helpers';

export enum SELECT_CONVERT_OPTIONS {
	TO = 'to',
	FROM = 'from',
}

export const IMAGE_PROCESSING_PREFIX = `${REACT_APP_URLS.REACT_APP_IMAGE_API_BASE_URL}/process/`;

export enum FILTER_TYPE {
	BY_NAME = 'by_name',
	BY_COUNTRY = 'by_country',
	MIXED = 'mixed',
	NONE = 'none',
}

export enum RegionsEnum {
	DOMESTIC = 'DOMESTIC',
	INTERNATIONAL = 'INTERNATIONAL',
}

export enum TournamentTypesEnum {
	CUP = 'CUP',
	LEAGUE = 'LEAGUE',
}

export enum GenderEnum {
	MALE = 'MALE',
	FEMALE = 'FEMALE',
}

export enum TournamentViews {
	LIST = 'list',
	EDIT = 'edit',
}

export enum TournamentsFiltersName {
	SEARCH = 'search_value',
	SELECTED_COUNTRIES = 'selected_countries',
}
