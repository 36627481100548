import React from 'react';
import { FormatOptionLabelMeta } from 'react-select/src/Select';
import { assetsPlaceholder } from '../../../../constants/assetsPlaceholder';
import './custom-select-option.scss';

export function customOption(option: any, labelMeta?: FormatOptionLabelMeta<any>, backgroundOptions?: React.CSSProperties) {
	const { logo, label, type, value } = option;
	const placeholder = type ? assetsPlaceholder[type] : assetsPlaceholder.player;

	return (
		<div className={'option-container'} id={`${label}-${value}`}>
			{label &&
				label.length > 0 &&
				(backgroundOptions ? (
					<div
						className='option-logo'
						style={{
							width: '20px',
							height: '20px',
							backgroundImage: `url("${logo || placeholder}")`,
							...backgroundOptions,
						}}
					/>
				) : (
					<img
						width='20'
						height='20'
						className='option-logo'
						src={logo || placeholder}
						onError={(i: any) => (i.target.src = assetsPlaceholder.player)}
					/>
				))}
			<div className={'option-label'}>{label} </div>
		</div>
	);
}
