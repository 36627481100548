import React from 'react';
import WidgetBlocksGenerator from './widget-blocks.generator';
import EditorBlocksGenerator from './editor-blocks.generator';
import ContentBlocksGenerator from './content-blocks.generator';
import BlockControlsWrapper from '../wrappers/block-controls/block-controls.wrapper';
import BlockModel from '../models/block.model';
import * as ReactSortableHOC from 'react-sortable-hoc';
import BackwardCompatibilityService from '../services/backward-compatibility.service';
import CustomBlocksGenerator from './custom-blocks.generator';
import FansUnitedWidgetBlocksGenerator from './fans-united-widget-blocks.generator';
import { EditorTypes, FansUnitedWidgetsTypes, WidgetTypes, WidgetTypesV2 } from '../constants/block.types';
import WidgetBlocksV2Generator from './widget-blocks-v2.generator';

const SortableBlock = ReactSortableHOC.SortableElement(BlockControlsWrapper);

export default class BlocksGenerator {
	public createBlock = (
		block: BlockModel,
		index: number,
		t: any,
		contentData: any,
		blocksLength: number,
		blocks: BlockModel[],
		contentLanguage?: any,
		entityType?: any,
		contentType?: string,
	) => {
		if (this.isBlockWidget(block.type)) {
			const newBlock = new BackwardCompatibilityService().transform(block);

			return (
				<SortableBlock
					key={`block-key-${newBlock.id}`}
					t={t}
					block={newBlock}
					blockIndex={index}
					index={index}
					blocksLength={blocksLength}
					contentType={contentType}
				>
					{new WidgetBlocksGenerator().createBlock(newBlock, t, contentData, contentLanguage)}
				</SortableBlock>
			);
		}

		if (this.isBlockWidgetV2(block.type)) {
			return (
				<SortableBlock
					key={`block-key-${block.id}`}
					t={t}
					block={block}
					blockIndex={index}
					index={index}
					blocksLength={blocksLength}
					contentType={contentType}
				>
					{new WidgetBlocksV2Generator().createBlock(block, t, contentData, contentLanguage)}
				</SortableBlock>
			);
		}

		if (this.isBlockFansUnitedWidget(block.type)) {
			const newBlock = new BackwardCompatibilityService().transform(block);

			return (
				<SortableBlock
					key={`block-key-${newBlock.id}`}
					t={t}
					block={newBlock}
					blockIndex={index}
					index={index}
					blocksLength={blocksLength}
					contentType={contentType}
				>
					{new FansUnitedWidgetBlocksGenerator().createBlock(newBlock, t, contentData)}
				</SortableBlock>
			);
		}

		if (this.isBlockEditor(block.type)) {
			const newBlock = new BackwardCompatibilityService().transform(block);

			return (
				<SortableBlock
					key={`block-key-${newBlock.id}`}
					t={t}
					block={newBlock}
					blockIndex={index}
					index={index}
					blocksLength={blocksLength}
					contentType={contentType}
				>
					{new EditorBlocksGenerator().createBlock(newBlock, index, t)}
				</SortableBlock>
			);
		} else if (this.isCustomBlock(block.type)) {
			const newBlock = new BackwardCompatibilityService().transform(block);
			return (
				<SortableBlock
					key={`block-key-${index}`}
					t={t}
					block={block}
					index={index}
					blockIndex={index}
					blocksLength={blocksLength}
					contentType={contentType}
				>
					{new CustomBlocksGenerator().createBlock(newBlock, t, index, contentData)}
				</SortableBlock>
			);
		} else {
			const newBlock = new BackwardCompatibilityService().transform(block);

			return (
				<SortableBlock
					key={`block-key-${newBlock.id}`}
					t={t}
					block={newBlock}
					blockIndex={index}
					index={index}
					blocksLength={blocksLength}
					contentType={contentType}
				>
					{new ContentBlocksGenerator().createBlock(newBlock, t, contentData, blocks, contentLanguage, entityType, contentType)}
				</SortableBlock>
			);
		}
	};

	private isBlockWidget = (type: string) => {
		return type === WidgetTypes.widget;
	};

	private isBlockWidgetV2 = (type: string) => {
		return type === WidgetTypesV2.widget;
	};

	private isBlockFansUnitedWidget = (type: string) => {
		return type === FansUnitedWidgetsTypes.widget;
	};

	private isBlockEditor = (type: string) => {
		return type === EditorTypes.editor;
	};

	private isCustomBlock = (type: string) => {
		return type === 'custom_block';
	};
}
