import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Properties } from './properties/gallery-info.properties';
import GalleryBlockModel from '../models/gallery-block.model';
import ErrorHandler from '../../../../partials/error/error-handler-component';
import CategorySelectContainer from '../../../../../Sidebar/GeneralContentAttributes/subcomponents/CategorySelect/CategorySelectContainer';
import Category from '../../../../../../../models/category/Category';

export default class GalleryInfo extends React.Component<Properties> {
	onTitleChange = (e: any) => {
		const { onGalleryInfoChange, copyAdditionalCategories, copySportsConnections, copyTags } = this.props;
		const gallery = GalleryBlockModel.builder(this.props.gallery).withTitle(e.target.value).build();
		onGalleryInfoChange(gallery, copyTags, copySportsConnections, copyAdditionalCategories);
	};

	onCategoryChange = (category: any) => {
		const { updateState, copyAdditionalCategories, copySportsConnections, copyTags } = this.props;
		const gallery = GalleryBlockModel.builder(this.props.gallery).withCategory(category).build();
		updateState(gallery, copyTags, copySportsConnections, copyAdditionalCategories);
	};

	onCopyAdditionalCategoriesChecked = (e: any) => {
		const { gallery, updateState, copySportsConnections, copyTags } = this.props;
		updateState(gallery, copyTags, copySportsConnections, e.target.checked);
	};

	onCopyTagsChecked = (e: any) => {
		const { gallery, updateState, copySportsConnections, copyAdditionalCategories } = this.props;
		updateState(gallery, e.target.checked, copySportsConnections, copyAdditionalCategories);
	};

	onCopyFootballConnectionsChecked = (e: any) => {
		const { gallery, updateState, copyAdditionalCategories, copyTags } = this.props;
		updateState(gallery, copyTags, e.target.checked, copyAdditionalCategories);
	};

	render() {
		const { gallery, copyAdditionalCategories, copySportsConnections, copyTags, t, block, allCategories, adminCategories } = this.props;

		return (
			<FormGroup>
				<Row className={'mb-3'}>
					<Col>
						<Label for={`gallery-title`}>{t('title')}:</Label>
						<Input value={gallery.title} id={`gallery-title-${block.id}`} onChange={this.onTitleChange} type={'text'} />
						{!this.props.isValid && !this.props.gallery.title && <ErrorHandler t={this.props.t} errorMessage='field_is_required' />}
					</Col>
				</Row>
				<Row className={'mb-3'}>
					<Col>
						<Label>{t('category')}:</Label>
						<CategorySelectContainer
							inputId='gallery-block-main-category-select'
							t={t}
							categories={allCategories}
							adminCategories={adminCategories}
							selectedCategory={gallery && gallery.category ? gallery.category : Category.builder().build()}
							onCategorySelect={this.onCategoryChange}
						/>
						{!this.props.isValid && !this.props.gallery.category && <ErrorHandler t={this.props.t} errorMessage='field_is_required' />}
					</Col>
				</Row>
				<Row className={'mb-1 copy-settings-row'}>
					<Col>
						<input
							className={'mr-1'}
							id={`copy-additional-categories-${block.id}`}
							checked={copyAdditionalCategories}
							onChange={this.onCopyAdditionalCategoriesChecked}
							type={'checkbox'}
						/>
						<label htmlFor={`copy-additional-categories-${block.id}`}>{this.props.t('copy_additional_categories')}</label>
					</Col>
				</Row>
				<Row className={'mb-1 copy-settings-row'}>
					<Col>
						<input className={'mr-1'} id={`copy-tags-${block.id}`} checked={copyTags} onChange={this.onCopyTagsChecked} type={'checkbox'} />
						<label htmlFor={`copy-tags-${block.id}`}>{this.props.t('copy_tags')}</label>
					</Col>
				</Row>
				<Row className={'mb-1 copy-settings-row'}>
					<Col>
						<input
							className={'mr-1'}
							id={`copy-sports-connections-${block.id}`}
							checked={copySportsConnections}
							onChange={this.onCopyFootballConnectionsChecked}
							type={'checkbox'}
						/>
						<label htmlFor={`copy-sports-connections-${block.id}`}>{this.props.t('copy_sports_connections')}</label>
					</Col>
				</Row>
			</FormGroup>
		);
	}
}
