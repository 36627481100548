import HttpService from '../../../../../../services/rest/HttpService';
import { SportTypes } from '../../../../../../constants/sport.types';
import { modelMatchToResponseModel } from './models/live-blog-events.mapper';

export enum EventStatuses {
	UPCOMING = 'UPCOMING',
	INTERRUPTED = 'INTERRUPTED',
	LIVE = 'LIVE',
}

export enum MatchStatuses {
	UPCOMING = 'notstarted',
	IN_PROGRESS = 'inprogress',
	INTERRUPTED = 'interrupted',
}

export type MatchStatusesTypes = MatchStatuses.UPCOMING | MatchStatuses.IN_PROGRESS | MatchStatuses.INTERRUPTED;

export type EventStatusesTypes = EventStatuses.UPCOMING | EventStatuses.INTERRUPTED | EventStatuses.LIVE;

export const loadMatches = (
	activeTab: EventStatusesTypes,
	tournamentFilter: string,
	seasonFilter: string,
	participantsIds: string[],
	entitiesLimit: number,
	setLoadedMatches: Function,
	setEntitiesLimit: Function,
	setLoading: Function,
	selectedSport: string,
) => {
	const newLimit = entitiesLimit + 10;
	const dataForRequest = extractDataForFetchingFootballMatches(
		extractStatusesTypesBasedOnActiveTab(selectedSport, activeTab) as MatchStatusesTypes[],
		tournamentFilter,
		seasonFilter,
		participantsIds,
		newLimit,
	);

	if (participantsIds && participantsIds.length > 0) {
		HttpService.instanceFootball()
			.get(dataForRequest)
			.then((response: any) => {
				const responseData: Array<any> | null = (response && response.data) || null;
				const remappedData = responseData && responseData.map((entity: any) => modelMatchToResponseModel(entity));
				setLoadedMatches(remappedData);
				setEntitiesLimit(newLimit);
				setLoading(false);
			})
			.catch(() => setLoading(false));
	} else if (tournamentFilter && seasonFilter) {
		HttpService.instanceFootball()
			.get(dataForRequest)
			.then((response: any) => {
				const responseData: Array<any> | null = (response && response.data) || null;
				const remappedData = responseData && responseData.map((entity: any) => modelMatchToResponseModel(entity));
				setLoadedMatches(remappedData);
				setEntitiesLimit(newLimit);
				setLoading(false);
			})
			.catch(() => setLoading(false));
	} else {
		setEntitiesLimit(0);
		setLoadedMatches(null);
		setLoading(false);
	}
};

// this method will generate url for fetching football matches
export const extractDataForFetchingFootballMatches = (
	statusTypes: MatchStatusesTypes[],
	tournamentFilter: string,
	seasonFilter: string,
	participantsIds: string[],
	limit: number = 10,
) => {
	const hardcodedParams = `&sort_direction=asc&limit=${limit}`;
	const statusTypesToString = statusTypes.join(',');
	const tournamentId = tournamentFilter || '';
	const seasonId = seasonFilter || '';

	if (participantsIds && participantsIds.length > 0) {
		return `/matches?team_ids=${participantsIds.join(
			',',
		)}&status_types=${statusTypesToString}&tournament_ids=${tournamentId}&season_ids=${seasonId}${hardcodedParams}`;
	} else if (tournamentId && seasonId) {
		return `/matches?status_types=${statusTypesToString}&tournament_ids=${tournamentId}&season_ids=${seasonId}${hardcodedParams}`;
	}
};

export const extractStatusesTypesBasedOnActiveTab = (selectedSport: string, activeTab: EventStatusesTypes) => {
	const enumToCheck = selectedSport.toLowerCase() === SportTypes.FOOTBALL ? MatchStatuses : EventStatuses;

	switch (activeTab) {
		case EventStatuses.UPCOMING:
			return [enumToCheck.UPCOMING, selectedSport.toLowerCase() === SportTypes.FOOTBALL ? MatchStatuses.IN_PROGRESS : EventStatuses.LIVE];
		case EventStatuses.INTERRUPTED:
			return [enumToCheck.INTERRUPTED];
		default:
			return [];
	}
};

// This method is used to extract saved participants ids for specific sport
export const extractParticipantIds = (teams: any): string[] => {
	return teams && teams.length > 0 && teams.map((x: any) => x.id);
};

export const findEmptyEventArrIndex = (model: any[]) => {
	if (model) {
		return model.findIndex((modelEntity) => modelEntity.id === null && modelEntity.home_team === null && modelEntity.away_team === null);
	}
};

export const emptyEventRowAvailable = (events: any[]) => {
	const newRowIndex = findEmptyEventArrIndex(events);
	return newRowIndex && newRowIndex >= 0;
};

export const removeEventById = (events: any[], searchedEventId: number) => {
	return events.filter((event) => event.id !== searchedEventId);
};

export const findIsEventAlreadySelected = (events: any[], currentlySelectedEl: any) => {
	return events.findIndex((event: any) => event && event.id && event.id == currentlySelectedEl.id) >= 0;
};

export const filterEvents = (selectedEvents: any[], availableEvents: any[]) => {
	return availableEvents.filter((elem) => !selectedEvents.find(({ id }) => elem.id === id));
};
