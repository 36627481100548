import FansUnitedGameModel from '../models/fans-united-game.model';
import FansUnitedHttpService from './fans-united-http.service';
import FansUnitedGameMapper from '../models/fans-united-game.mapper';
import FansUnitedLeaderboardTemplatesModel from '../models/fans-united-leaderboard-templates.model';
import FansUnitedLeaderboardTemplatesMapper from '../models/fans-united-leaderboard-templates.mapper';

export default class FansUnitedService {
	/**
	 * Requests the FU games listing from the fans-united api and returns a Promise<FansUnitedGameModel[]>
	 */
	public getFansUnitedGames = async (type: string, maxGames: number): Promise<FansUnitedGameModel[]> => {
		const games = await FansUnitedHttpService.getGames(type);
		const mapper = new FansUnitedGameMapper();
		if (games && games.data && games.data.data && games.data.data.length > 0) {
			return games.data.data.slice(0, maxGames).map((game: any) => mapper.responseToModel(game));
		} else {
			return [];
		}
	};

	/**
	 * Requests leaderboards templates from the fans-united api and returns a Promise<FansUnitedLeaderboardTemplatesModel[]>
	 */
	public getLeaderboards = async (maxTemplates: number): Promise<FansUnitedLeaderboardTemplatesModel[]> => {
		const leaderboards = await FansUnitedHttpService.getLeaderboardTemplates();
		const mapper = new FansUnitedLeaderboardTemplatesMapper();
		if (leaderboards && leaderboards.data && leaderboards.data.data && leaderboards.data.data.length > 0) {
			return leaderboards.data.data.slice(0, maxTemplates).map((leaderboard: any) => mapper.responseToModel(leaderboard));
		} else {
			return [];
		}
	};

	public markFuGameModelAsSelected(games: FansUnitedGameModel[], gameId: string) {
		if (games) {
			games = games.map((game: FansUnitedGameModel) => {
				game.isSelected = game.id === gameId;

				return game;
			});
		}

		return games;
	}

	public markFuTemplateModelAsSelected(templates: FansUnitedLeaderboardTemplatesModel[], templateId: string) {
		if (templates) {
			templates = templates.map((template: FansUnitedLeaderboardTemplatesModel) => {
				template.isSelected = template.id === templateId;

				return template;
			});
		}

		return templates;
	}
}
