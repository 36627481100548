import React from 'react';
import { Button, Col, Label, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ImageUpload } from '../../Partials/Fields/image-upload/image-upload';
import Project from '../../../models/project/Project';
import Author from '../../../models/author/Author';
import HttpService from '../../../services/rest/HttpService';
import { toast } from 'react-toastify';
import { Properties, State } from './Properties/AuthorCreateProperties';
import {
	AUTHOR_ENTITY_CREATE,
	AUTHOR_ENTITY_CREATE_FAILED,
	AUTHOR_ENTITY_CREATE_SUCCESS,
	returnObjectForAuthorEntityCreate,
	returnObjectForDefaultAuthorEntityUpdate,
} from '../../../store/action-creators/AuthorActionCreators';
import { Bio } from '../../Partials/Fields/bio/BioComponent';
import { IsDefault } from '../../Partials/Fields/is-default/IsDefaultComponent';
import { Name } from '../../Partials/Fields/name/NameComponent';
import { Active } from '../../Partials/Fields/active/ActiveComponent';
import BlockableButtonWrapper from '../../Partials/BaseComponents/BlockableButton/BlockableButtonWrapper';
import SeoBuilder from '../../../models/seo/SeoBuilder';
import SeoModel from '../../../models/seo/Seo';
import { SeoComponentModel } from '../../Partials/Sidebar/Seo/models/SeoComponentModel';
import { authorToSeoComponentModelForCreate, seoComponentModelToAuthor, socialLinksMerge } from './helpers/AuthorHelper';
import { ContentMode, ContentTypes } from '../../../constants/content-types';
import Seo from '../../Partials/Sidebar/Seo/SeoComponent';
import SidebarElementsToggle from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-elements-toggle/sidebar-elements-toggle.component';
import SidebarCollapsibleElements from '../../Partials/Sidebar/sidebar-collapsible-elements/sidebar-collapsible-elements';
import {
	ContentSidebarChangeCollapseStatus,
	onChangeCollapseStatus,
	onSidebarElemSort,
	toggleSidebarEditMode,
} from '../../../services/content-sidebar/content-sidebar.helper';
import { FormattedSidebarSectionValue } from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.types';
import GlobalModal from '../../Partials/Modals/Global/global-modal';
import {
	appendBeforeUnloadResetTempSidebar,
	defaultTempSidebarFromApiResponse,
	overwriteTempSidebarByContentType,
	saveSidebarSettingsByContentType,
} from '../../Partials/Sidebar/sidebar-collapsible-elements/utils/temp-sidebar.helper';
import AuthorSocials from './components/author-socials';

class AuthorCreate extends React.Component<Properties, State> {
	componentDidMount(): void {
		this.initPageTitle();
		this.initAuthorCreateSuccessListener();
		appendBeforeUnloadResetTempSidebar(ContentTypes.ARTICLE);
	}

	initPageTitle() {
		document.title = this.props.t('author_create');
	}

	constructor(props: Properties) {
		super(props);
		this.state = {
			isSidebarInEdit: false,
			author: Author.builder()
				.withSocialMediaLinks(socialLinksMerge(true, this.props.defaultAuthorSocials, []))
				.withSeo(new SeoBuilder().withFollow(true).withIndex(true).build())
				.withActive(true)
				.build(),
			avatarUploaded: false,
			withAvatar: false,
			file: {},
			openSidebarSettingsModalFlag: false,
		};
	}

	initAuthorCreateSuccessListener() {
		window.addEventListener(AUTHOR_ENTITY_CREATE_SUCCESS, (data: any) => {
			this.state.author.isDefault
				? this.props.updateDefaultAUthor(this.state.author)
				: this.props.updateDefaultAUthor(Author.builder().build());
			this.props.history.push(`/smp/authors/edit/${data.detail}`);
		});
	}

	onNameChange(name: string) {
		const seo = SeoModel.builder(this.state.author.seo).withAutoUpdateSeo(name).build();
		this.setState({ author: Author.builder(this.state.author).withSeo(seo).withName(name).build() });
	}

	onSeoChange(seoModel: SeoComponentModel) {
		const author = seoComponentModelToAuthor(seoModel, this.state.author);
		this.setState({ author });
	}

	onSocialChange(slug: string, value: string) {
		const authorSocialsState = this && this.state && this.state.author && this.state.author.social_media_links;
		if (authorSocialsState) {
			const socialIndexForChange = authorSocialsState.findIndex((social) => social.slug === slug);
			const updatedSocials = [...authorSocialsState];
			updatedSocials[socialIndexForChange] = { ...updatedSocials[socialIndexForChange], value };
			this.setState({ author: Author.builder(this.state.author).withSocialMediaLinks(updatedSocials).build() });
		}
	}

	onBioChange(bio: string) {
		this.setState({ author: Author.builder(this.state.author).withBio(bio).build() });
	}

	onFileChange = (file: any) => {
		this.setState({ file });
		this.uploadAvatar(file);
	};

	uploadAvatar = async (file: any) => {
		if (file && file.name) {
			const headers = { Project: this.props.project.domain, 'content-type': 'multipart/form-data' };
			const formData = new FormData();
			formData.append('file', file);
			await HttpService.postImg('/upload', formData, headers)
				.then((response: any) => {
					this.setState({ withAvatar: true });
					toast.success(this.props.t('image_upload_success'));
					const avatar = `${response.data.path}?operations=autocrop(512:512)`;
					const author = Author.builder(this.state.author).withAvatarImage(avatar).build();
					this.setState({ author, withAvatar: true, avatarUploaded: true });
				})

				.catch(() => {
					this.setState({ withAvatar: true, avatarUploaded: false });
					toast.error(this.props.t('image_too_big_upload_error'));
				});
		}
	};

	onIsDefaultChange(isDefault: boolean) {
		if (isDefault) {
			this.setState({ author: Author.builder(this.state.author).withIsDefault(isDefault).withActive(true).build() });
		} else {
			this.setState({ author: Author.builder(this.state.author).withIsDefault(isDefault).build() });
		}
	}

	onActiveChange(active: boolean) {
		if (!active) {
			this.setState({ author: Author.builder(this.state.author).withActive(active).withIsDefault(false).build() });
		} else {
			this.setState({ author: Author.builder(this.state.author).withActive(active).build() });
		}
	}

	onSubmit = async () => {
		const filteredSocials = this.state.author.social_media_links.filter((social) => !!social.value);
		const author = Author.builder(this.state.author).withSocialMediaLinks(filteredSocials).build();
		if (this.state.withAvatar) {
			if (this.state.avatarUploaded) {
				await this.props.postAuthor(author, this.props.project);
			} else {
				this.setState({ withAvatar: false });
				toast.error(this.props.t('author_update_failed'));
			}
		} else if (!this.state.withAvatar) {
			await this.props.postAuthor(author, this.props.project);
		}
	};

	toggleSidebarEditMode = () => {
		const newState = toggleSidebarEditMode({ ...this.state }, ContentTypes.AUTHOR);
		this.setState(newState);
	};

	render() {
		const { t } = this.props;
		const { author, openSidebarSettingsModalFlag } = this.state;
		const seoModel = authorToSeoComponentModelForCreate(author);
		const sidebarComponentsMap = {
			seo: <Seo contentType={ContentTypes.AUTHOR} onChange={this.onSeoChange.bind(this)} value={seoModel} />,
		};

		return (
			<div className='animated fadeIn'>
				<Row>
					<Col col='8' lg='8' md='12' sm='12' xs='12'>
						<div className='card'>
							<div className='card-header'>
								<div className={'d-flex align-items-center'}>
									<span className={'mr-auto mb-0'}>{t('author_create_title')}</span>
									<BlockableButtonWrapper
										blockOnActions={[AUTHOR_ENTITY_CREATE]}
										releaseOnActions={[AUTHOR_ENTITY_CREATE_SUCCESS, AUTHOR_ENTITY_CREATE_FAILED]}
									>
										<Button size='sm' color='primary' id='author-create-save-top' className={'ml-auto'} onClick={this.onSubmit.bind(this)}>
											<i className='fa fa-floppy-o'></i> {t('save_author')}
										</Button>
									</BlockableButtonWrapper>
								</div>
							</div>
							<div className='card-body'>
								<Row>
									<Col xs='12'>
										<Label htmlFor='name'>{t('name')}</Label>
										<Name t={t} onChange={this.onNameChange.bind(this)} value={this.state.author.name} />
									</Col>
								</Row>
								<Row>
									<Col xs='12'>
										<Bio t={t} onChange={this.onBioChange.bind(this)} value={this.state.author.bio} />
										<AuthorSocials socialLinks={this.state.author.social_media_links} onChange={this.onSocialChange.bind(this)} />
										<ImageUpload avatar={this.state.author.avatarImage} authorName={this.state.author.name} change={this.onFileChange} />
									</Col>
								</Row>
								<Row>
									<Col xs='12'>
										<IsDefault
											t={t}
											onChange={this.onIsDefaultChange.bind(this)}
											value={this.state.author.isDefault}
											isChecked={this.state.author.isDefault}
										/>
									</Col>
								</Row>

								<Row>
									<Col xs='12'>
										<Active t={t} onChange={this.onActiveChange.bind(this)} value={this.state.author.active} isChecked={this.state.author.active} />
									</Col>
								</Row>
								<Row className={'mt-4'}>
									<Col>
										<BlockableButtonWrapper
											blockOnActions={[AUTHOR_ENTITY_CREATE]}
											releaseOnActions={[AUTHOR_ENTITY_CREATE_SUCCESS, AUTHOR_ENTITY_CREATE_FAILED]}
										>
											<Button
												color={'primary'}
												id='author-create-save-bottom'
												className={'text-uppercase font-weight-bold'}
												onClick={this.onSubmit.bind(this)}
											>
												<i className='fa fa-floppy-o'></i> {t('save_author')}
											</Button>
										</BlockableButtonWrapper>
									</Col>
								</Row>
							</div>
						</div>
					</Col>
					<Col col='4' lg='4' md='12' sm='12' xs='12' className='position-relative'>
						<SidebarElementsToggle t={t} toggleSidebarEditMode={this.toggleSidebarEditMode} isSidebarInEdit={this.state.isSidebarInEdit} />
						<SidebarCollapsibleElements
							isSidebarInEdit={this.state.isSidebarInEdit}
							onElemSort={(settingsValue: FormattedSidebarSectionValue[]) => onSidebarElemSort(settingsValue, ContentTypes.AUTHOR)}
							onChangeCollapseStatus={(data: ContentSidebarChangeCollapseStatus) => onChangeCollapseStatus(data)}
							contentType={ContentTypes.AUTHOR}
							sidebarComponentsMap={sidebarComponentsMap}
							contentMode={ContentMode.CREATE}
							t={t}
						/>
					</Col>
				</Row>
				<GlobalModal
					isOpen={openSidebarSettingsModalFlag}
					t={this.props.t}
					headerContent='save_sidebar_settings'
					bodyContent='unsaved_changes'
					submitFunction={() => saveSidebarSettingsByContentType(ContentTypes.AUTHOR, t)}
					beforeCloseFunction={() =>
						overwriteTempSidebarByContentType(ContentTypes.AUTHOR, defaultTempSidebarFromApiResponse[ContentTypes.AUTHOR].value)
					}
				/>
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		author: state.author,
		project: state.project.currentProject,
		defaultAuthor: state.author.defaultAuthor,
		defaultAuthorSocials: state.author.defaultSocials || [],
		types: state.types,
		profile: state.profile.profile,
	};
}

function mapDispatchToProps(dispatch: any) {
	return {
		postAuthor: (author: Author, project: Project) => dispatch(returnObjectForAuthorEntityCreate(author, project)),
		updateDefaultAUthor: (author: Author) => dispatch(returnObjectForDefaultAuthorEntityUpdate(author)),
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(AuthorCreate) as React.ComponentType;
