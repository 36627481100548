import 'reflect-metadata';
import { AnyT, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import Translation from '../../../views/Partials/Blocky/partials/sports-team-select/models/translation.model';
import ResultModel from '../result/result.model';
import AssetsModel from '../sports-types/assets.model';

@jsonObject
export default class GameModel {
	@jsonMember(String)
	public id: string = '';

	@jsonMember(String)
	public name: string = '';

	@jsonMember(String)
	public sport: string = '';

	@jsonMember(String, { name: 'status_type' })
	public statusType: string = '';

	@jsonMember(String, { name: 'start_time' })
	public startTime: string = '';

	@jsonMember(String, { name: 'competition_id' })
	public competitionId: string = '';

	@jsonMember(String, { name: 'entity_type' })
	public entityType: string = '';

	@jsonMember(AssetsModel, { name: 'display_asset' })
	public displayAsset: AssetsModel = new AssetsModel();

	@jsonMember(AnyT, { deserializer: (value) => value })
	public participants: any;

	@jsonArrayMember(ResultModel)
	public results: ResultModel[] = [];

	@jsonArrayMember(Translation)
	public translations: Translation[] = [];

	@jsonMember(Boolean)
	public isSelected: boolean = false;
}
