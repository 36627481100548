import SportOddsBookmakerModel from '../../../../../../../models/sport-odds/sport-odds-bookmaker.model';
import BasketballTeamProgrammeWidgetBuilder from './basketball-team-programme.builder';
import CompetitionModel from '../../../../../../../models/v2/competition/entity/competition.model';
import BasketballSeasonModel from '../../../../../../../models/v2/basketball-season/entity/basketball-season.model';
import BasketballStageModel from '../../../../../../../models/v2/Stage/Entity/basketball-stage.model';
import BasketballRoundModel from '../../../../../../../models/v2/round/entity/basketball-round.model';
import { BasketballTeamProgrammeWidgetJson } from './basketball-team-programme.json';
import SportTeamModel from '../../../../partials/sports-team-select/models/sport-team.model';
import { RefreshTimeValuesType } from '../../../partials/refresh-time-input/helper';

export default class BasketballTeamProgrammeWidgetModel {
	readonly team: SportTeamModel;
	readonly competition: CompetitionModel;
	readonly season: BasketballSeasonModel;
	readonly stage: BasketballStageModel;
	readonly rounds: BasketballRoundModel[];
	readonly sortDirection: string;
	readonly displayOdds: boolean = false;
	readonly bookmakers: SportOddsBookmakerModel[] | null;
	readonly matchType: any;
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(
		team: SportTeamModel,
		competition: CompetitionModel,
		season: BasketballSeasonModel,
		stage: BasketballStageModel,
		rounds: BasketballRoundModel[],
		sortDirection: string,
		displayOdds: boolean,
		bookmakers: SportOddsBookmakerModel[] | null,
		matchType: string,
		refreshTime: RefreshTimeValuesType,
	) {
		this.team = team;
		this.competition = competition;
		this.season = season;
		this.stage = stage;
		this.rounds = rounds;
		this.sortDirection = sortDirection;
		this.displayOdds = displayOdds;
		this.bookmakers = bookmakers;
		this.matchType = matchType;
		this.refreshTime = refreshTime;
	}

	toJson(): BasketballTeamProgrammeWidgetJson {
		return {
			team: this.team,
			competition: this.competition,
			season: this.season,
			stage: this.stage,
			rounds: this.rounds,
			sortDirection: this.sortDirection,
			displayOdds: this.displayOdds,
			bookmakers: this.bookmakers,
			matchType: this.matchType,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: BasketballTeamProgrammeWidgetJson): BasketballTeamProgrammeWidgetModel {
		return new BasketballTeamProgrammeWidgetModel(
			json.team,
			json.competition,
			json.season,
			json.stage,
			json.rounds,
			json.sortDirection,
			json.displayOdds,
			json.bookmakers,
			json.matchType,
			json.refreshTime,
		);
	}

	static builder(model?: BasketballTeamProgrammeWidgetModel): BasketballTeamProgrammeWidgetBuilder {
		return new BasketballTeamProgrammeWidgetBuilder(model);
	}
}
