import React, { FunctionComponent } from 'react';
import 'flatpickr/dist/themes/material_green.css';
import Flatpickr from 'react-flatpickr';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import './DateTimePickerContainer.scss';
import { dateFormat, langs } from '../../constants/date-time.attributes';

type Properties = {
	t: any;
	language: string;
	date: string;
	onChange: (dates: Date[] | null) => void;
	id: string;
	timezone: string;
};

const DateTimeSelect: FunctionComponent<Properties> = ({ t, language, date, onChange, id, timezone }) => {
	let parsedDate = null;

	if (date) {
		//@ts-ignore
		let timezonedDate = moment.tz(moment(date), dateFormat, timezone);
		parsedDate = new Date(
			timezonedDate.year(),
			timezonedDate.month(),
			timezonedDate.date(),
			timezonedDate.hour(),
			timezonedDate.minute(),
			timezonedDate.second(),
		);
	}

	return (
		<div className='datepicker-container'>
			<Flatpickr
				className='date-picker'
				id={id}
				options={{
					defaultDate: date || '',
					enableTime: true,
					dateFormat,
					time_24hr: true,
					locale: langs[language],
				}}
				placeholder={t('date_mode_format')}
				onChange={onChange}
				value={parsedDate}
			/>
			<div id={`${id}-clear-button`} className='close-button' onClick={() => onChange(null)}>
				<i className='fa fa-times close-icon' />
			</div>
		</div>
	);
};

function mapStateToProps(state: any) {
	return {
		language: state.profile.profile.language || 'en-US',
		timezone: state.project.currentProject.timezone || 'Europe/Sofia',
	};
}
export default compose(connect(mapStateToProps))(DateTimeSelect);
