import React from 'react';

type Properties = {
	errorMessage: string;
	t: any;
};

const ErrorHandler: React.FunctionComponent<Properties> = ({ errorMessage, t }) => {
	return <h6 className={'text-danger'}>{t(errorMessage)}</h6>;
};

export default ErrorHandler;
