import React, { FunctionComponent, useEffect, useState } from 'react';
import LiveBlogModel from '../../models/live-blog.model';
import '../../style/live-blog-listing.style.scss';
import '../../style/live-blog.style.scss';
import { LiveBlogConfigurationDisabledSaveModel } from '../../helpers/utility.helper';
import Loader from '../../../../Partials/Shared/loader/Loader';
import { getLiveBlogById } from '../../helpers/live-blog-main.helper';
import LiveBlogConfigurationContainer from '../configuration/live-blog-configuration.container';

type Properties = {
	t: any;
	history: any;
	location: any;
};

export const LiveBlogEdit: FunctionComponent<Properties> = ({ t, history, location }) => {
	const [disabledSave, setDisabledSave] = useState(new LiveBlogConfigurationDisabledSaveModel());
	const [liveBlog, setLiveBlog] = useState<LiveBlogModel>(new LiveBlogModel());

	useEffect(() => {
		initPageTitle();
		const urlParams = location.pathname.split('/');
		const lastParam = urlParams[urlParams.length - 1];

		getLiveBlogById(lastParam, t('error_loading_live_blogs')).then((data) => {
			if (data) {
				setLiveBlog(data);
			}
		});
	}, []);

	const initPageTitle = () => {
		document.title = t('live_blog_edit');
	};

	if (!liveBlog.id) {
		return (
			<div className='loader-edit-live-blog'>
				<Loader className='loading-overlay-h-auto' />
			</div>
		);
	} else {
		return (
			<>
				<LiveBlogConfigurationContainer
					isNewLiveBlog={false}
					history={history}
					t={t}
					liveBlog={liveBlog}
					setLiveBlog={setLiveBlog}
					disabledSave={disabledSave}
					setDisabledSave={setDisabledSave}
				/>
			</>
		);
	}
};
