import slugify from '@mediaplatform/slugify';
import { emojiRegex, slugRegex } from '../constants/slug.regex';
import { store } from '../../../../../store/store';

export const removeTextEmojis = (text: string = '') => {
	return text ? text.replace(emojiRegex(), '') : '';
};

export const slugifyString = (text: string, removeEmojis: boolean = true) => {
	const reduxStore = store.getState();
	const languageForLocale =
		(reduxStore &&
			reduxStore.project &&
			reduxStore.project.currentProject &&
			reduxStore.project.currentProject.languages &&
			reduxStore.project.currentProject.languages.defaultLanguageCode &&
			reduxStore.project.currentProject.languages.defaultLanguageCode.languageCode) ||
		undefined;

	const textWithoutEmojis = removeEmojis ? removeTextEmojis(text) : text;
	return slugify(textWithoutEmojis, { replacement: '-', remove: slugRegex, lower: true, locale: languageForLocale });
};
