import React from 'react';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import FansUnitedWidgetModel from './models/fans-united-widget.model';

type Properties = {
	widgetLeaderboard: FansUnitedWidgetModel;
	t: any;
};

export const WidgetLeaderboardView: React.FunctionComponent<Properties> = ({ widgetLeaderboard, t }) => {
	if (widgetLeaderboard && widgetLeaderboard.template && widgetLeaderboard.template.id) {
		return (
			<>
				<div data-qa='widget-leaderboard-view'>
					<Row className='d-flex p-2  align-items-center mb-1'>
						<Col className='ml-2'>
							<strong>
								{moment(widgetLeaderboard.template.created_at ? widgetLeaderboard.template.created_at : '').format('MMM Do YYYY, HH:mm')}
							</strong>
						</Col>
						<Col className='mr-1'>
							<strong>{widgetLeaderboard.template.name}</strong>
						</Col>
					</Row>
					<Row className='px-4'>
						<Col>
							<hr />
						</Col>
					</Row>
					<Row className='px-4 pt-1'>
						{widgetLeaderboard.template && widgetLeaderboard.template.description && (
							<Col className='text-left'>
								<span>{t('description')}: </span>
								<strong>{t(widgetLeaderboard.template.description)}</strong>
							</Col>
						)}
					</Row>
					<Row className='px-4 pt-1'>
						{widgetLeaderboard.template && widgetLeaderboard.template.type && (
							<Col className='text-left'>
								<span>{t('type')}: </span>
								<strong>{t(widgetLeaderboard.template.type)}</strong>
							</Col>
						)}
					</Row>
				</div>
			</>
		);
	}

	return null;
};
