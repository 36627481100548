import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import HttpService from '../../../../../../services/rest/HttpService';
import * as helpers from '../helpers/team-select/team-select-component.helper';
import { responseToTeamModel, teamsToOptions, teamToOption } from '../helpers/team-select/team-select-component.helper';
import TeamModel from '../../../../../../models/v2/Team/Entity/TeamModel';
import _ from 'lodash';
import { customOption } from '../../../partials/shared/custom-select-option';
import AsyncSelect from 'react-select/async';
import ErrorHandler from '../error-handler/error-handler';

type Properties = {
	onTeamSelect: any;
	selectedTeam: TeamModel | any;
	t: any;
	isValid: boolean;
	isMulti?: boolean;
	isClearable?: boolean;
	isRequired?: boolean;
	isBlocky: boolean;
	className?: string;
	stageId?: number | null;
	seasonId?: number | null;
	searchByKeyword: boolean;
	id: string;
};

const TeamSelectContainer: React.FunctionComponent<Properties> = (props) => {
	const [teamsOptions, setTeamsOptions] = useState<TeamModel[]>([]);

	useEffect(() => {
		if (!props.searchByKeyword && props.stageId && props.seasonId) {
			getTeams(props.stageId, props.seasonId);
		}
	}, [props.stageId, props.seasonId]);

	const updateTeamsOptionsState = (teams: TeamModel[]) => {
		const { onTeamSelect, selectedTeam, isBlocky, isMulti } = props;

		if (
			(!selectedTeam ||
				(selectedTeam && (selectedTeam.id === undefined || selectedTeam.id.length < 1) && !isMulti) ||
				(isMulti && selectedTeam.length === 0)) &&
			isBlocky
		) {
			onTeamSelect(teams[0]);
		}
		setTeamsOptions(teams);
	};

	const getTeams = (stageId: number | null, seasonId: number | null) => {
		if (stageId) {
			return HttpService.getTournamentStageTeams(stageId)
				.then((response: any) => {
					updateTeamsOptionsState(response.data.map((item: TeamModel) => responseToTeamModel(item)));
				})
				.catch((e: any) => e);
		}

		if (seasonId) {
			return HttpService.getTeamsBySeasonId(seasonId)
				.then((response: any) => {
					updateTeamsOptionsState(response.data.map((item: TeamModel) => responseToTeamModel(item)));
				})
				.catch((e: any) => e);
		}
	};

	const searchTeamsByName = (search: any, callback: any) => {
		HttpService.getTeam(search)
			.then((response: any) => {
				const options = response.data.map((team: TeamModel) => responseToTeamModel(team));
				callback(teamsToOptions(options));
			})
			.catch((e: any) => e);
	};

	const onSelect = (teams: any) => {
		if (props.isMulti) {
			teams && teams.length > 0 ? props.onTeamSelect(teams.map((team: TeamModel) => team.data)) : props.onTeamSelect([]);
		} else {
			teams && teams.data && teams.data.id ? props.onTeamSelect(teams.data) : props.onTeamSelect(null);
		}
	};
	const { isRequired, t, className, onTeamSelect, selectedTeam, searchByKeyword, stageId, isValid, seasonId } = props;
	const teamNotSelected =
		selectedTeam === undefined || selectedTeam === null || (!props.isMulti && !selectedTeam.id) || selectedTeam.length === 0;

	return (
		<Row data-qa={props.id}>
			<Col>
				<Label htmlFor={props.id}>{t('team')}:</Label>
				{isRequired && <span className='text-danger'>*</span>}
				{searchByKeyword ? (
					<AsyncSelect
						id={props.id}
						className='mb-2'
						isClearable={!teamNotSelected}
						noOptionsMessage={(inputValue) => inputValue && t('no_options')}
						isMulti={props.isMulti}
						loadOptions={_.debounce(searchTeamsByName, 500)}
						value={props.isMulti ? helpers.teamsToOptions(props.selectedTeam) : teamToOption(props.selectedTeam)}
						formatOptionLabel={customOption}
						onChange={onSelect}
						placeholder={t('search')}
					/>
				) : (
					<Select
						id={props.id}
						menuPortalTarget={document.body}
						menuPosition='absolute'
						className={className ? className : ''}
						options={stageId || seasonId ? teamsToOptions(teamsOptions) : []}
						value={selectedTeam && selectedTeam.id ? teamToOption(selectedTeam) : []}
						noOptionsMessage={(inputValue) => inputValue && props.t('no_options')}
						isClearable={!teamNotSelected}
						placeholder={t('select')}
						formatOptionLabel={customOption}
						onChange={(selection: any) => {
							selection ? onTeamSelect(selection.data) : onTeamSelect(null);
						}}
					/>
				)}
				{isRequired && <ErrorHandler arePropsValid={isValid} isValueSelected={teamNotSelected} t={t} errorMessage='field_is_required' />}
			</Col>
		</Row>
	);
};

export default TeamSelectContainer as React.ComponentType<Properties>;
