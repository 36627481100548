import { Button, Col, FormGroup, Row } from 'reactstrap';
import React from 'react';
import OnnetworkVideoSelect from '../../../partials/onnetwork-video-select/onnetwork-video-select.component';
import VideoContainer from './video-rows/videos-container';
import Scrollbar from 'react-custom-scrollbars';
import ErrorHandler from '../../../partials/error/error-handler-component';
import OnnetworkVideoModel from '../../../../../../models/onnetwork-videos/onnetwork-video.model';
import BlockModel from '../../../models/block.model';

type Properties = {
	t: any;
	onSelect: (video: OnnetworkVideoModel) => void;
	updatePreviewState?: (text: string, page: number) => void;
	block?: BlockModel;
	selectedVideo: OnnetworkVideoModel;
	isMediaSidebar: boolean;
	blocks: BlockModel[];
	isValid?: boolean;
	inBlocky: boolean;
	linkedTags?: any;
	page?: number;
};

export type State = {
	videos: OnnetworkVideoModel[] | null;
	toggleLoadingState: boolean;
	page: number;
	shouldRequest: boolean;
};

class OnnetworkContainer extends React.Component<Properties, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			videos: null,
			toggleLoadingState: false,
			page: props.page,
			shouldRequest: false,
		};
	}

	setVideos = (videos: OnnetworkVideoModel[] | null, page: number, sarchText: string) => {
		this.setState((state: State) => ({ ...state, videos, page, shouldRequest: false }));
		this.props.updatePreviewState && this.props.updatePreviewState(sarchText, page);
	};

	toggleLoadingState = (loading: boolean) =>
		this.setState((state: State) => ({
			...state,
			toggleLoadingState: loading,
		}));

	onPageUp = () => this.setState({ ...this.state, page: this.state.page + 1, shouldRequest: true });

	onPageDown = () => this.setState({ ...this.state, page: this.state.page - 1, shouldRequest: true });

	render() {
		const { t, onSelect, selectedVideo, isMediaSidebar, blocks, block, linkedTags } = this.props;
		const { videos, toggleLoadingState, page, shouldRequest } = this.state;

		return (
			<div>
				<Row>
					<Col>
						<FormGroup>
							<OnnetworkVideoSelect
								t={t}
								shouldRequest={shouldRequest}
								setVideos={this.setVideos}
								onSelectedVideoChange={onSelect}
								toggleLoadingState={this.toggleLoadingState}
								searchText={block && block.data && block.data.preview && block.data.preview.title}
								blocks={blocks}
								linkedTags={linkedTags}
								page={page}
							/>
						</FormGroup>
					</Col>
				</Row>
				<div className={`${toggleLoadingState ? 'loading-overlay rounded opacity-06' : ''} py-2`}>
					{videos && videos.length > 0 ? (
						<div style={{ height: `${isMediaSidebar ? 400 : ''}px` }} className='pb-2'>
							{isMediaSidebar ? (
								<Scrollbar className='bg-light rounded'>
									<VideoContainer
										t={t}
										onSelect={onSelect}
										isMediaSidebar={isMediaSidebar}
										videos={videos}
										toggleLoadingState={toggleLoadingState}
										selectedVideo={selectedVideo}
									/>
								</Scrollbar>
							) : (
								<div className='bg-white rounded'>
									<VideoContainer
										t={t}
										onSelect={onSelect}
										isMediaSidebar={isMediaSidebar}
										videos={videos}
										toggleLoadingState={toggleLoadingState}
										selectedVideo={selectedVideo}
									/>
								</div>
							)}
						</div>
					) : (
						<div className='text-center'>{t('no_data')}</div>
					)}
					{videos && (
						<div className='m-2 d-flex flex-row justify-content-end'>
							<Button
								id='onnetwork-prev-page'
								size='sm'
								outline
								className='mr-2 d-flex align-items-center'
								color='primary'
								disabled={page === 0}
								onClick={this.onPageDown}
							>
								<i className='fa fa-2x fa-angle-left px-1' />
								{t('previous')}
							</Button>
							<Button
								id='onnetwork-next-page'
								size='sm'
								className='d-flex align-items-center'
								outline
								color='primary'
								disabled={videos.length < 10}
								onClick={this.onPageUp}
							>
								{t('next')}
								<i className='fa fa-2x fa-angle-right px-1' />
							</Button>
						</div>
					)}
				</div>
				{this.props.inBlocky && (
					<div className='p-1'>
						{!this.props.isValid && selectedVideo && selectedVideo.id === undefined && (
							<ErrorHandler t={this.props.t} errorMessage='no_selected_video' />
						)}
					</div>
				)}
			</div>
		);
	}
}

export default OnnetworkContainer;
