import React, { FunctionComponent } from 'react';
import LiveBlogModel from '../../models/live-blog.model';
import '../../style/live-blog-listing.style.scss';
import '../../style/live-blog.style.scss';
import { LiveBlogTitleSelect } from '../subcomponents/main-fields/live-blog-title.component';
import { LiveBlogDescriptionSelect } from '../subcomponents/main-fields/live-blog-description.component';
import {
	liveBlogConfigurationAdditionalCategoriesChange,
	liveBlogConfigurationCategoryChange,
	LiveBlogConfigurationDisabledSaveModel,
	liveBlogConfigurationMainFieldsChange,
	liveBlogConfigurationMainMediaChange,
	liveBlogConfigurationMainMediaTypeChange,
	liveBlogConfigurationMatchHeaderChange,
	liveBlogConfigurationSlugChange,
	liveBlogConfigurationSponsorsChange,
	liveBlogConfigurationStartTimeChange,
	liveBlogConfigurationStatusChange,
	liveBlogConfigurationTypeChange,
	LiveBlogTypes,
} from '../../helpers/utility.helper';
import { Col } from 'reactstrap';
import { LiveBlogSlugSelect } from '../subcomponents/slug/live-blog-slug-select.component';
import { LiveBlogCategorySelect } from '../subcomponents/categories/live-blog-category-select.component';
import { LiveBlogAdditionalCategoriesSelect } from '../subcomponents/categories/live-blog-additional-categories-select.component';
import { LiveBlogStartTimeSelect } from '../subcomponents/start-time/start-time-select.component';
import { LiveBlogMainMediaComponent } from '../subcomponents/live-blog-main-media/live-blog-main-media.component';
import CategoryModel from '../../../../../models/v2/category/category.model';
import { LiveBlogStatusSelect } from '../subcomponents/status/live-blog-status-select.component';
import { LiveBlogTypeSelect } from '../subcomponents/live-blog-type-select/live-blog-type-select.component';
import MainMediaModel from '../../../../../models/v2/main-media/main-media.model';
import { LiveBlogMatchHeaderSelect } from '../subcomponents/match-header-toggle/match-header-toggle.component';
import { LiveBlogSponsorsSelect } from '../subcomponents/sponsors/live-blog-sponsors.select.component';
import OddClientBookmakersModel from '../../../../Partials/Blocky/partials/available-bookmakers-select/models/odd-client-bookmakers-full.model';

type Properties = {
	t: any;
	liveBlog: LiveBlogModel;
	setLiveBlog: any;
	disabledSave: LiveBlogConfigurationDisabledSaveModel;
	setDisabledSave: any;
};

export const LiveBlogMainConfigurationComponent: FunctionComponent<Properties> = ({
	t,
	liveBlog,
	setLiveBlog,
	disabledSave,
	setDisabledSave,
}) => {
	return (
		<>
			<Col xs='6'>
				<LiveBlogTitleSelect
					t={t}
					liveBlog={liveBlog}
					setLiveBlogTitle={(el: any) => liveBlogConfigurationMainFieldsChange(el.target, liveBlog, setLiveBlog, disabledSave, setDisabledSave)}
				/>
				<LiveBlogDescriptionSelect
					t={t}
					liveBlog={liveBlog}
					setLiveBlogDescription={(el: any) =>
						liveBlogConfigurationMainFieldsChange(el.target, liveBlog, setLiveBlog, disabledSave, setDisabledSave)
					}
				/>
				<LiveBlogSlugSelect
					t={t}
					liveBlog={liveBlog}
					setLiveBlogSlug={(el: any) => liveBlogConfigurationSlugChange(el.target, liveBlog, setLiveBlog)}
				/>
				<LiveBlogStartTimeSelect
					t={t}
					liveBlog={liveBlog}
					setLiveBlogStartTime={(date: Date) => liveBlogConfigurationStartTimeChange(date, liveBlog, setLiveBlog, disabledSave, setDisabledSave)}
				/>
				<LiveBlogCategorySelect
					t={t}
					liveBlog={liveBlog}
					setLiveBlogCategory={(category: CategoryModel) =>
						liveBlogConfigurationCategoryChange(category, liveBlog, setLiveBlog, disabledSave, setDisabledSave)
					}
				/>
				<LiveBlogAdditionalCategoriesSelect
					t={t}
					liveBlog={liveBlog}
					setLiveBlogAdditionalCategories={(categories: CategoryModel[]) =>
						liveBlogConfigurationAdditionalCategoriesChange(categories, liveBlog, setLiveBlog)
					}
				/>
				<LiveBlogSponsorsSelect
					t={t}
					liveBlog={liveBlog}
					setLiveBlogSponsors={(sponsors: OddClientBookmakersModel[]) => liveBlogConfigurationSponsorsChange(sponsors, liveBlog, setLiveBlog)}
				/>
			</Col>
			<Col xs='6'>
				<LiveBlogTypeSelect
					t={t}
					liveBlog={liveBlog}
					setLiveBlogType={(type: string) => liveBlogConfigurationTypeChange(type, liveBlog, setLiveBlog)}
				/>
				{liveBlog.type === LiveBlogTypes.MATCH && (
					<LiveBlogMatchHeaderSelect
						t={t}
						liveBlog={liveBlog}
						setLiveBlogMatchHeader={(toggle: boolean) => liveBlogConfigurationMatchHeaderChange(toggle, liveBlog, setLiveBlog)}
					/>
				)}
				<LiveBlogStatusSelect
					t={t}
					liveBlog={liveBlog}
					setLiveBlogStatus={(status: string) => liveBlogConfigurationStatusChange(status, liveBlog, setLiveBlog)}
				/>
				<LiveBlogMainMediaComponent
					t={t}
					liveBlog={liveBlog}
					setLiveBlogMainMediaType={(type: string) => liveBlogConfigurationMainMediaTypeChange(type, liveBlog, setLiveBlog)}
					setLiveBlogMainMedia={(media: MainMediaModel[]) =>
						liveBlogConfigurationMainMediaChange(media, liveBlog, setLiveBlog, disabledSave, setDisabledSave)
					}
				/>
			</Col>
		</>
	);
};
