import React from 'react';
import Dropzone from 'dropzone';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import './image-upload-container.scss';
import HttpService from '../../../../../services/rest/HttpService';
import { Properties, State } from './properties/image-upload.properties';
import * as helpers from '../../../../Partials/Shared/image-upload/helpers/image-upload-button.helper';
import { extractSportsRelatedData, isSingleFileUplaod, normalizeResponse } from './helpers/image-upload.helper';

class ImageUploadContainer extends React.Component<Properties, State> {
	private dropzone: Dropzone = {} as Dropzone;

	constructor(props: Properties) {
		super(props);

		this.state = {
			isFileUploading: false,
			displayError: false,
			errorText: '',
			uploadStep: 3,
			imageRelated: [],
			imageSportsRelated: [],
		};
	}

	componentDidMount() {
		if (this.props.display) {
			this.initDropzone();
		}
	}

	initDropzone() {
		this.dropzone = new Dropzone(`#${this.props.imageUploadButtonId}`, {
			url: this.props.currentProject.imageApiUrl + '/upload',
			method: 'post',
			dictDefaultMessage: this.props.t('drop_file'),
			maxFilesize: 15,
			maxFiles: isSingleFileUplaod(this.props.isSingleUpload) ? 1 : 70,
			maxThumbnailFilesize: 16,
			createImageThumbnails: true,
			timeout: 300000,
			parallelUploads: isSingleFileUplaod(this.props.isSingleUpload) ? 1 : 10,
			headers: { Project: this.props.currentProject.domain },
			acceptedFiles: 'image/*',
			accept: (file: any, done: Function) => {
				file.accept = done();
			},
			dictFileTooBig: 'File is too big',
		});

		this.initDropzoneListeners();
	}

	initDropzoneListeners() {
		let imagePaths: string[] = [];

		this.dropzone.on('addedfile', () => {
			this.toggleUploadingState(true, 0);
		});

		this.dropzone.on('error', (file: any, error: any) => {
			try {
				const errorDisplayNodesArray = document.querySelectorAll('[data-dz-errormessage]');

				const overwriteElementText = () => {
					const index = errorDisplayNodesArray.length - 1;
					const latestErrorDisplaySpan = errorDisplayNodesArray[index];

					latestErrorDisplaySpan.innerHTML = error.message ? error.message : error;
				};

				overwriteElementText();

				this.toggleUploadingState(false, 3);
				helpers.handleErrors(error.message ? error.message : error, this.props.t);
			} catch (e) {
				console.log(e);
			}
		});

		this.dropzone.on('success', (response: any) => {
			const imagePath = response && response.xhr && response.xhr.response ? JSON.parse(response.xhr.response).path : '';
			imagePaths.push(imagePath);
		});

		this.dropzone.on('queuecomplete', () => {
			this.uploadToContentApi(imagePaths);
			imagePaths = [];
		});

		this.dropzone.on('thumbnail', (file: any) => {
			if (file.width * file.height > helpers.MAX_DIMENS_SIZE && typeof file.reject === 'function') {
				file.reject();
			} else if (typeof file.accept === 'function') {
				file.accept();
			}
		});
	}

	uploadToContentApi(imagePaths: string[]) {
		if (imagePaths.length > 0) {
			this.toggleUploadingState(true, 1);
			const headers = { Project: this.props.currentProject.domain };
			HttpService.all(helpers.constructRequestsFromFilePaths(imagePaths, headers))
				.then((response: any) => {
					if (this.props.copySportsRelated) {
						this.toggleUploadingState(true, 2);
						this.addRelatedContentToImagesRequest(normalizeResponse(response), headers);
					} else {
						this.toggleUploadingState(false, 3);
					}

					if (isSingleFileUplaod(this.props.isSingleUpload)) {
						this.props.onImageUploadSuccess(normalizeResponse(response)[0]);
					} else {
						this.props.onMultiImageUploadSuccess(normalizeResponse(response));
					}
				})
				.catch(() => {
					this.toggleUploadingState(false, 3);
					helpers.handleErrors(helpers.FILE_UPLOAD_FAILED_CONTENT_API, this.props.t);
				});
		}
	}

	addRelatedContentToImagesRequest = (images: string[], headers: any) => {
		const sportsRelated = extractSportsRelatedData(this.props.sportsRelatedContent);

		if (images.length > 0 && sportsRelated.length > 0) {
			HttpService.all(helpers.constructRequestsSportsRelatedImages(images, sportsRelated, headers))
				.then(() => this.toggleUploadingState(false, 3))
				.catch(() => {
					this.toggleUploadingState(false, 3);
					helpers.handleErrors(helpers.FILE_UPLOAD_FAILED_CONTENT_API, this.props.t);
				});
		} else {
			this.toggleUploadingState(false, 3);
			helpers.handleErrors(helpers.IMAGE_RELATED_CONTENT_MISSING, this.props.t);
		}
	};

	toggleUploadingState = (isUploading: boolean, step: number) => {
		this.setState({ ...this.state, isFileUploading: isUploading, uploadStep: step });

		if (this.props.onImageProcessing) {
			this.props.onImageProcessing(isUploading);
		}
	};

	toggleErrorState = (error: string, display: boolean) => {
		this.setState({ ...this.state, errorText: error, displayError: display });
	};

	render() {
		const { imageUploadButtonId, display } = this.props;
		const { errorText, uploadStep, isFileUploading } = this.state;
		const uploadStepKey = helpers.UPLOAD_STEPS[uploadStep];

		if (!display) {
			return null;
		}

		return (
			<div className='image-processing-container'>
				<div className={helpers.UPLOAD_STEP_STYLE[uploadStepKey]}>
					<Row className='row-loader'>
						<Col className='text-center mb-2'>
							<i className={helpers.appendUploadingClass(isFileUploading)} />
						</Col>
					</Row>
					<Row className='row-loader-text'>
						<Col className='text-center'>
							<p>{this.props.t(helpers.UPLOAD_STEP_TEXT[uploadStepKey])}</p>
						</Col>
					</Row>
				</div>
				<div id={imageUploadButtonId} className='dropzone blocky-dropzone-custom-class' />
				{errorText && (
					<div className='alert alert-warning' role='alert'>
						{errorText}
					</div>
				)}
			</div>
		);
	}
}

function mapStateToProps(state: any) {
	return {
		currentProject: state.project.currentProject,
		lang: state.profile.profile.language,
	};
}

export default connect(mapStateToProps)(ImageUploadContainer) as React.ComponentType<any>;
