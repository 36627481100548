import { assetsPlaceholder } from '../../../../../../../constants/assetsPlaceholder';

export const getEntityMedia = (tag: any) => {
	if (tag && tag.display_asset && tag.display_asset.url) {
		return tag.display_asset.url;
	}

	if (assetsPlaceholder[tag.entity_type]) {
		return assetsPlaceholder[tag.entity_type];
	}

	return null;
};

export const remapAdditionalTags = (tag: any, tagParent: any) => {
	if (tag) {
		return { ...tag, parent: tagParent.id, subtype: tag.entity_type, text: tagParent.text };
	}

	return {};
};
