import React from 'react';
import Select from 'react-select';
import HttpService from '../../../../../services/rest/HttpService';
import { Label } from 'reactstrap';
import BookmakerModel from './models/bookmaker.model';
import { Properties, State } from './properties/bookmaker-select.properties';
import ErrorHandler from '../error/error-handler-component';
import { customOption } from '../shared/custom-select-option';
import * as helpers from './helpers/bookmakers-select.helper';

class BookmakerSelect extends React.Component<Properties, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			bookmakerList: [],
		};
	}

	componentDidMount() {
		if (this.props.eventId && !this.props.forFansUnited) {
			const shouldPreselectSingleSelect = (this.props.value === null || this.props.value) && !this.props.matchContentData ? false : true;
			const preselectBookmaker = this.props.valueList ? false : shouldPreselectSingleSelect;

			this.getOddsByMatchId(this.props.eventId, preselectBookmaker);
		}

		if (this.props.showAllBookmakers && !this.props.forFansUnited) {
			const preselectBookmaker = this.props.value ? false : true;

			this.getAllBookmakers(preselectBookmaker);
		}

		if (this.props.forFansUnited) {
			const preselectBookmaker = this.props.value.length > 0 ? false : true;

			this.getOddsWithoutModelRemapping(this.props.eventId, preselectBookmaker);
		}
	}

	componentDidUpdate(prevProps: any) {
		if (this.props.eventId !== prevProps.eventId && !this.props.forFansUnited) {
			this.resetSelectedBookmakerState();
			this.getOddsByMatchId(this.props.eventId, true);
		} else if (this.props.eventId !== prevProps.eventId && this.props.forFansUnited) {
			this.getOddsWithoutModelRemapping(this.props.eventId, true);
		}
	}

	resetSelectedBookmakerState = () => this.props.onSelect([]);

	getOddsByMatchId = (eventId: any, preselectBookmaker: boolean) => {
		if (eventId) {
			HttpService.getEventOdds(eventId)
				.then((response: any) => {
					if (response) {
						const bookmakerList = response.data.map((bookmaker: any) => {
							return BookmakerModel.builder()
								.withId(bookmaker.odd_provider.id)
								.withLogo(bookmaker.odd_provider.url_logo)
								.withName(bookmaker.odd_provider.name)
								.withOverUnder(bookmaker)
								.build();
						});
						preselectBookmaker && this.preselectBookmakers(this.props.isMulti ? bookmakerList : bookmakerList[0]);
						this.updateBookmakerListState(bookmakerList);
					}
				})
				.catch((e: any) => e);
		}
	};

	getOddsWithoutModelRemapping = (eventId: any, preselectBookmaker: boolean) => {
		if (eventId) {
			HttpService.getEventOdds(eventId)
				.then((response: any) => {
					if (response) {
						const bookmakerList = response.data.map((bookmaker: any) => {
							return {
								id: bookmaker.odd_provider.id,
								logo: bookmaker.odd_provider.url_logo,
								name: bookmaker.odd_provider.name,
								...bookmaker,
							};
						});
						preselectBookmaker && this.preselectBookmakers(this.props.isMulti ? bookmakerList : bookmakerList[0]);
						this.updateBookmakerListState(bookmakerList);
					}
				})
				.catch((e: any) => e);
		}
	};

	getAllBookmakers = (preselectBookmaker: boolean) => {
		HttpService.getAllBookmakers()
			.then((response: any) => {
				if (response) {
					const bookmakerList = response.data.map((bookmaker: any) => {
						return BookmakerModel.builder().withId(bookmaker.id).withLogo(bookmaker.url_logo).withName(bookmaker.name).build();
					});
					preselectBookmaker && this.preselectBookmakers(this.props.isMulti ? bookmakerList : bookmakerList[0]);
					this.updateBookmakerListState(bookmakerList);
				}
			})
			.catch((e: any) => e);
	};

	preselectBookmakers = (bookmakerList: BookmakerModel[]) => {
		this.props.onSelect(bookmakerList);
	};

	updateBookmakerListState = (bookmakerList: BookmakerModel[]) => {
		this.setState({ ...this.state, bookmakerList });
	};

	onSingleSelectChange = (selection: any) => {
		this.props.onSelect(selection && selection.data ? selection.data : null);
	};

	onMultiSelectChange = (selections: any) => {
		this.props.onSelect(helpers.optionListToBookmakerList(selections));
	};

	noOptions = (inputValue: any) => inputValue && this.props.t('no_options');

	render() {
		const { eventId, isMulti, value, valueList, bookmakerSelectionEnabled } = this.props;
		if (!eventId && !bookmakerSelectionEnabled) {
			return null;
		}

		if (isMulti) {
			return (
				<>
					<Label>{this.props.t('select_bookmaker')}:</Label>
					<Select
						options={helpers.bookmakerListToOptionList(this.state.bookmakerList)}
						isMulti
						className='mb-2'
						placeholder={this.props.t('select')}
						noOptionsMessage={this.noOptions}
						value={helpers.bookmakerListToOptionList(valueList)}
						formatOptionLabel={customOption}
						onChange={this.onMultiSelectChange}
					/>
					{!this.props.isValid && (!valueList || (valueList && valueList.length === 0)) && (
						<ErrorHandler t={this.props.t} errorMessage='field_is_required' />
					)}
				</>
			);
		}

		return (
			<>
				<Label>{this.props.t('select_main_bookmaker')}:</Label>
				<Select
					isClearable
					className='mb-2'
					options={helpers.bookmakerListToOptionList(this.state.bookmakerList)}
					value={value ? helpers.bookmakerToOption(value) : []}
					placeholder={this.props.t('select')}
					formatOptionLabel={customOption}
					noOptionsMessage={this.noOptions}
					onChange={this.onSingleSelectChange}
				/>
			</>
		);
	}
}

export default BookmakerSelect;
