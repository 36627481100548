import { EventStatusesTypes, MatchStatusesTypes } from '../../../../../constants/event.types';
import { SportTypes, SportTypesValue } from '../../../../../constants/sport.types';
import EventsMeta from '../../../../../models/v2/events-meta/events-meta.model';
import { modelMatchToResponseModel } from '../../../../../models/v2/match/entity/response-match.mapper';
import SeasonResponseModel from '../../../../../models/v2/Season/Entity/response-season.model';
import EventModel from '../../../../../models/v2/sports-types/eventModel';
import TournamentResponseModel from '../../../../../models/v2/Tournament/Entity/response-tournament.model';
import HttpService from '../../../../../services/rest/HttpService';
import SportsConnectionsHttpService from '../../../Shared/advanced-content-filter/subcomponents/v2/services/sports-connections-http.service';
import { responseToEventModel } from '../../../Shared/advanced-content-filter/subcomponents/v2/sports-select/sports-connections.helper';
import { extractDataForFetchingEvents, extractDataForFetchingFootballMatches, extractStatusesTypesBasedOnActiveTab } from './events.helper';
import SportsTypesModel from '../../../../../models/v2/sports-types/sports-types.model';

export const loadMoreMatches = (
	activeTab: EventStatusesTypes,
	tournamentFilter: TournamentResponseModel | null,
	seasonFilter: SeasonResponseModel | null,
	dateFromFilter: string,
	dateToFilter: string,
	participantsIds: string[],
	entitiesLimit: number,
	setLoadedMatches: Function,
	setEntitiesLimit: Function,
	setLoading: Function,
	selectedSport: SportsTypesModel,
) => {
	const newLimit = entitiesLimit + 10;
	const dataForRequest = extractDataForFetchingFootballMatches(
		extractStatusesTypesBasedOnActiveTab(selectedSport, activeTab) as MatchStatusesTypes[],
		tournamentFilter,
		seasonFilter,
		dateFromFilter,
		dateToFilter,
		participantsIds,
		newLimit,
	);

	if (participantsIds.length > 0) {
		HttpService.instanceFootball()
			.get(dataForRequest)
			.then((response: any) => {
				const responseData: Array<any> | null = (response && response.data) || null;
				const remappedData = responseData && responseData.map((entity: any) => modelMatchToResponseModel(entity));
				setLoadedMatches(remappedData);
				setEntitiesLimit(newLimit);
				setLoading(false);
			})
			.catch(() => setLoading(false));
	} else {
		setEntitiesLimit(0);
		setLoadedMatches(null);
		setLoading(false);
	}
};

export const loadMoreEvents = (
	selectedSport: SportsTypesModel,
	activeTab: EventStatusesTypes,
	dateFromFilter: string,
	dateToFilter: string,
	participantsIds: string[],
	eventsMeta: EventsMeta,
	setLoadedEvents: Function,
	setEventsMeta: Function,
	setLoading: Function,
) => {
	if (selectedSport && activeTab && participantsIds && participantsIds.length > 0) {
		const dataForRequest = extractDataForFetchingEvents(
			selectedSport,
			extractStatusesTypesBasedOnActiveTab(selectedSport, activeTab) as EventStatusesTypes[],
			dateFromFilter,
			dateToFilter,
			participantsIds,
			eventsMeta,
		);

		SportsConnectionsHttpService.getGamesData(dataForRequest)
			.then((response) => {
				const responseData = response.data.results;
				try {
					setEventsMeta({
						total: +response.data.meta.total,
						limit: +response.data.meta.limit,
					});
				} catch (e) {}
				const remappedData: EventModel[] = responseData.map((entity: any) => responseToEventModel(entity));
				setLoadedEvents(remappedData);
				setLoading(false);
			})
			.catch(() => setLoading(false));
	} else {
		setLoadedEvents(null);
		setEventsMeta(new EventsMeta());
		setLoading(false);
	}
};
