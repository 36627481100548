import React from 'react';
import { Col, Row } from 'reactstrap';
import FansUnitedWidgetModel from './models/fans-united-widget.model';
import moment from 'moment';
import BookmakerModel from '../../../partials/bookmaker-select/models/bookmaker.model';
import { imagePlaceHolder } from '../../../../../../constants/resource-constants';

type Properties = {
	widgetCorrectScore: FansUnitedWidgetModel;
	t: any;
	bookmakers: [];
};

export const WidgetCorrectScoreView: React.FunctionComponent<Properties> = ({ widgetCorrectScore, t, bookmakers }) => {
	if (widgetCorrectScore && widgetCorrectScore.match && widgetCorrectScore.match.id) {
		return (
			<>
				<Row className='d-flex p-2 text-center align-items-center mb-1'>
					<Col>
						<strong>{moment(widgetCorrectScore.match.startTime ? widgetCorrectScore.match.startTime : '').format('MMM Do YYYY, HH:mm')}</strong>
					</Col>
					<Col className='text-right mr-1'>
						<strong className='mr-2'>
							{widgetCorrectScore.match && widgetCorrectScore.match.homeTeam && widgetCorrectScore.match.homeTeam.name}
						</strong>
						<img
							width='40'
							src={widgetCorrectScore.match && widgetCorrectScore.match.homeTeam && widgetCorrectScore.match.homeTeam.logo}
							alt={widgetCorrectScore.match && widgetCorrectScore.match.homeTeam && widgetCorrectScore.match.homeTeam.name}
						/>
					</Col>
					<strong className='text-nowrap'>:</strong>
					<Col className='text-left'>
						<img
							width='40'
							src={widgetCorrectScore.match && widgetCorrectScore.match.awayTeam && widgetCorrectScore.match.awayTeam.logo}
							alt={widgetCorrectScore.match && widgetCorrectScore.match.awayTeam && widgetCorrectScore.match.awayTeam.logo}
						/>
						<strong className='ml-2'>
							{widgetCorrectScore.match && widgetCorrectScore.match.awayTeam && widgetCorrectScore.match.awayTeam.name}
						</strong>
					</Col>
				</Row>
				<Row className='px-4'>
					<Col>
						<hr />
					</Col>
				</Row>
				{bookmakers && bookmakers.length > 0 && widgetCorrectScore.displayOdds && (
					<Row className='mb-2 px-4 pt-1'>
						<Col>
							{t('show')}:
							{bookmakers.map((bookmaker: BookmakerModel) => {
								return (
									<img
										key={bookmaker.id}
										height='20'
										alt={bookmaker.name}
										className='h-20 mx-1'
										src={bookmaker.logo ? bookmaker.logo : imagePlaceHolder}
										title={bookmaker.name}
									/>
								);
							})}
						</Col>
					</Row>
				)}
				<Row className='px-4 pt-1'>
					{widgetCorrectScore.market && widgetCorrectScore.market.value && widgetCorrectScore.market.value.length > 0 && (
						<Col className='text-left'>
							<span>{t('market')}: </span>
							<strong>{t(widgetCorrectScore.market.value)}</strong>
						</Col>
					)}
				</Row>
				<Row className='px-4 pt-1'>
					<Col className='text-left'>
						{t('display_odds')}:
						<strong>
							<span className={widgetCorrectScore.displayOdds ? 'text-success' : 'text-danger'}>
								{widgetCorrectScore.displayOdds ? ` ${t('yes')}` : ` ${t('no')}`}
							</span>
						</strong>
					</Col>
				</Row>
			</>
		);
	}

	return null;
};
