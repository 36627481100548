import { StandingsWidgetV2Json } from './standings-widget-v2.json';
import TournamentModel from '../../../../../../../models/v2/Tournament/Entity/TournamentModel';
import StageModel from '../../../../../../../models/v2/Stage/Entity/StageModel';
import GroupModel from '../../../../../../../models/v2/Group/entity/group.model';
import SeasonModel from '../../../../../../../models/v2/Season/Entity/SeasonModel';
import StandingsWidgetV2Builder from './standings-widget-v2.builder';
import { RefreshTimeValuesType } from '../../refresh-time-input/helper';

export default class StandingsWidgetV2Model {
	readonly tournament: TournamentModel;
	readonly season: SeasonModel;
	readonly stage: StageModel;
	readonly group: GroupModel[];
	readonly refreshTime: RefreshTimeValuesType;

	private constructor(
		tournament: TournamentModel,
		season: SeasonModel,
		stage: StageModel,
		group: GroupModel[],
		refreshTime: RefreshTimeValuesType,
	) {
		this.tournament = tournament;
		this.season = season;
		this.stage = stage;
		this.group = group;
		this.refreshTime = refreshTime;
	}

	toJson(): StandingsWidgetV2Json {
		return {
			tournament: this.tournament,
			season: this.season,
			stage: this.stage,
			group: this.group,
			refreshTime: this.refreshTime,
		};
	}

	static fromJson(json: StandingsWidgetV2Json): StandingsWidgetV2Model {
		return new StandingsWidgetV2Model(json.tournament, json.season, json.stage, json.group, json.refreshTime);
	}

	static builder(standingsWidget?: StandingsWidgetV2Model): StandingsWidgetV2Builder {
		return new StandingsWidgetV2Builder(standingsWidget);
	}
}
