import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import ErrorHandler from '../error-handler/error-handler';
import BasketballRoundModel from '../../../../../../models/v2/round/entity/basketball-round.model';
import { basketballRoundsToOptions } from '../helpers/round-select/basketball-round-select-component.helper';
import { SelectMenuOptionType } from '../../../../../../models/v2/general/select.model';

type Properties = {
	onRoundSelect: (rounds: BasketballRoundModel[]) => any;
	selectedRounds: BasketballRoundModel[];
	t: any;
	isValid: boolean;
	isMulti?: boolean;
	isClearable?: boolean;
	isRequired?: boolean;
	isBlocky: boolean;
	className?: string;
	preselectRound?: boolean;
	id: string;
	stageRounds?: BasketballRoundModel[];
};

const BasketballRoundSelectComponent: React.FunctionComponent<Properties> = (props) => {
	const [roundsOptions, setRoundsOptions] = useState<BasketballRoundModel[]>([]);

	const {
		isRequired,
		t,
		className,
		isMulti,
		selectedRounds,
		isClearable,
		isValid,
		stageRounds,
		onRoundSelect,
		preselectRound,
		isBlocky,
	} = props;

	useEffect(() => {
		if (stageRounds && stageRounds.length > 0) {
			updateRoundsOptionsState(stageRounds);
		}
	}, [stageRounds]);

	const updateRoundsOptionsState = (rounds: BasketballRoundModel[]) => {
		if (preselectRound && (!selectedRounds || selectedRounds.length === 0) && isBlocky) {
			onRoundSelect([rounds[0]]);
		}
		setRoundsOptions(rounds);
	};

	const onSelect = (rounds: SelectMenuOptionType[]) => {
		if (rounds && rounds.length > 0) {
			props.onRoundSelect(rounds.map((round: SelectMenuOptionType) => round.data));
		} else props.onRoundSelect([] as BasketballRoundModel[]);
	};

	const roundsSelected = props.selectedRounds === null || (props.selectedRounds && props.selectedRounds.length === 0);

	return (
		<Row data-qa={props.id}>
			<Col>
				<Label htmlFor={props.id}>{t('round')}:</Label>
				{isRequired && <span className='text-danger'>*</span>}
				<Select
					id={props.id}
					isMulti={isMulti}
					menuPortalTarget={document.body}
					menuPosition='absolute'
					className={className ? className : ''}
					options={basketballRoundsToOptions(roundsOptions)}
					value={basketballRoundsToOptions(selectedRounds)}
					noOptionsMessage={(inputValue) => inputValue && props.t('no_options')}
					isClearable={isClearable}
					placeholder={t('select')}
					onChange={onSelect}
				/>

				{isRequired && <ErrorHandler t={t} arePropsValid={isValid} isValueSelected={roundsSelected} errorMessage='field_is_required' />}
			</Col>
		</Row>
	);
};

export default BasketballRoundSelectComponent as React.ComponentType<Properties>;
