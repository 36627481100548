import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { blockManagementService, blockWrapperService } from '../../../../subcomponents/blocky.component';
import BlockValidation from '../../../../helpers/block-validation.helper';
import BlockModel from '../../../../models/block.model';
import StageModel from '../../../../../../../models/v2/Stage/Entity/StageModel';
import FootballPlayerWidgetModel from '../models/football-player-widget.model';
import PlayerModel from '../../../../../../../models/v2/player/entity/player.model';
import PlayerSelectComponent from '../../../partials/player-select/player-select.component';
import PlayerSeasonSelectComponent from '../../../partials/player-select/player-season-select.component';
import EntitySeasonsSelectModel from '../../../../partials/player-seasons/model/entity-seasons-select.model';
import StageSelectComponent from '../../../partials/stage-select/stage-select.component';
import MatchByStageSelectComponent from '../../../partials/match-select/match-by-stage-select.component';
import MatchModel from '../../../../../../../models/v2/match/entity/match.model';
import { ContentTypes } from '../../../../../../../constants/content-types';
import { SportTypes } from '../../../../../../../constants/sport.types';
import { SPORT_SELECT_ATTRIBUTES } from '../../helpers/component-attributes.constants';
import OddsModelV2 from '../../odds/models/odds-widget.model';
import { featuresService } from '../../../../../../../App';
import { FeatureTypes } from '../../../../../../../services/feature-service/features.enum';
import GenericSelect from '../../../../partials/generic-select/generic-select-component';
import {
	playerProfileInfoTabOptions,
	playerProfileStatsTabOptions,
	WidgetPlayerTabs,
	WidgetTeamDefaultTabs,
	WidgetTeamDefaultTabsWithMatches,
} from '../../football-team/constants/football-team.constants';
import { getDefaultType } from '../../../../blocks/content/embed-block/helpers/embed-block-edit.helper';
import { OddsEditComponent } from '../../odds/components/odds-edit.component';
import { createBlock } from '../../../partials/helpers/blocky-sidebar-communication/blocky-sidebar-communication.helper';
import { WidgetsV2Ids } from '../../../../constants/block.types';
import FansUnitedEnabledComponent from '../../../partials/fans-united/fans-united-enabled.component';
import { ElementsSelect } from '../../../partials/elements-select/widget-elements-select';
import { SelectMenuOptionType } from '../../../../../../../models/v2/general/select.model';
import RefreshTimeWrapper from '../../../partials/refresh-time-input/refresh-time-wrapper';
import { onRefreshTimeChangeFromMainComponent } from '../../../partials/refresh-time-input/helper';
import { MatchStatuses } from '../../../../../../../constants/event.types';

type Properties = {
	block: BlockModel;
	t: any;
	contentData: any;
	contentLanguage: string;
};

type State = {
	widgetModel: FootballPlayerWidgetModel;
	oddsModel: OddsModelV2;
	isValid: boolean;
};

export default class FootballPlayerEditComponent extends React.Component<Properties, State> {
	constructor(props: Properties) {
		super(props);
		this.state = {
			widgetModel: createBlock(
				WidgetsV2Ids.widgetFootballPlayer,
				props.block.data.preview && props.block.data.preview.widgetModel,
				props.contentData,
			),
			oddsModel:
				props.block && props.block.data.preview && props.block.data.preview.odds
					? OddsModelV2.builder(props.block.data.preview.odds).build()
					: OddsModelV2.builder().withMainBookmaker(null).build(),
			isValid: false,
		};
	}

	componentDidMount(): void {
		blockWrapperService.registerOnSaveFunc({ blockId: this.props.block.id, func: this.onSave });
	}

	componentDidUpdate(prevProps: Readonly<Properties>, prevState: Readonly<State>, snapshot?: any) {
		if (
			this.state.widgetModel.displayOdds !== prevState.widgetModel.displayOdds &&
			!this.state.widgetModel.displayOdds &&
			this.state.widgetModel.player &&
			!this.state.widgetModel.player.id &&
			this.state.widgetModel.match !== prevState.widgetModel.match
		) {
			const emptyOddsModel = OddsModelV2.builder().build();

			this.setState({ ...this.state, oddsModel: emptyOddsModel });
		}
	}

	onSave = (index: number) => {
		const validation = new BlockValidation();
		const data = { widgetModel: this.state.widgetModel, oddsModel: this.state.oddsModel };
		const isValid = validation.validate(this.props.block, data) === true;

		this.setState({ ...this.state, isValid });

		const bookmakerSelectionEnabled = featuresService && featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const odds = OddsModelV2.builder(this.state.oddsModel)
			.withMainBookmaker(bookmakerSelectionEnabled ? this.state.oddsModel.mainBookmaker : null)
			.build();

		if (isValid) {
			const block = Object.assign({}, this.props.block);
			block.data.preview = { widgetModel: this.state.widgetModel, odds };
			blockManagementService.blockUpdate(block, index);
		} else {
			return isValid;
		}
	};

	componentWillUnmount(): void {
		blockWrapperService.unregisterOnSaveFunc(this.props.block.id);
	}

	updateWidgetModelState = (model: FootballPlayerWidgetModel) => {
		this.setState({ ...this.state, widgetModel: model });
	};

	onPlayerSelect = (player: PlayerModel) => {
		this.updateWidgetModelState(
			FootballPlayerWidgetModel.builder(this.state.widgetModel)
				.withPlayer(player)
				.withTournamentSeason({} as EntitySeasonsSelectModel)
				.withStage({} as StageModel)
				.withMatch({} as MatchModel)
				.withPlayerTeamName('')
				.build(),
		);
	};

	onSeasonSelect = (season: EntitySeasonsSelectModel | null) => {
		this.updateWidgetModelState(
			FootballPlayerWidgetModel.builder(this.state.widgetModel)
				.withTournamentSeason(season ? season : ({} as EntitySeasonsSelectModel))
				.withStage({} as StageModel)
				.withMatch({} as MatchModel)
				.withPlayerTeamName(this.state.widgetModel.playerTeamName)
				.build(),
		);
	};

	onStageSelect = (stage: StageModel) => {
		this.updateWidgetModelState(
			FootballPlayerWidgetModel.builder(this.state.widgetModel)
				.withPlayer(this.state.widgetModel.player)
				.withStage(stage)
				.withMatch({} as MatchModel)
				.build(),
		);
	};

	onDisplayTabsChange = (event: any) => {
		this.updateWidgetModelState(FootballPlayerWidgetModel.builder(this.state.widgetModel).withDisplayTabs(event.target.checked).build());
	};

	onTabsChange = (tabs: SelectMenuOptionType[] | null) => {
		const tabsToStringArray = tabs && tabs.length > 0 ? tabs.map((dataOptions: SelectMenuOptionType) => dataOptions.value.toString()) : [];
		this.updateWidgetModelState(FootballPlayerWidgetModel.builder(this.state.widgetModel).withTabs(tabsToStringArray).build());
	};

	onDefaultTabChange = (tab: any) => {
		this.updateWidgetModelState(FootballPlayerWidgetModel.builder(this.state.widgetModel).withDefaultTab(tab).build());
	};

	onDisplayOddsChange = (e: any) => {
		this.updateWidgetModelState(FootballPlayerWidgetModel.builder(this.state.widgetModel).withDisplayOdds(e.target.checked).build());
	};

	onPlayerTeamName = (playerTeamName: string) => {
		this.updateWidgetModelState(FootballPlayerWidgetModel.builder(this.state.widgetModel).withPlayerTeamName(playerTeamName).build());
	};

	onMatchSelect = (match: any) => {
		const displayOdds = match && match.eventStatus && match.eventStatus.code !== MatchStatuses.FINISHED;

		this.updateWidgetModelState(
			FootballPlayerWidgetModel.builder(this.state.widgetModel)
				.withMatch(match)
				.withFansUnitedEnabled(false)
				.withFansUnitedExpanded(false)
				.withDisplayOdds(displayOdds)
				.build(),
		);
	};

	onFansUnitedEnabledChange = (event: any) => {
		this.updateWidgetModelState(
			FootballPlayerWidgetModel.builder(this.state.widgetModel)
				.withFansUnitedEnabled(event.target.checked)
				.withFansUnitedExpanded(event.target.checked)
				.build(),
		);
	};

	onFansUnitedExpandedChange = (event: any) => {
		this.updateWidgetModelState(
			FootballPlayerWidgetModel.builder(this.state.widgetModel).withFansUnitedExpanded(event.target.checked).build(),
		);
	};

	onOddsWidgetChange = (oddsModel: OddsModelV2) => this.setState({ ...this.state, oddsModel });

	onInfoParametersChange = (infoParameters: string[]) => {
		this.updateWidgetModelState(FootballPlayerWidgetModel.builder(this.state.widgetModel).withInfoParameters(infoParameters).build());
	};

	onStatsParametersChange = (statsParameters: string[]) => {
		this.updateWidgetModelState(FootballPlayerWidgetModel.builder(this.state.widgetModel).withStatsParameters(statsParameters).build());
	};

	onRefreshTimeChange = (refreshTimeData: SelectMenuOptionType) => {
		onRefreshTimeChangeFromMainComponent(refreshTimeData, FootballPlayerWidgetModel, this.state.widgetModel, this.updateWidgetModelState);
	};

	render() {
		const { widgetModel, isValid, oddsModel } = this.state;
		const { t, block } = this.props;
		const bookmakerSelectionEnabled = featuresService.areFeaturesEnabled([FeatureTypes.ALLOW_BOOKMAKER_SELECTION]);
		const matchNotStarted = widgetModel.match && widgetModel.match.eventStatus && widgetModel.match.eventStatus.code !== 'finished';
		const defaultTab =
			widgetModel.match && widgetModel.match.id
				? getDefaultType(WidgetTeamDefaultTabsWithMatches, t)
				: getDefaultType(WidgetTeamDefaultTabs, t);

		return (
			<>
				<div data-qa={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.PLAYER_SELECT}`}>
					<Row>
						<Col>
							<FormGroup>
								<PlayerSelectComponent
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.PLAYER_SELECT}`}
									isBlocky
									isRequired
									isClearable
									isValid={isValid}
									selectedPlayer={widgetModel.player}
									onPlayerSelect={this.onPlayerSelect}
								/>
							</FormGroup>
						</Col>
					</Row>
					{widgetModel.player && widgetModel.player.id && widgetModel.player.id.toString().length > 0 && (
						<Row>
							<Col>
								<FormGroup>
									<PlayerSeasonSelectComponent
										isClearable
										isRequired={true}
										isBlocky
										entityId={widgetModel.player.id}
										selectedSeason={widgetModel.tournamentSeason}
										onEntitySeasonSelect={this.onSeasonSelect}
										isValid={isValid}
										onPlayerTeamNameSelect={this.onPlayerTeamName}
										entityType={ContentTypes.PLAYER}
										id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.PLAYER_SEASON_SELECT}`}
									/>
								</FormGroup>
							</Col>
						</Row>
					)}
					<Row>
						<Col>
							<FormGroup>
								<StageSelectComponent
									withLabel
									isValid={isValid}
									selectedStage={widgetModel.stage}
									seasonId={widgetModel.tournamentSeason && widgetModel.tournamentSeason.seasonId && widgetModel.tournamentSeason.seasonId}
									onStageSelect={this.onStageSelect}
									t={t}
									isClearable={!!(widgetModel.stage && widgetModel.stage.id)}
									preselectStage
									id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.STAGE_SELECT}`}
								/>
							</FormGroup>
						</Col>
						{widgetModel.tournamentSeason &&
							widgetModel.tournamentSeason.seasonId &&
							widgetModel.playerTeamName &&
							widgetModel.playerTeamName.length > 0 && (
								<Col>
									<FormGroup>
										<MatchByStageSelectComponent
											isMulti={false}
											isBlocky
											isValid={isValid}
											selectedMatch={widgetModel.match}
											stageId={widgetModel.stage ? widgetModel.stage.id : null}
											seasonId={widgetModel.tournamentSeason && widgetModel.tournamentSeason.seasonId ? widgetModel.tournamentSeason.seasonId : null}
											onMatchSelect={this.onMatchSelect}
											t={t}
											isClearable
											preselectMatch
											player={widgetModel.player}
											teamName={widgetModel.playerTeamName}
											entityType={ContentTypes.PLAYER}
											id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.MATCH_BY_STAGE_SELECT}`}
										/>
									</FormGroup>
								</Col>
							)}
					</Row>
					{widgetModel.tournamentSeason && widgetModel.tournamentSeason.seasonId && (
						<Row className='mb-2'>
							<Col>
								<FormGroup key={`display-tabs`} check className='radio'>
									<Input
										key='display-tabs-input'
										className='form-check-input'
										type='checkbox'
										id={`display-tabs-checkbox-${this.props.block.id}`}
										name='radios'
										onChange={this.onDisplayTabsChange}
										checked={widgetModel.displayTabs ? widgetModel.displayTabs : false}
										data-qa='display-tabs-checkbox'
									/>
									<Label check key='display-tabs-label' className='form-check-label' for={`display-tabs-checkbox-${this.props.block.id}`} />
									{t('display_tabs')}
								</FormGroup>
							</Col>
						</Row>
					)}

					{widgetModel.displayTabs && (
						<>
							<Row className='mt-2' data-qa='football-player-profile-tabs-select'>
								<Col>
									<FormGroup>
										<Label>{t('tabs')}</Label>
										<GenericSelect
											t={t}
											isMulti={true}
											options={WidgetPlayerTabs}
											value={widgetModel.tabs ? WidgetPlayerTabs(t).filter((el) => widgetModel.tabs.includes(el.value.toString())) : []}
											onGenericOptionSelect={this.onTabsChange}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row className='mt-2' data-qa='football-team-profile-default-tab-select'>
								<Col>
									<FormGroup>
										<Label>{t('default_tab')}</Label>
										<GenericSelect
											t={t}
											isMulti={false}
											options={widgetModel.match && widgetModel.match.id ? WidgetTeamDefaultTabsWithMatches : WidgetTeamDefaultTabs}
											value={widgetModel.defaultTab ? widgetModel.defaultTab : this.onDefaultTabChange(defaultTab)}
											onGenericOptionSelect={this.onDefaultTabChange}
										/>
									</FormGroup>
								</Col>
							</Row>
						</>
					)}
					{widgetModel.player && widgetModel.player.id > 0 && (
						<>
							<Row className='mt-2' data-qa='football-player-profile-elements-select'>
								<Col>
									<FormGroup>
										<Label htmlFor={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.ELEMENTS}`}>{t('select_info')}</Label>
										<ElementsSelect
											value={widgetModel.infoParameters}
											onSelect={this.onInfoParametersChange}
											t={t}
											isMulti
											elements={playerProfileInfoTabOptions}
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row className='mt-2' data-qa='football-player-profile-elements-select'>
								<Col>
									<FormGroup>
										<Label htmlFor={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.ELEMENTS}`}>{t('select_stats')}</Label>
										<ElementsSelect
											value={widgetModel.statsParameters}
											onSelect={this.onStatsParametersChange}
											t={t}
											isMulti
											elements={playerProfileStatsTabOptions}
										/>
									</FormGroup>
								</Col>
							</Row>
						</>
					)}
					{bookmakerSelectionEnabled && matchNotStarted && (
						<Row className='mb-2'>
							<Col>
								<FormGroup key={`display-odds-markets`} check className='radio'>
									<Input
										key={`display-odds-markets-input`}
										className='form-check-input'
										type='checkbox'
										id={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.DISPLAY_ODDS_SELECT}`}
										name='radios'
										onChange={this.onDisplayOddsChange}
										checked={widgetModel.displayOdds ? widgetModel.displayOdds : false}
									/>
									<Label
										htmlFor={`${SportTypes.FOOTBALL}-${SPORT_SELECT_ATTRIBUTES.DISPLAY_ODDS_SELECT}`}
										key={`display-odds-markets-label`}
										check
										className='form-check-label'
										for={`display-odds-markets-checkbox-${this.props.block.id}`}
									>
										{t('display_odds')}
									</Label>
								</FormGroup>
							</Col>
						</Row>
					)}
					{widgetModel && widgetModel.displayOdds && bookmakerSelectionEnabled && matchNotStarted && (
						<OddsEditComponent
							widgetType={WidgetsV2Ids.widgetFootballPlayer}
							block={this.props.block}
							isStandAlone={false}
							t={t}
							match={widgetModel && widgetModel.match ? widgetModel.match : oddsModel.match}
							onOddsWidgetChange={this.onOddsWidgetChange}
							displayOddsLabel={true}
							isValid={this.state.isValid}
							currentOdds={oddsModel}
						/>
					)}
					{featuresService.checkFeatureIsSetAndEnabled(FeatureTypes.FANS_UNITED) && matchNotStarted && (
						<FansUnitedEnabledComponent
							fansUnitedEnabledChecked={widgetModel.fansUnitedEnabled}
							fansUnitedExpandedChecked={widgetModel.fansUnitedExpanded}
							blockId={block.id}
							t={t}
							onFansUnitedEnabledCheck={this.onFansUnitedEnabledChange}
							onFansUnitedExpandCheck={this.onFansUnitedExpandedChange}
							sport={SportTypes.FOOTBALL}
						/>
					)}
					<RefreshTimeWrapper value={this.state.widgetModel.refreshTime} onRefreshTimeChange={this.onRefreshTimeChange} />
				</div>
			</>
		);
	}
}
